import React, { useEffect, useState } from 'react'

interface Props {
  imgSrc: string
  previewSrc: string
  width?: any
  height?: any
  className?: string
  id?: string
  alt?:string
  styleTransform?: string
}
function ProgressiveImage(props: Props) {
  const { width = '100%', imgSrc, id, height = '100%', className, previewSrc, alt, styleTransform } = props
  const [usedSrc, setUsedSrc] = useState(previewSrc)
  const [usedEffectStyle, setUsedEffectStyle] = useState({
    filter: 'blur(5px)',
    clipPath: 'inset(0)',
  })


  useEffect(() => {
    const img = new Image()
    img.src = imgSrc
    img.onload = () => {
      setUsedSrc(img.src)
      setUsedEffectStyle({})
    }
  }, [imgSrc])

  return (
    <img
      id={id}
      alt={alt}
      src={usedSrc}
      width={width}
      height={height}
      className={className}
      style={{ transition: 'filter 0.1s ease-out', ...usedEffectStyle, transform: styleTransform }}
    />
   
  )
}

export default React.memo(ProgressiveImage)

import React, { useState } from 'react'
import { Route } from 'react-router-dom'
import './account.scss'
import MyAccount from './myAccount';
const ModifyOrder = React.lazy(() => import('./modifyOrder')); 
const MyFavourites = React.lazy(() => import('./myFavourites'));
const MyProfile = React.lazy(() => import('./myProfile'));
const OrderDetail = React.lazy(() => import('./orderDetail'));
import Toaster from '../../components/Toaster'
import AccountRoute from './component/accountRoute/AccountRoute';

const Account = () => {
  const [showMessage, setShowMessage] = useState(false)
  const handleSubmitToastMessageProfile = () => {
    setShowMessage(true)
  }

  return (
    <>
     
        <AccountRoute>
          {showMessage && (
            <Toaster successMessage={'Profile Updated Successfully!'} />
          )}
          <Route
            path={'/my-account'}
            exact={true}
            render={() => <MyAccount setShowMessage={setShowMessage} />}
          />

          <Route
            path={'/my-account/profile'}
            exact={true}
            render={() => (
              <MyProfile
                handleSubmitToastMessageProfile={
                  handleSubmitToastMessageProfile
                }
              />
            )}
          />

          <Route
            path={'/my-account/order-detail/:id'}
            exact={true}
            render={(matchProps) => <OrderDetail {...matchProps} />}
          />

          <Route
            path={'/my-account/:id/modify/:id'}
            exact={true}
            render={(matchProps) => <ModifyOrder {...matchProps} />}
          />

          <Route
            path={'/my-account/favourites'}
            exact={true}
            render={() => <MyFavourites />}
          />
          </AccountRoute>
    </>
  )
}

export default Account

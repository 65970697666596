/**
 * Configuration
 * @module config
 */

// const env = process.env.REACT_APP_ENV
// const dev = process.env.REACT_APP_ENV === 'development'

// If you want to change the language, remember to also change the
// locale data and the messages in the app.js file.
const locale = 'en'
const i18n = {
  /*
    0: Sunday
    1: Monday
    ...
    6: Saturday
  */
  firstDayOfWeek: 0,
}

const sortSearchByDistance = false

// Canonical root url is needed in social media sharing and SEO optimization purposes.
// const canonicalRootURL = process.env.REACT_APP_CANONICAL_ROOT_URL

// Site title is needed in meta tags (bots and social media sharing reads those)
const siteTitle = 'Avatus'

// Agora App Id
// const agoraAppId = process.env.REACT_APP_AGORA_APP_ID

const genderOptions = [
  { key: 'male', label: 'Male' },
  { key: 'female', label: 'Female' },
  { key: 'other', label: 'Gender Variant / Non Binary' },
]

const channelPlan = [
  { key: 'months', label: 'Months' },
  { key: 'annually', label: 'Anually' },
]

const channelOptions = [
  { key: '1', label: '1' },
  { key: '2', label: '2' },
  { key: '3', label: '3' },
]

const config = {
  name: 'Avatus',
  // agoraAppId,
  // canonicalRootURL,
  description: 'Avatus',
  // dev,
  // env,
  genderOptions,
  i18n,
  locale,
  siteTitle,
  sortSearchByDistance,
  channelOptions,
  channelPlan,
}

export default config

import { AxiosRequestConfig } from 'axios'
import { store } from '../store'
import { getLocalStrapiToken, setLocalStrapiToken, setPartnerToken, setUserToken } from '../utils/common'
import { UserDetails } from '../constants/model'
import UserActions from '../actions/userActions'
import constant, { API_SUCCESS_CODE } from './constant'
import { endpoints } from './endPoints'

const handleAPIError = (error: any) => {
  if (
    error.response &&
    (error.response.status == 401 || error.response.status === 417)
  ) {
    setUserToken(new UserDetails())
    setPartnerToken('')
    store.dispatch(UserActions.updateUserDetails(new UserDetails()))
    // @ts-ignore
    if (global.routeRef) {
      // @ts-ignore
      global.routeRef.replace('/')
    }
  }
}

/**
 *
 * @param endPoint api end point
 * @param params request data
 * @param successCallback function for handle success response
 * @param errorCallback  function for handle error response
 */
const postApiCall = (
  endPoint: string,
  params: any,
  successCallback: Function,
  errorCallback: Function
) => {
  constant.axios
    .post(endPoint, params)
    .then((response: any) => {
      if (
        (response && response?.status === 200) ||
        (response && response?.data?.responseCode === 200)
      ) {
        successCallback(response?.data)
      } else {
        if (response?.response?.status === 400) {
          successCallback(response?.response?.data)
        } else successCallback(response?.data)
      }
    })
    .catch((error: any) => {
      errorCallback && errorCallback(error?.response)
      handleAPIError(error)
    })
}

/**
 *
 * @param endPoint api end point
 * @param params api url parameter
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */
const getApiCall = (
  endPoint: string,
  params = '',
  successCallback: Function,
  errorCallback: Function
) => {
  constant.axios
    .get(endPoint + params)
    .then((response: any) => {
      if (
        (response && response?.status === 200) ||
        (response && response?.data?.responseCode === 200)
      ) {
        successCallback(response?.data)
      } else {
        if (response?.response?.status === 400) {
          successCallback(response?.response?.data)
        } else successCallback(response?.data)
      }
    })
    .catch((error: any) => {
      errorCallback && errorCallback(error?.response)
      handleAPIError(error)
    })
}

/** ******************************************************************************************* */
/**
 *
 * @param endPoint api end point
 * @param params api request data
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */
const deleteApiCall = (
  endPoint: string,
  params = '',
  successCallback: Function,
  errorCallback: Function
) => {
  constant.axios
    .delete(endPoint + params)
    .then((response: any) => {
      if (
        (response && response?.status === 200) ||
        (response && response?.data?.responseCode === 200)
      ) {
        successCallback(response?.data)
      } else {
        if (response?.response?.status === 400) {
          successCallback(response?.response?.data)
        } else successCallback(response?.data)
      }
    })
    .catch((error: any) => {
      errorCallback && errorCallback(error?.response)
      handleAPIError(error)
    })
}

/**
 *
 * @param endPoint api end point
 * @param params api request data
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */
const patchApiCall = (endPoint: string, payload = {}) =>
  constant.axios.patch(endPoint, payload)

/**
 *
 * @param endPoint api end point
 * @param params api request data
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */
const putApiCall = (
  endPoint: string,
  params: any = '',
  successCallback: Function,
  errorCallback: Function
) => {
  constant.axios
    .put(endPoint, params)
    .then((response: any) => {
      if (
        (response && response?.status === 200) ||
        (response && response?.data?.responseCode === 200)
      ) {
        successCallback(response?.data)
      } else {
        if (response?.response?.status === 400) {
          successCallback(response?.response?.data)
        } else successCallback(response?.data)
      }
    })
    .catch((error: any) => {
      errorCallback && errorCallback(error?.response)
      handleAPIError(error)
    })
}

function timeout(ms: number, promise: any) {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new Error('TIMEOUT'))
    }, ms)

    promise
      .then((value) => {
        clearTimeout(timer)
        resolve(value)
      })
      .catch((reason) => {
        clearTimeout(timer)
        reject(reason)
      })
  })
}

const getStrapiApiCall = (
  endPoint: string,
  params = '',
  successCallback: Function,
  errorCallback: Function,
  
) => {
  const strapiToken = getLocalStrapiToken()
  if (strapiToken) {
    const strapi_Base_URl = process.env.STRAPI_URL || ''
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${strapiToken}`)
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    fetch(strapi_Base_URl + '/' + `${endPoint + params}`, requestOptions)
      .then((response) => {
        return response.json()
      })
      .then((apiResult: any) => {
        apiResult ? successCallback(apiResult) : errorCallback()
      })
      .catch((apiError: any) => {
        errorCallback(apiError)
      })
  } else {
    getApiCall(
      endpoints.orbitConfig.config,
      '',
      (apiRespone: any) => {
        const { responseCode, data, error } = apiRespone
        if (responseCode == API_SUCCESS_CODE.success && !error && data) {
          const strapiToken = data.find((item: any) => item.configKey == 'strapi-token-ro')?.configValue
          setLocalStrapiToken(strapiToken)
          getStrapiApiCall(endPoint,params,successCallback,errorCallback)
        }
        console.log('apiRespone',apiRespone)
      },
      (error: any) => {
        console.log('error',error)
      }
    )
  }
}


const getGoogleMapApi = (
  endPoint: string,
  params = '',
  successCallback: Function,
  errorCallback: Function,
  
) => { 
  const GOOGLE_URL = process.env.GOOGLE_URL || ''
  
  fetch(GOOGLE_URL + '/' + `${endPoint + params + "&key=AIzaSyDZKj6HaK42-Iy8Kq6UZNd9lNwrmWy5aLs"}`,)
    .then((response) => {
      return response.json()
    })
    .then((apiResult: any) => {
      apiResult ? successCallback(apiResult) : errorCallback()
    })
    .catch((apiError: any) => {
      errorCallback(apiError)
    })
}



/**
 * export all function
 */
export default {
  getApiCall,
  putApiCall,
  postApiCall,
  patchApiCall,
  deleteApiCall,
  getStrapiApiCall,
  getGoogleMapApi,
}

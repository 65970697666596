import routing from './routing'
import onBoardingTypes from './onBoarding'
import prebookingTypes from './prebooking'
import userDetailsTypes from './userDetails'
import appData from './appData'
import homeActionType from './home'
import rateYourInterest from './rateYourInterest'
import partnerAndChannel from "./partnerAndChannel"
import locatorAndScheduling from './locatorScheduling'
import ecBooking from './ecBooking'

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = {
  ...routing,
  ...onBoardingTypes,
  ...prebookingTypes,
  ...userDetailsTypes,
  ...homeActionType,
  ...rateYourInterest,
  ...partnerAndChannel,
  ...locatorAndScheduling,
  ...ecBooking
}

export const AppData = {
  ...appData,
}

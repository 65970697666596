import Header from '../../../components/Header'
import Footer from '../../../components/Footer'
import Grid from '@mui/material/Grid'
import Icons from '../../../utils/icons'
import '../matterStream.scss'
import { useDispatch, useSelector } from 'react-redux'
import { ReducerModel } from '../../../reducers/reducerModel'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import moment from 'moment'
import { NewsCategory } from '../../../constants/model'
import ProgressiveImage from '../../../components/ProgressiveImage'
import React from 'react'
import { getUserToken, tagMethodGTM } from '../../../utils/common'
import { AppData } from '../../../constants'
import Images from '../../../utils/images'

const PressKit = () => {
  const {   
    pressRelease,
    logosAndTrademark,
    aera5000,
    aera5000Plus
    } = useSelector(
      (store: ReducerModel) => store.home
    )
  const history = useHistory()

  const isMobile = useMediaQuery('(max-width:576px)');
  const [sliceMore, setSliceMore] = useState(3)

  useEffect(() => {            
    window.scrollTo(0, 0)   
    // //get all news
    // dispatch(homeActions.getAwards())   
    // dispatch(homeActions.getWhoWeAreLeaders())
  },[])  
  
  let search = window.location.search;
  let params = new URLSearchParams(search);  
  let press = params.get('press');
  let aera = params.get('aera');
  
  const download = (url: string, name: string) => {

    // console.log(url)
    // console.log(name)
    // var a = document.createElement('a');
    // a.href = url;
    // a.download = name;
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
    const userToken = getUserToken()
    
    !userToken ? history.push('/login') :
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          //setFetching(false);
          const blobURL = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = blobURL;
          a.style = "display: none";

          if (name && name.length) a.download = name;
          document.body.appendChild(a);
          a.click();
        })
        .catch(() => console.log('error'));
  };

  // const forceDownload = (url:string, fileName:string) => {
  //   var xhr = new XMLHttpRequest();
  //   xhr.open("GET", url, true);
  //   xhr.responseType = "blob";
  //   xhr.onload = function(){
  //       var urlCreator = window.URL || window.webkitURL;
  //       var imageUrl = urlCreator.createObjectURL(this.response);
  //       var tag = document.createElement('a');
  //       tag.href = imageUrl;
  //       tag.download = fileName;
  //       document.body.appendChild(tag);
  //       tag.click();
  //       document.body.removeChild(tag);
  //   }
  //   xhr.send();
  // }

  return (
    <div className="matter-stream">
      <Header />
      <h1 className="visuallyHidden">Category Blogs</h1>        

      <div className="matter-news-content content-container">        
        
        <div className="flex al-center monument">
          <p className="category-title partnerLabelColor pointer" onClick={() => {
            history.push('/matter-stream')
            }}>
              Matter Stream 
              <img style={{ marginLeft: '14px' }} src={Icons.RightChevronIconBlack} alt='Right Caret Black Color Icon' /> 
          </p>
          <p style={{ marginLeft: '14px' }} className="category-title partnerLabelColor pointer" onClick={() => {
            history.push('/matter-stream/news')
            }}>News
            <img style={{ marginLeft: '14px' }} src={Icons.RightChevronIconBlack} alt='Right Caret Black Color Icon' />
          </p>          
          <p className="category-title-news" style={isMobile ? {textAlign: "center", width: "min-content"} : {}}>{press}</p>
        </div>   
               

        {/* display filtered and initial load blogs */}
        {press === 'Press Release' &&           
          <div className="matter-stream-content-blogs" style={{ marginTop: '32px' }}>
            {isMobile ? <Grid container spacing={{xs: 1.5, sm: 3}} style={{ marginTop: '0' }}>
              {pressRelease.slice(0, sliceMore).sort((a,b) => {          
                  return new Date(b.pressDate).getTime() - new Date(a.pressDate).getTime()
                }).map((item, index)=>                 
                <Grid item xs={12} sm={6} md={4} key={index} style={{ paddingTop: '0', marginBottom: '48px' }}>
                  <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={() => {                    
                    window.open(process.env.STRAPI_URL + item.pressFile)                              
                  }}>
                  <div className="blog-posts monument pointer" onClick={() => {                    
                   
                  }
                    }>
                    <ProgressiveImage alt="Matter Blogs"                    
                      className="more-posts-blog-img" 
                      imgSrc={process.env.STRAPI_URL + item.pressImageWeb} 
                      previewSrc={process.env.STRAPI_URL + item.pressThumbNailImageWeb} />                    
                    <p className="date mr-8">{moment(item.pressDate).format('MMM DD, YYYY')}</p>
                    <span className="dot"></span>                     
                    <p className="date ml-8">{item.pressPublisher}</p> 
                    <p className="blog-data-title mt-8">
                      {item.pressTitle}
                    </p>
                  </div>
                  </Link>
                  <p className="download pointer" 
                    onClick={()=> download(process.env.STRAPI_URL + item.pressFile, item.pressTitle)}>
                    Download
                  </p>
                </Grid>
              )}
              {((sliceMore < pressRelease.length) && isMobile) && 
                <div style={{ paddingLeft: "12px" }}>
                    <p className="more-posts primary pointer" onClick={() => {
                      setSliceMore(sliceMore + 3)
                    }}>
                      <span className="underline pb-2">Load More</span>
                      <img
                        className="ml-4"
                        src={Icons.RightChevronIconBlue}
                        alt="Right Chevron Icon Blue"
                      />
                    </p>                  
                </div>}               
            </Grid>
            : <Grid container spacing={{xs: 1.5, sm: 3}} style={{ marginTop: '0' }}>
              {pressRelease.sort((a,b) => {          
                  return new Date(b.pressDate).getTime() - new Date(a.pressDate).getTime()
                }).map((item, index)=>                 
                <Grid item xs={12} sm={6} md={4} key={index} style={{ paddingTop: '0', marginBottom: '48px' }}>
                  <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={() => {                    
                    window.open(process.env.STRAPI_URL + item.pressFile)                              
                  }}>
                  <div className="blog-posts monument pointer" onClick={() => {                    
                   
                  }
                    }>
                    <ProgressiveImage alt="Matter Blogs"                    
                      className="more-posts-blog-img" 
                      imgSrc={process.env.STRAPI_URL + item.pressImageWeb} 
                      previewSrc={process.env.STRAPI_URL + item.pressThumbNailImageWeb} />                    
                    <p className="date mr-8">{moment(item.pressDate).format('MMM DD, YYYY')}</p>
                    <span className="dot"></span>                     
                    <p className="date ml-8">{item.pressPublisher}</p> 
                    <p className="blog-data-title mt-8">
                      {item.pressTitle}
                    </p>
                  </div>
                  </Link>
                  <p className="download pointer" 
                    onClick={()=> download(process.env.STRAPI_URL + item.pressFile, item.pressTitle)}>
                    Download
                  </p>
                </Grid>
              )}               
            </Grid>} 
          </div>}

        {/* Display Aera 5000 */}
        {(press === 'Product Portfolio' && aera === 'Aera 5000') &&           
          <div className="matter-stream-content-blogs" style={{ marginTop: '32px' }}>
            <Grid container spacing={{xs: 1.5, sm: 3}} style={{ marginTop: '0' }}>
            {aera5000.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index} style={{ paddingTop: '0', marginBottom: '48px' }}>                  
                  <div className="blog-posts monument" onClick={() => {                    
                  
                    }
                    }>
                    <div style={{ padding: '30px', border: '1px solid #CECFCF', borderRadius: '4px', marginBottom: '12px', height: '290px' }}>
                      <ProgressiveImage alt="Matter Blogs"                    
                        className="" 
                        imgSrc={process.env.STRAPI_URL + item.aeraBikeImg}
                          previewSrc={process.env.STRAPI_URL + item.aeraBikeImg} /> 
                    </div>                                                            
                    <p className="download pointer" 
                      onClick={()=> download(process.env.STRAPI_URL + item.aeraBikeImg, "Aera 5000")}>
                      Download
                    </p>                     
                  </div>
                  
                </Grid>
              ))}                         
            </Grid> 
          </div>}

          {/* Display Aera 5000 Plus */}
          {(press === 'Product Portfolio' && aera === 'Aera 5000 Plus') &&           
            <div className="matter-stream-content-blogs" style={{ marginTop: '32px' }}>
              <Grid container spacing={{xs: 1.5, sm: 3}} style={{ marginTop: '0' }}>
              {aera5000Plus.map((item, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index} style={{ paddingTop: '0', marginBottom: '48px' }}>                  
                    <div className="blog-posts monument" onClick={() => {                    
                    
                      }
                      }>
                      <div style={{ padding: '30px', border: '1px solid #CECFCF', borderRadius: '4px', marginBottom: '12px', height: '290px' }}>
                        <ProgressiveImage alt="Matter Blogs"                    
                          className="" 
                          imgSrc={process.env.STRAPI_URL + item.aeraBikeImg}
                          previewSrc={process.env.STRAPI_URL + item.aeraBikeImg} /> 
                      </div>                                                            
                      <p className="download pointer"
                        onClick={()=> download(process.env.STRAPI_URL + item.aeraBikeImg, "Aera 5000 Plus")}>
                          Download
                      </p>                     
                    </div>
                    
                  </Grid>
                ))}                         
              </Grid> 
            </div>}

          {press === 'Logos & Trademark' &&           
          <div className="matter-stream-content-blogs" style={{ marginTop: '32px' }}>
            <Grid container spacing={{xs: 1.5, sm: 3}} style={{ marginTop: '0' }}>

            {logosAndTrademark.slice(0,3).map((item, index) => (
              <Grid item xs={12} sm={6} md={4}  style={{ paddingTop: '0' }}>                  
                <div className="blog-posts monument" onClick={() => {                    
                
                  }
                  }>
                  <div style={{ padding: '30px', border: '1px solid #CECFCF', borderRadius: '4px', marginBottom: '12px', height: '290px' }}>
                    <ProgressiveImage alt="Matter Blogs"                    
                      className="contain-img" 
                      imgSrc={process.env.STRAPI_URL + item.logoAndTrademark}
                      previewSrc={process.env.STRAPI_URL + item.logoAndTrademark} /> 
                  </div>                                                            
                  <p className="download pointer" 
                    onClick={()=> download(process.env.STRAPI_URL + item.logoAndTrademark, "Logo")}>
                    Download
                  </p>                     
                </div>
                
              </Grid>
            ))}

            {/* <Grid item xs={12} sm={6} md={4}  style={{ paddingTop: '0' }}>                  
                <div className="blog-posts monument pointer" onClick={() => {                    
                
                  }
                  }>
                  <div style={{ padding: '30px', border: '1px solid #CECFCF', borderRadius: '4px', marginBottom: '12px', height: '290px' }}>
                    <ProgressiveImage alt="Matter Blogs"                    
                      className="" 
                      imgSrc={Icons.Mini_Logo_Black} 
                      previewSrc={Icons.Mini_Logo_Black} /> 
                  </div>                                                            
                  <p className="download pointer" 
                    onClick={()=> download(Icons.Mini_Logo_Black, "T&C JWT")}>
                    Download
                  </p>                    
                </div>
                
              </Grid> */}
            </Grid>
          </div>}

      </div>        
      <Footer />
    </div>    
  )
}

export default PressKit

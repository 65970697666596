import React from 'react'
import { Helmet } from 'react-helmet-async'

interface Props {
  title: string
  conicalLink: string
  description: string
}
const HeadlessComponent = ({ title, conicalLink, description }: Props) => {
  return (
    <Helmet>
      <link rel="canonical" href={conicalLink} />
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
    </Helmet>
  )
}

export default HeadlessComponent

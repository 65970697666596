import { AnyAction } from 'redux'
import { ActionTypes } from '../constants/index'
import { OnboardingDetails } from '../constants/model'

let initialState: OnboardingDetails = new OnboardingDetails()

export default function reducer(state: any = initialState, action: AnyAction) {
  const { type, payload } = action
  switch (type) {
    case ActionTypes.POST_OTP:
      return {
        ...state,
        ...payload,
      }
    case ActionTypes.TAB_CHANGE:
      return {
        ...state,
        ...payload,
      }
    case ActionTypes.TAB_BACK:
      return {
        ...state,
        ...payload,
      }
    case ActionTypes.SIGNUP_DETAILS:
      return {
        ...state,
        ...payload,
      }
    case ActionTypes.OTP_VERIFICATION:
      return {
        ...state,
        ...payload,
      }
    case ActionTypes.OTP_REQUEST_DETAILS:
      return {
        ...state,
        ...payload,
      }
    case ActionTypes.LOADER_STATUS:
      return {
        ...state,
        ...payload,
      }
    case ActionTypes.NUMBER_OTP_VERIFIED:
      return {
        ...state,
        ...payload,
      }
    case ActionTypes.OTP_NUMBER_ATTEMPS:
      return {
        ...state,
        ...payload,
      }
    case ActionTypes.BACKFROMACTIVATE:
      return {
        ...state,
        ...payload,
      }
    case ActionTypes.CHECK_FIVE_MINUTES:
      return {
        ...state,
        ...payload,
      }
      case ActionTypes.RESET_ONBOARDING:
        return new OnboardingDetails()
    default:
      return state
  }
}

import { useEffect } from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import '../../account.scss';

const AccountRoute = (props : any) => {
    
    useEffect(() => {
        document.title = 'My Account | Matter'
    }, [])
    
    return (
        <div className="account-page">
            <Header />
            <h1 className="visuallyHidden">My Account</h1>
            <main className="account-content content-container">
                {props.children}
            </main>
            <Footer />
        </div>
    )
}

export default AccountRoute;
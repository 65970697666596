import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Images from '../../utils/images'
import Grid from '@mui/material/Grid'
import Icons from '../../utils/icons'
import Link from '@mui/material/Link'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import {ClickAwayListener} from '@mui/base'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import './faq.scss'
import { useDispatch, useSelector } from 'react-redux'
import { ReducerModel } from '../../reducers/reducerModel'
import { useState } from 'react'
import { FAQDetails, FaqMasterCategory } from '../../constants/model'
import homeActions from '../../actions/homeActions'
import { useEffect } from 'react'
import AppLoader from '../../components/AppLoader'
import { useHistory } from 'react-router-dom'
import MarkDown from '../../components/Markdown'
import { useRef } from 'react'
import { tagMethodGTM } from '../../utils/common'
import HeadlessComponent from '../../components/HeadlessComponent'

const CompanionAppFAQ = () => {
  const { faqCategories, faqDetail, faqAppliedSearch } = useSelector(
    (store: ReducerModel) => store.home
  )

  const history: any = useHistory()
  const searchParams = history.location?.state?.search
  const faqQuestion = history.location?.state?.faqQuestion
  let defaultFAQSelection = {
    expendedItemId: -1,
    childCategoryId: -1,
    masterCategoryId: -1,
    subChildCategoryId: -1,
  }
  const [selectedFAQCategory, setFAQCategory] = useState({
    childCategoryId: -1,
    masterCategoryId: -1,
    subChildCategoryId: !searchParams ? -1 : 1,
    expendedItemId: -1,
  })

  const [selectedQuestion, setSelectedQuestion] = useState(0)
  const oldFAQRecord:any = useRef()
  const [isLoading, setLoading] = useState(false)
  const [searchFAQ, setSearchFAQ] = useState(searchParams)
  // const faqSearch = history.location?.state?.search

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 200);
    dispatch(homeActions.resetFAQ())
    dispatch(homeActions.getBannerStrapiApi())
    !searchParams
      ? dispatch(
          homeActions.getFaqCategoriesStrapiApi(
            (apiResponse: FAQDetails) => {
              if (faqQuestion) {
                let childTitle = ''
                const masterCategoryIndex = faqCategories.findIndex(
                  (masteritem) => {
                    return masteritem.child_category_faqs.find((subItem) => {
                      childTitle = subItem.categoryTitle
                      return subItem.id == faqQuestion.subChildCategoryId
                    })
                  }
                )
                if (masterCategoryIndex != -1) {
                  setFAQCategory({
                    childCategoryId: faqQuestion.subChildCategoryId || -1,
                    masterCategoryId:
                      masterCategoryIndex != -1
                        ? faqCategories[masterCategoryIndex].id
                        : -1,
                    subChildCategoryId: 1,
                    expendedItemId: faqQuestion.id,
                  })
                  setSelectedQuestion(faqQuestion.id)
                  dispatch(
                    homeActions.updateFAQDetails({
                      ...faqAppliedSearch,
                      categoryTitle: childTitle,
                      faq_maters: [faqQuestion],
                      master_category_faq: {
                        categoryTitle:
                          faqCategories[masterCategoryIndex].categoryTitle,
                        id: faqCategories[masterCategoryIndex].id,
                      },
                    })
                  )
                }
              } else
                setFAQCategory({
                  ...selectedFAQCategory,
                  childCategoryId: apiResponse.id,
                  masterCategoryId: apiResponse.master_category_faq.id,
                })
            },
            (error: any) => {}
          )
        )
      : dispatch(
          homeActions.getFAQAppliedSearch(
            searchParams,
            (faqDetails: FAQDetails) => {
              setFAQCategory({
                ...selectedFAQCategory,
                subChildCategoryId: 1,
              })
              dispatch(homeActions.updateFAQDetails(faqDetails))
            },
            () => {}
          )
        )
  }, [])

  const dispatch: Function = useDispatch()

  const handleExpendChange =
    (itemId: number) => (event: any, newExpanded: boolean) => {
      setSelectedQuestion(selectedQuestion == itemId ? -1 : itemId)
      // setFAQCategory({
      //   ...selectedFAQCategory,
      //   expendedItemId:
      //     itemId != selectedFAQCategory.expendedItemId ? itemId : 0,
      // })
    }

  return (
    <div className="faq-page-wrapper">
      {/* <Header /> */}
      <h1 className="visuallyHidden">Matter FAQs</h1>
      <HeadlessComponent
        title="Find answers to all the FAQs about the Matter AERA electric bike."
        conicalLink="https://www.matter.in/faq" 
        description="One-stop shop for all your answers related to Matter. Right from product ownership to the features and performance queries that you might have, and a lot more."
      />

      <section className="faq-banner">
        <img
          className="banner-image for-desktop"
          src={Images.FaqBanner}
          alt="Matter Faq"
        />
        <img
          className="banner-image for-mob"
          src={Images.FaqBannerMobile}
          alt="Matter Faq"
        />
        <div className="banner-content">
          <h2 className="monument white mb-16">FAQs</h2>
          <p className="subTextLight">
            We are here to help you! <br />
            Get answers to all your questions here.
          </p>
          <ClickAwayListener
            onClickAway={() => {
              document
                .querySelector('.faq-search-list')
                ?.classList.remove('show')
              // setSearchFAQ('')
            }}
          >
            <div className="ask-cover">
              <input
                value={searchFAQ}
                onKeyUp={(onKeyUpEvent) => {
                  if (onKeyUpEvent.keyCode == 13) {
                    oldFAQRecord.current = {...selectedFAQCategory}

                    setFAQCategory({
                      ...selectedFAQCategory,
                      childCategoryId: -1,
                      masterCategoryId: -1,
                      subChildCategoryId: 1,
                      expendedItemId : -1,
                    })
                    dispatch(homeActions.updateFAQDetails(faqAppliedSearch))
                    document
                      .querySelector('.faq-search-list')
                      ?.classList.contains('show') ? document
                      .querySelector('.faq-search-list')
                      ?.classList.remove('show') : document
                      .querySelector('.faq-search-list')
                      ?.classList.add('show')
                  }

                }}
                onChange={(event) => {
                  event.preventDefault()
                  const value = event.target.value
                  setSearchFAQ(event.target.value)
                  if (value.length == 0 || value.length >= 3) {
                    document
                      .querySelector('.faq-search-list')
                      ?.classList.add('show')
                    dispatch(
                      homeActions.getFAQAppliedSearch(
                        event.target.value,
                        (faqDetail: FAQDetails) => {},
                        () => {}
                      )
                    )
                  }
                  // if (value.length == 0) {
                  //   document
                  //     .querySelector('.faq-search-list')
                  //     ?.classList.remove('show')
                  // }
                  // setFAQCategory({
                  //   ...selectedFAQCategory,
                  //   // subChildCategoryId : 1,
                  //   expendedItemId: selectedFAQCategory.expendedItemId,
                  // })
                }}
                placeholder="Ask away!"
                type="text"
              />
              <img
                onClick={() => {
                  setFAQCategory({
                    expendedItemId: -1,
                    childCategoryId: -1,
                    masterCategoryId: -1,
                    subChildCategoryId: 1,
                  })
                  dispatch(homeActions.updateFAQDetails(faqAppliedSearch))
                }}
                src={Icons.SearchGrayIcon}
              />
              <ul
                id={'faq-search-list'}
                className={`faq-search-list ${
                  selectedFAQCategory.subChildCategoryId == -1 ? 'show' : ''
                }`}
              >
                {faqAppliedSearch.faq_maters.map((item) => {
                  const makeBold = () => {
                    var reg = new RegExp(searchFAQ, 'gi')
                    var final_str =
                      item.question.replace(reg, function (str) {
                        return '<b>' + str + '</b>'
                      })
                    return final_str
                  }
                  
                  return (
                    <li
                      onClick={() => {
                     
                        let childTitle = ''
                        const masterCategoryIndex = faqCategories.findIndex(
                          (masteritem) => {
                            return masteritem.child_category_faqs.find(
                              (subItem) => {
                                childTitle = subItem.categoryTitle
                                return subItem.id == item?.subChildCategoryId
                              }
                            )
                          }
                        )
                        
                        if (masterCategoryIndex != -1) {
                          setFAQCategory({
                            childCategoryId: item?.subChildCategoryId || -1,
                            masterCategoryId:
                              masterCategoryIndex != -1
                                ? faqCategories[masterCategoryIndex].id
                                : -1,
                            subChildCategoryId: 1,
                            expendedItemId: item.id,
                          })
                          setSelectedQuestion(item.id)
                          dispatch(
                            homeActions.updateFAQDetails({
                              ...faqAppliedSearch,
                              categoryTitle: childTitle,
                              faq_maters: [item],
                              master_category_faq: {
                                categoryTitle:
                                  faqCategories[masterCategoryIndex].categoryTitle,
                                id: faqCategories[masterCategoryIndex].id,
                              },
                            })
                          )
                          setSearchFAQ('')
                          document
                      .querySelector('.faq-search-list')
                      ?.classList.contains('show') ? document
                      .querySelector('.faq-search-list')
                      ?.classList.remove('show') : document
                      .querySelector('.faq-search-list')
                      ?.classList.add('show')
                        }
                      }}
                      key={item.id}
                    >
                      <p  dangerouslySetInnerHTML={{ __html: makeBold() }} className="small"></p>
                    </li>
                  )
                })}

                {!faqAppliedSearch.faq_maters.length && searchFAQ && (
                  <li>
                    <p className="small">No matches found</p>
                  </li>
                )}

                {faqAppliedSearch.faq_matersCount &&
                faqAppliedSearch.faq_matersCount > 0 ? (
                  <li className="right">
                    <a
                      onClick={() => {
                        console.log("yes lcikcing")
                        setFAQCategory({
                          childCategoryId: -1,
                          masterCategoryId: -1,
                          subChildCategoryId: 1,
                          expendedItemId: -1,
                        })
                          dispatch(homeActions.updateFAQDetails(faqAppliedSearch))
                          document
                      .querySelector('.faq-search-list')
                      ?.classList.contains('show') ? document
                      .querySelector('.faq-search-list')
                      ?.classList.remove('show') : document
                      .querySelector('.faq-search-list')
                      ?.classList.add('show')
                      }}
                      className="underline primary"
                    >
                      View all {faqAppliedSearch.faq_matersCount} Results
                    </a>
                  </li>
                ) : null}
              </ul>
            </div>
          </ClickAwayListener>
        </div>
      </section>
      <section
        className={`faq-content content-container ${
          selectedFAQCategory.subChildCategoryId != -1 ? 'show-detail' : ''
        }`}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={5} md={4}>
            <ul className="topics-list">
              {faqCategories.map((item: FaqMasterCategory) => {
                return (
                  <li
                    onClick={() => {
                     
                      if (searchFAQ) {
                        setSearchFAQ('')
                        dispatch(
                          homeActions.getFAQAppliedSearch(
                            '',
                            () => {},
                            () => {}
                          )
                        )
                      }

                      setFAQCategory({
                        ...selectedFAQCategory,
                        masterCategoryId: item.id,
                        childCategoryId: item.child_category_faqs.length
                          ? item.child_category_faqs[0].id
                          : -1,
                      })
                      item.child_category_faqs.length &&
                        dispatch(
                          homeActions.getCategoryFAQs(
                            item.child_category_faqs[0].id,
                            () => {},
                            () => {}
                          )
                        )
                    }}
                    className={`topic ${
                      item.id == selectedFAQCategory.masterCategoryId
                        ? 'active'
                        : ''
                    }`}
                    key={item.id}
                  >
                    <h6 className="monument small">
                      {item.categoryTitle}
                    </h6>
                    <ul className="subtopics-list">
                      {item.child_category_faqs?.map((childCategory) => {
                        return (
                          <li
                            onClick={(event) => {
                              console.log("yes lcikcing")
                              tagMethodGTM(
                                `FAQs`,
                                `Matter FAQs ${item.categoryTitle}`,
                                'Click',
                                `${childCategory.categoryTitle}`
                              )
                              event.stopPropagation()
                              setFAQCategory({
                                ...selectedFAQCategory,
                                childCategoryId: childCategory.id,
                                subChildCategoryId: childCategory.id,
                              })
                              dispatch(
                                homeActions.getCategoryFAQs(
                                  childCategory.id,
                                  () => {},
                                  () => {}
                                )
                              )
                            }}
                            key={childCategory.id}
                            className={`subtopic ${
                              childCategory.id ==
                              selectedFAQCategory.childCategoryId
                                ? 'active'
                                : ''
                            }`}
                          >
                            {childCategory.categoryTitle}
                            {childCategory.id ==
                              selectedFAQCategory.childCategoryId && (
                              <ArrowForwardIosIcon
                                className="primary"
                                fontSize="small"
                              />
                            )}
                          </li>
                        )
                      })}
                    </ul>
                  </li>
                )
              })}
            </ul>
          </Grid>
          <Grid item xs={12} sm={7} md={8}>
            <div className="faq-list">
              { selectedFAQCategory.expendedItemId != -1 || (selectedFAQCategory.subChildCategoryId != 1) ? (
                // <ul className="breadcrumb-cover monument">
                <ul className="breadcrumb-cover monument">
                  <li
                    onClick={() => {
                      setFAQCategory(defaultFAQSelection)
                      setSearchFAQ('')
                    }}
                  >
                    <Link>{'Support'}</Link>
                  </li>
                  <li
                    onClick={() => {
                      setFAQCategory({
                        ...selectedFAQCategory,
                        subChildCategoryId: -1,
                      })
                      // dispatch(
                      //   homeActions.updateFAQDetails({
                      //     ...faqDetail,
                      //     ...{ faq_maters: [] },
                      //   })
                      // )
                    }}
                  >
                    <Link>{faqDetail.master_category_faq.categoryTitle}</Link>
                  </li>
                  <li>
                    {/* <p className="ex-small"> */}
                      <Link className='primary'>{faqDetail.categoryTitle}</Link>
                    {/* </p> */}
                  </li>
                </ul>
              ) : null}
             { selectedFAQCategory.expendedItemId == -1 && selectedFAQCategory.subChildCategoryId == 1 &&<Link
                className="black back-cover vietnam for-mobile"
                variant="caption"
                onClick={() => {
                  setSearchFAQ('')
                  setFAQCategory({
                    ...selectedFAQCategory,
                    subChildCategoryId: -1,
                  })
                }}
              >
                <img
                  src={Icons.BackChevronIcon}
                  alt="go back"
                  className="mr-8"
                />
                BACK
              </Link>}
              {faqDetail.faq_maters.length ? (
                <h4 className="monument primary mb-16 mt-16">
                  {faqDetail.categoryTitle}
                </h4>
              ) : null}
              {faqDetail.faq_maters.map((item) => {
                return (
                  <Accordion
                    key={item.id}
                    expanded={selectedQuestion == item.id}
                    onChange={handleExpendChange(item.id)}
                    elevation={0}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      onClick={() =>
                        tagMethodGTM(
                          `Trending FAQs`,
                          `Matter Trending FAQs`,
                          'Click',
                          `${item.question}-${selectedQuestion == item.id ? 'Collapse':'Expand'}`
                        )
                      }
                    >
                      <p className='medium'>{item.question}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* <p className="subText">{item.answer}</p> */}
                      <div className="subText answerText">
                        <MarkDown disableLinkClick={true} string={item.answer} />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                )
              })}

              {!faqDetail.faq_maters.length && (
                <h4 className="monument mt-20 mb-8">
                  {`${
                    searchFAQ
                      ? 'No results found for ' + `"${searchFAQ}"`
                      : 'No results found'
                  }`}
                </h4>
              )}
              {!faqDetail.faq_maters.length && (
                <p className="subText">
                  We couldn’t find what you searched for. Please try again.
                </p>
              )}
            </div>
          </Grid>
        </Grid>
      </section>
      {/* <Footer /> */}
      {isLoading && <AppLoader />}
    </div>
  )
}

export default CompanionAppFAQ

import Api from '../../api'
import { ActionTypes } from '../../constants'
import { endpoints } from '../../api/endPoints'
import { API_SUCCESS_CODE } from '../../api/constant'
import {
  BIKE_MODEL_NAME,
  FAQCategory,
  FAQDetails,
  InnovationHubDetails,
  WhoWeAreEvents,
  WhoWeAreLeaders,
  WhoWeAreRewards,
  Blogs,
  BlogsCategory,
  News,
  Awards,
  PressReleases,
  Leadership,
  LogosAndTrademark,
  Aera5000,
  Aera5000Plus,
  ProductPortfolio,
  LegalPolicy,
  PrivacyPolicy,
  Aera5000e,

} from '../../constants/model'
import PrebookingActions from '../prebookingActions'
import moment from 'moment'
import { ReducerModel } from '../../reducers/reducerModel'

const formatPriceBikes = (availableLoc: Array<any>) => {
  const loc: any = {}

  availableLoc.forEach((item, index) => {
    if (item.priceBreakUp) {
      item.priceBreakUp.productPrice =
        item.priceBreakUp.bikeOriginalPrice || '0'
      const bikeDetails = {
        bikeModelName: item.bikeModelName,
        priceBreakUp: item.priceBreakUp,
      }
      if (!loc[item.city + item.state]) {
        loc[item.city + item.state] = {
          state: item.state,
          city: item.city,
          availableBikes: [bikeDetails],
        }
      } else {
        if (
          !loc[item.city + item.state].availableBikes.find(
            (subItem: any) => subItem.bikeModelName == item.bikeModelName
          )
        ) {
          loc[item.city + item.state].availableBikes.push(bikeDetails)
        }
      }
    }
  })

  const newData = []
  for (const key in loc) {
    newData.push(loc[key])
  }

  return newData.filter((item) => item.availableBikes.length)
}

const getLocationList = (success: Function, fail: Function) => {
  return (dispatch: Function, getState: Function) => {
    Api.getApiCall(
      endpoints.product.locationListings,
      '',
      (response: any) => {
        if (response) {
          const { error, responseCode, data, message } = response
          if (!error && responseCode == API_SUCCESS_CODE.success) {
            const validLocations =
              (data &&
                data.filter(
                  (item: any) =>
                    item.bikeModelName === BIKE_MODEL_NAME.AERA_5000 ||
                    item.bikeModelName === BIKE_MODEL_NAME.AERA_5000_PLUE
                )) ||
              []

            const formatedLoc = formatPriceBikes(validLocations)

            formatedLoc.map((item) => {
              const fiveThousandData = item.availableBikes.filter(
                (bikeItem: any) =>
                  bikeItem.bikeModelName == BIKE_MODEL_NAME.AERA_5000
              )
              const fiveThousandPlusData = item.availableBikes.filter(
                (bikeItem: any) =>
                  bikeItem.bikeModelName == BIKE_MODEL_NAME.AERA_5000_PLUE
              )

              item.availableBikes = [
                ...fiveThousandData,
                ...fiveThousandPlusData,
              ]
            })
            dispatch({
              type: ActionTypes.UPDATE_AVAILABLE_LOCATIONS_LIST,
              payload: { availableLocationList: formatedLoc || [] },
            })
            success(formatedLoc)
            return
          }
          fail({ message })
          return
        }
        fail()
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.UPDATE_AVAILABLE_LOCATIONS_LIST,
          payload: { availableLocationList: [] },
        })
        dispatch(PrebookingActions.resetCreatePreBooking())
        fail(error)
      }
    )
  }
}

const getModifyLocationList = (
  orderId: string,
  success: Function,
  fail: Function
) => {
  return (dispatch: Function, getState: Function) => {
    Api.getApiCall(
      endpoints.product.locationListings + `?orderId=${orderId}`,
      '',
      (response: any) => {
        if (response) {
          const { error, responseCode, data, message } = response
          if (!error && responseCode == API_SUCCESS_CODE.success) {
            const validLocations =
              (data &&
                data.filter(
                  (item: any) =>
                    item.bikeModelName === BIKE_MODEL_NAME.AERA_5000 ||
                    item.bikeModelName === BIKE_MODEL_NAME.AERA_5000_PLUE
                )) ||
              []

            const formatedLoc = formatPriceBikes(validLocations)

            formatedLoc.map((item) => {
              const fiveThousandData = item.availableBikes.filter(
                (bikeItem: any) =>
                  bikeItem.bikeModelName == BIKE_MODEL_NAME.AERA_5000
              )
              const fiveThousandPlusData = item.availableBikes.filter(
                (bikeItem: any) =>
                  bikeItem.bikeModelName == BIKE_MODEL_NAME.AERA_5000_PLUE
              )

              item.availableBikes = [
                ...fiveThousandData,
                ...fiveThousandPlusData,
              ]
            })
            dispatch({
              type: ActionTypes.UPDATE_AVAILABLE_LOCATIONS_LIST,
              payload: { availableLocationList: formatedLoc || [] },
            })
            success(formatedLoc)
            return
          }
          fail({ message })
          return
        }
        fail()
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.UPDATE_AVAILABLE_LOCATIONS_LIST,
          payload: { availableLocationList: [] },
        })
        dispatch(PrebookingActions.resetCreatePreBooking())
        fail(error)
      }
    )
  }
}

const getCancelReasons = () => {
  return (dispatch: Function, getState: Function) => {
    Api.getApiCall(
      endpoints.product.cancelReason,
      '',
      (response: any) => {
        const { responseCode, data, error, message } = response
        if (data && !error && responseCode == API_SUCCESS_CODE.success) {
          dispatch({
            type: ActionTypes.CANCEL_REASONS,
            payload: { cancelReasons: data || [] },
          })
        } else {
          dispatch({
            type: ActionTypes.CANCEL_REASONS,
            payload: { cancelReasons: [] },
          })
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.CANCEL_REASONS,
          payload: { cancelReasons: [] },
        })
      }
    )
  }
}

// Strapi Banner api
// GET banner data

const getBannerStrapiApi = () => {
  return (dispatch: Function, getState: Function) => {
    Api.getStrapiApiCall(
      endpoints.strapi.topBanner,
      '',
      (response: any) => {
        if (response) {
          const { data } = response
          dispatch({
            type: ActionTypes.TOP_BANNER,
            payload: {
              topHomeBanner: {
                desktopmessage: data?.attributes?.desktopmessage || '',
                mobilemessage: data?.attributes?.mobilemessage || '',
              },
            },
          })
        } else {
          dispatch({
            type: ActionTypes.TOP_BANNER,
            payload: {
              topHomeBanner: {
                desktopmessage: '',
                mobilemessage: '',
              },
            },
          })
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.TOP_BANNER,
          payload: {
            topHomeBanner: {
              desktopmessage: '',
              mobilemessage: '',
            },
          },
        })
      }
    )
  }
}

//Contact Us Get Strapi api

const getContactUsStrapiApi = () => {
  return (dispatch: Function, getState: Function) => {
    Api.getStrapiApiCall(
      endpoints.strapi.contactUs,
      '',
      (apiRespone: any) => {
        if (apiRespone) {
          const { data } = apiRespone
          dispatch({
            type: ActionTypes.CONTACT_US,
            payload: {
              contactUs: {
                email: data?.attributes?.email || '',
                callUs: data?.attributes?.callUs || '',
                whatsApp: data?.attributes?.whatsApp || '',
              },
            },
          })
        } else {
          dispatch({
            type: ActionTypes.CONTACT_US,
            payload: {
              contactUs: {
                email: '',
                callUs: '',
                whatsApp: '',
              },
            },
          })
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.CONTACT_US,
          payload: {
            contactUs: {
              email: '',
              callUs: '',
              whatsApp: '',
            },
          },
        })
      }
    )
  }
}

// const getHomePageDetails = () => {
//   let dataaa = {
//     data: {
//       id: 1,
//       attributes: {
//         exploreMatterTitle:
//           'An electric motorbike that not only shifts gears but also paradigms.',
//         channelPartnerTitle: 'Partner with us',
//         channelPartnerDescription:
//           "If you believe if providing exceptional experiences, and  buy into the idea of an electric future, let's explore synergies!",
//         createdAt: '2024-02-07T04:16:00.962Z',
//         updatedAt: '2024-02-07T04:17:14.673Z',
//         publishedAt: '2024-02-07T04:17:14.671Z',
//       },
//     },
//     meta: {},
//   }

//   const {
//     data: {
//       attributes: {
//         exploreMatterTitle,
//         channelPartnerTitle,
//         channelPartnerDescription,
//       },
//     },
//   } = dataaa

//   return (dispatch: Function, getState: Function) => {
//     dispatch({
//       type: ActionTypes.HOME_PAGE_DETAILS,
//       payload: {
//         homeDetails: {
//           exploreMatterTitle,
//           channelPartnerTitle,
//           channelPartnerDescription,
//         },
//       },
//     })

// Api.getStrapiApiCall(
//   endpoints.strapi.homePage,
//   '',
//   (apiRespone: any) => {
//     if (apiRespone) {
//       const { data } = apiRespone
//       console.log('getHomePageDetails', apiRespone)
//       // dispatch({
//       //   type: ActionTypes.HOME_PAGE_DETAILS,
//       //   payload: {},
//       // })
//     } else {
//       // dispatch({
//       //   type: ActionTypes.HOME_PAGE_DETAILS,
//       //   payload: {},
//       // })
//     }
//   },
//   (error: any) => {
//     // dispatch({
//     //   type: ActionTypes.HOME_PAGE_DETAILS,
//     //   payload: {},
//     // })
//   }
// )
//   }
// }

const getproductSpecStrapiApi = () => {
  return (dispatch: Function, getState: Function) => {
    Api.getStrapiApiCall(
      endpoints.strapi.productSpec,
      '',
      (apiRespone: any) => {
        if (apiRespone) {
          const { data } = apiRespone

          dispatch({
            type: ActionTypes.PRODUCT_SPEC,
            payload: {
              productSpec: {
                productData: data[0]?.attributes?.productSpec || [],
                MVAppProductSpec: data[0]?.attributes?.MVAppProductSpec || [],
                instructions: data[0]?.attributes?.instructions || [],
                termsandconditions: data[0].attributes.termsandconditions || []
              },
            },
          })
        } else {
          dispatch({
            type: ActionTypes.PRODUCT_SPEC,
            payload: {
              productSpec: {
                productData: [],
                MVAppProductSpec: [],
                instructions: [],
                termsandconditions: []
              },
            },
          })
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.PRODUCT_SPEC,
          payload: {
            productSpec: {
              productData: [],
              MVAppProductSpec: [],
              instructions: [],
              termsandconditions: []
            },
          },
        })
      }
    )
  }
}

const getHomePageDetails = () => {
  return (dispatch: Function, getState: Function) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.homePage
      : endpoints.strapi.homePage

    Api.getStrapiApiCall(
      apiEndpoint,
      '',
      (response: any) => {
        if (response) {

          const { data } = response
          dispatch({
            type: ActionTypes.HOME_PAGE_DETAILS,
            payload: {
              HomeDetails: {
                sec1heading: data?.attributes?.section_1_heading || '',
                sec1learnmore: data?.attributes?.section_1_learn_more || '',
                sec1CTA: data?.attributes?.section_1_cta || '',
                sec2Heading: data?.attributes?.section_2_heading || '',
                sec2Img: data?.attributes?.section_2_img?.data.attributes?.formats?.large?.url || '',
                sec2Img2: data?.attributes?.section_2_img_2?.data.attributes?.url || '',
                sec2CTA: data?.attributes?.section_2_cta || '',
                sec3Redefine: data?.attributes?.section_3_redifining || '',
                sec3Reimagine: data?.attributes?.section_3_reimagining || '',
                partnerHeading: data?.attributes?.partner_channel_heading || '',
                partnerOTO: data?.attributes?.partner_channel_oto?.data?.attributes?.url || '',
                partnerWithUs: data?.attributes?.partner_with_us_heading || '',
                partnerWithUsContent: data?.attributes?.partner_with_us_content || '',
                partnerWithUsCTA: data?.attributes?.partner_with_us_cta || '',
                exploreHeading: data?.attributes?.explore_the_era_heading || '',
                footerText: data?.attributes?.footer_heading || '',
                Interest: data?.attributes?.interest || '',
                InterestCTA: data?.attributes?.interest_cta || '',
                partnerSubtext: data?.attributes?.partner_channel_subtext || '',
                footerCTA: data?.attributes?.footer_cta || '',
                footerCopyrights: data?.attributes?.footer_copyrights_text || '',
                partnerImg1: data?.attributes?.partner_channel_img1?.data?.attributes?.formats?.large?.url || '',
                partnerImg1Pre: data?.attributes?.partner_channel_img1_pre?.data?.attributes?.formats?.small?.url || '',
                partnerImg4: data?.attributes?.partner_channel_img4?.data?.attributes?.url || '',
                partnerImg2: data?.attributes?.partner_channel_img2?.data?.attributes?.url || '',
                partnerUsImg: data.attributes.partner_with_us_img.data.attributes.url || '',
                partnerFlipkart: data?.attributes?.partner_channel_flipkart?.data?.attributes?.url || '',
                footerInsta: data?.attributes?.footer_instagram?.data?.attributes.url || '',
                footerLinkedin: data?.attributes?.footer_linkedin?.data?.attributes.url || '',
                footerYoutube: data?.attributes?.footer_youtube?.data?.attributes.url || '',
                footerX: data?.attributes?.footer_X?.data?.attributes.url || '',
                footerFacebook: data?.attributes?.footer_facebook?.data?.attributes.url || '',
                Launches: data?.attributes?.upcoming_launches || '',
                LaunchesContent: data?.attributes?.upcoming_launches_content || '',
                top_banner_image: data?.attributes?.top_banner_image?.data?.attributes?.url,
                top_banner_image_mob: data?.attributes?.top_banner_image_mob?.data?.attributes?.url,
              },
            },
          })
        } else {
          dispatch({
            type: ActionTypes.HOME_PAGE_DETAILS,
            payload: {
              HomeDetails: {
                sec1heading: '', sec1learnmore: '', sec1CTA: '', sec2Heading: '', sec2Img: '', sec2Img2: '', sec2CTA: '', sec3Redefine: '',
                sec3Reimagine: '', partnerHeading: '', partnerOTO: '', partnerWithUs: '', partnerWithUsContent: '', partnerWithUsCTA: '',
                exploreHeading: '', footerText: '', Interest: '', InterestCTA: '', partnerSubtext: '', footerCTA: '', footerCopyrights: '',
                partnerImg1: '', partnerImg4: '', partnerImg2: '', partnerUsImg: '', partnerFlipkart: '', footerInsta: '', footerLinkedin: '',
                footerYoutube: '', footerX: '', footerFacebook: '', Launches: '', LaunchesContent: '', partnerImg1Pre: '',
              },
            },
          })
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.HOME_PAGE_DETAILS,
          payload: {
            HomeDetails: {
              sec1heading: '', sec1learnmore: '', sec1CTA: '', sec2Heading: '', sec2Img: '', sec2Img2: '', sec2CTA: '', sec3Redefine: '',
              sec3Reimagine: '', partnerHeading: '', partnerOTO: '', partnerWithUs: '', partnerWithUsContent: '', partnerWithUsCTA: '',
              exploreHeading: '', footerText: '', Interest: '', InterestCTA: '', partnerSubtext: '', footerCTA: '', footerCopyrights: '',
              partnerImg1: '', partnerImg4: '', partnerImg2: '', partnerUsImg: '', partnerFlipkart: '', footerInsta: '', footerLinkedin: '',
              footerYoutube: '', footerX: '', footerFacebook: '', Launches: '', LaunchesContent: '', partnerImg1Pre: '',
            },
          },
        })
      }
    )
  }
}

// FAQ Categories API

const getFaqCategoriesStrapiApi = (
  successCallback: Function,
  failureCallback: Function
) => {
  return (dispatch: Function) => {
    Api.getStrapiApiCall(
      endpoints.strapi.masterFAQCategory,
      '',
      (apiRespone: any) => {
        if (apiRespone) {
          const formatedMasterCategory: Array<any> =
            apiRespone?.data.map((item: any) => {
              const formatedResponse: any = {
                id: item.id,
                ...item.attributes,
              }
              formatedResponse.child_category_faqs =
                formatedResponse.child_category_faqs?.data?.map(
                  (childCategory: any) => {
                    const childFormatedResponse: any = {
                      id: childCategory.id,
                      ...childCategory.attributes,
                    }
                    return childFormatedResponse
                  }
                ) || []
              return formatedResponse
            }) || []
          dispatch({
            type: ActionTypes.FAQ_CATEGORIES,
            payload: {
              faqCategories: formatedMasterCategory,
            },
          })
          if (
            formatedMasterCategory &&
            formatedMasterCategory.length &&
            formatedMasterCategory[0].child_category_faqs.length
          ) {
            dispatch(
              getCategoryFAQs(
                formatedMasterCategory[0].child_category_faqs[0].id,
                successCallback,
                failureCallback
              )
            )
          }
        } else {
          dispatch({
            type: ActionTypes.FAQ_CATEGORIES,
            payload: {
              faqCategories: [],
            },
          })
          failureCallback()
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.FAQ_CATEGORIES,
          payload: {
            faqCategories: [],
          },
        })
        failureCallback(error)
      }
    )
  }
}

const getCategoryFAQs = (
  childCategoryid: number,
  sucessCallback: Function,
  failureCallback: Function
) => {
  return (dispatch: Function) => {
    Api.getStrapiApiCall(
      endpoints.strapi.childCategoryFAQList,
      `/${childCategoryid}?populate=*`,
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data) {
          const { id, attributes } = apiRespone.data
          const formatedFAQ: FAQDetails = {
            id: id,
            categoryTitle: attributes.categoryTitle,
            master_category_faq: {
              id: attributes.master_category_faq?.data?.id || -1,
              ...attributes.master_category_faq?.data?.attributes,
            },
            faq_maters:
              attributes?.faq_maters?.data
                .map((item: any) => {
                  // console.log("item =>",item)
                  const formatedQA = item.attributes.publishedAt && {
                    id: item.id,
                    ...item.attributes,
                  }
                  return formatedQA
                })
                .filter(Boolean) || [],
          }
          dispatch({
            type: ActionTypes.FAQ_DETAILS,
            payload: {
              faqDetail: formatedFAQ,
            },
          })
          sucessCallback(formatedFAQ)
          return
        } else {
          dispatch({
            type: ActionTypes.FAQ_DETAILS,
            payload: {
              faqDetail: new FAQDetails(),
            },
          })
          failureCallback()
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.FAQ_DETAILS,
          payload: {
            faqDetail: new FAQDetails(),
          },
        })
        failureCallback(error)
      }
    )
  }
}

const getMasterChildFAQ = (
  sucessCallback: Function,
  failureCallback: Function
) => {
  return (dispatch: Function) => {
    Api.getStrapiApiCall(
      endpoints.strapi.masterChildFAQ,
      `?populate=*`,
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data) {
          let trendingFAQ: Array<any> = []
          apiRespone.data.map((item: any) => {
            const trandingqa =
              item.attributes?.faq_maters?.data.map((faqItem: any) => {
                const formatedFQA = { id: faqItem.id, ...faqItem.attributes }
                return formatedFQA
              }) || []
            trendingFAQ.push(...trandingqa)
          })
          trendingFAQ.sort((a: any, b: any) => {
            //@ts-ignore
            return b.count - a.count
          })
          dispatch({
            type: ActionTypes.TRENDING_FAQ,
            payload: {
              trendingFAQ: trendingFAQ.slice(0, 5),
            },
          })

          sucessCallback(trendingFAQ)
          return
        } else {
          dispatch({
            type: ActionTypes.FAQ_DETAILS,
            payload: {
              trendingFAQ: [],
            },
          })
          failureCallback()
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.FAQ_DETAILS,
          payload: {
            trendingFAQ: [],
          },
        })
        failureCallback(error)
      }
    )
  }
}

const getFAQAppliedSearch = (
  search: string,
  sucessCallback: Function,
  failureCallback: Function
) => {
  return (dispatch: Function) => {
    Api.getStrapiApiCall(
      endpoints.strapi.faqList,
      `?populate=*&filters[question][$containsi]=${search}`,
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data) {
          const {
            meta: { pagination },
          } = apiRespone
          const formatedFaq = search
            ? apiRespone.data.map((item: any) => {
              const {
                attributes: {
                  publishedAt,
                  answer,
                  count,
                  isTrending,
                  question,
                  child_category_faq,
                },
                id,
              } = item
              return {
                publishedAt,
                answer,
                count,
                id,
                isTrending,
                question,
                subChildCategoryId: child_category_faq?.data?.id || 0,
              }
            })
            : []
          const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1)
          const faqDetail: FAQDetails = {
            id: 0,
            faq_maters: formatedFaq?.filter((item: any) => item.publishedAt),
            faq_matersCount: search ? pagination.total : 0,
            categoryTitle: `${pagination.total} Results "${capitalize(
              search
            )}"`,
            master_category_faq: new FAQCategory(),
          }
          dispatch({
            type: ActionTypes.FAQ_APPLIED_SEARCH,
            payload: {
              faqAppliedSearch: faqDetail,
            },
          })
          sucessCallback(faqDetail)
          return
        } else {
          dispatch({
            type: ActionTypes.FAQ_APPLIED_SEARCH,
            payload: {
              faqAppliedSearch: new FAQDetails(),
            },
          })
          failureCallback()
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.FAQ_APPLIED_SEARCH,
          payload: {
            faqAppliedSearch: new FAQDetails(),
          },
        })
        failureCallback(error)
      }
    )
  }
}

const resetFAQ = () => {
  return (dispatch: Function) => {
    dispatch({
      type: ActionTypes.RESET_FAQ,
      payload: {
        trendingFAQ: [],
        faqAppliedSearch: new FAQDetails(),
        faqDetail: new FAQDetails(),
      },
    })
  }
}

const updateFAQDetails = (faqDetails: FAQDetails) => {
  return (dispatch: Function) => {
    dispatch({
      type: ActionTypes.FAQ_DETAILS,
      payload: {
        faqDetail: faqDetails,
      },
    })
  }
}

const getAppConfig = () => {
  return (dispatch: Function, getState: Function) => {
    Api.getApiCall(
      endpoints.orbitConfig.config,
      '',
      (apiRespone: any) => {
        const { responseCode, data, error } = apiRespone
        if (responseCode == API_SUCCESS_CODE.success && !error && data) {
          const joinWaitListkey = data.find(
            (item: any) => item.configKey == 'join-wait-list'
          )?.configValue
          const { home }: ReducerModel = getState()
          dispatch({
            type: ActionTypes.UPDATE_WEB_APP_CONFIG,
            payload: {
              appConfig: {
                ...home.appConfig,
                joinWaitListkey: joinWaitListkey === 'true',
              },
            },
          })
        }

        console.log('apiRespone', apiRespone)
      },
      (error: any) => {
        console.log('error', error)
      }
    )
  }
}

const getInnovationHubDetails = () => {
  return (dispatch: Function) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.getInnovationTechStack
      : endpoints.strapi.getInnovationTechStack
    Api.getStrapiApiCall(
      apiEndpoint,
      `?populate=*`,
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data && apiRespone.data.length) {
          const {
            attributes: { innovation_hub },
          } = apiRespone.data[0]
          const innovationHubDetails = new InnovationHubDetails()
          if (innovation_hub.data.attributes) {
            const {
              videoUrl,
              publishedAt,
              techStackTitle,
              totalTradeMark,
              totalPatentFiled,
              intellactualTitle,
              techStackDescription,
              totalDesignRegistered,
              intellactualDescription,
            } = innovation_hub.data.attributes

            innovationHubDetails.videoUrl = videoUrl
            innovationHubDetails.publishedAt = publishedAt
              ; (innovationHubDetails.techStackTitle = techStackTitle),
                (innovationHubDetails.totalTradeMark = totalTradeMark),
                (innovationHubDetails.totalPatentFiled = totalPatentFiled),
                (innovationHubDetails.intellactualTitle = intellactualTitle),
                (innovationHubDetails.techStackDescription =
                  techStackDescription),
                (innovationHubDetails.totalDesignRegistered =
                  totalDesignRegistered),
                (innovationHubDetails.intellactualDescription =
                  intellactualDescription)
          }
          const formatedTechStack = apiRespone.data.map((item: any) => {
            if (item.attributes.publishedAt) {
              const {
                id,
                attributes: { description, imageUrl, title },
              } = item
              const { url } = imageUrl.data.attributes
              return { id, description, imageUrl: url, title }
            }
          })
          innovationHubDetails.techStackList = formatedTechStack
          dispatch({
            type: ActionTypes.INNOVATION_HUB,
            payload: {
              innovationHub: innovationHubDetails,
            },
          })
          return
        } else {
          dispatch({
            type: ActionTypes.INNOVATION_HUB,
            payload: {
              innovationHub: new InnovationHubDetails(),
            },
          })
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.INNOVATION_HUB,
          payload: {
            innovationHub: new InnovationHubDetails(),
          },
        })
      }
    )
  }
}

const getWhoWeAreAchivements = () => {
  return (dispatch: Function, getState: any) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.getWhoWeAreReward
      : endpoints.strapi.getWhoWeAreReward
    Api.getStrapiApiCall(
      apiEndpoint,
      `?populate=*`,
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data && apiRespone.data.length) {
          const { data } = apiRespone
          let rewardAchievmentTitle = ''
          const rewardList = data
            .map((item: any) => {
              const { id, attributes } = item
              if (attributes.publishedAt) {
                const {
                  rewardDescription,
                  rewardTitle,
                  rewardMedia,
                  who_we_are_reward_list,
                } = attributes
                if (!rewardAchievmentTitle && who_we_are_reward_list) {
                  rewardAchievmentTitle =
                    who_we_are_reward_list?.data?.attributes?.title || ''
                }
                return {
                  id,
                  rewardDescription,
                  rewardTitle,
                  rewardMedia: rewardMedia?.data?.attributes?.url || '',
                }
              }
            })
            .filter((item: any) => item)

          const rewardAndAchievment = {
            title: rewardAchievmentTitle,
            rewardList,
          }
          const {
            home: { whoWeAre },
          }: ReducerModel = getState()
          dispatch({
            type: ActionTypes.WHO_WE_ARE_REWARDS,
            payload: {
              whoWeAre: { ...whoWeAre, rewards: rewardAndAchievment },
            },
          })

          return
        } else {
          const {
            home: { whoWeAre },
          }: ReducerModel = getState()
          dispatch({
            type: ActionTypes.WHO_WE_ARE_REWARDS,
            payload: {
              whoWeAre: { ...whoWeAre, rewards: new WhoWeAreRewards() },
            },
          })
        }
      },
      (error: any) => {
        const {
          home: { whoWeAre },
        }: ReducerModel = getState()
        dispatch({
          type: ActionTypes.WHO_WE_ARE_REWARDS,
          payload: {
            whoWeAre: { ...whoWeAre, rewards: new WhoWeAreRewards() },
          },
        })
      }
    )
  }
}

const getWhoWeAreEvents = (successCallback: Function) => {
  return (dispatch: Function, getState: any) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.getWhoWeAreEvent
      : endpoints.strapi.getWhoWeAreEvent
    Api.getStrapiApiCall(
      apiEndpoint,
      `?populate=*`,
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data && apiRespone.data.length) {
          const { data } = apiRespone
          let ourStoryTitle = ''
          let ourStoryDescription = ''
          const eventList = data

            .map((item: any) => {
              const { id, attributes } = item
              if (attributes.publishedAt) {
                const {
                  eventDescription,
                  eventTitle,
                  eventYear,
                  who_we_are_story,
                  eventMediaUrl,
                } = attributes
                if (!ourStoryTitle && who_we_are_story) {
                  ourStoryTitle =
                    who_we_are_story?.data?.attributes?.title || ''
                  ourStoryDescription =
                    who_we_are_story?.data?.attributes?.description || ''
                }
                return {
                  id,
                  eventDescription,
                  eventYear,
                  eventTitle,
                  eventMedia: eventMediaUrl?.data?.attributes?.url || '',
                }
              }
            })
            .filter((item: any) => item)
            .sort(
              (a: any, b: any) => parseInt(a.eventYear) - parseInt(b.eventYear)
            )
          const ourStoryEventDetails = {
            title: ourStoryTitle,
            description: ourStoryDescription,
            eventsList: eventList,
          }
          const {
            home: { whoWeAre },
          }: ReducerModel = getState()
          dispatch({
            type: ActionTypes.WHO_WE_ARE_EVENTS,
            payload: {
              whoWeAre: { ...whoWeAre, ourStoryEvents: ourStoryEventDetails },
            },
          })
          successCallback && successCallback(eventList)
          return
        } else {
          const {
            home: { whoWeAre },
          }: ReducerModel = getState()
          dispatch({
            type: ActionTypes.WHO_WE_ARE_EVENTS,
            payload: {
              whoWeAre: { ...whoWeAre, ourStoryEvents: new WhoWeAreEvents() },
            },
          })
        }
      },
      (error: any) => {
        const {
          home: { whoWeAre },
        }: ReducerModel = getState()
        dispatch({
          type: ActionTypes.WHO_WE_ARE_EVENTS,
          payload: {
            whoWeAre: { ...whoWeAre, ourStoryEvents: new WhoWeAreEvents() },
          },
        })
      }
    )
  }
}

const getImageUrlFromStrapi = (mediaObj: any) => {
  return mediaObj?.data?.attributes?.url || ''
}
const getWhoWeAreDetails = () => {
  return (dispatch: Function, getState: any) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.getWHoWeAre
      : endpoints.strapi.getWHoWeAre
    Api.getStrapiApiCall(
      apiEndpoint,
      '',
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data && apiRespone.data.length) {
          const { data } = apiRespone
          if (data && data.length) {
            const {
              home: { whoWeAre },
            }: ReducerModel = getState()
            const {
              title,
              missionVisionAppImage,
              missionVisionWebImage,
              impactGoalDescription1,
              impactGoalDescription2,
              impactGoalTitle1,
              impactGoalTitle2,
            } = data[0].attributes
            dispatch({
              type: ActionTypes.WHO_WE_ARE_DETAILS,
              payload: {
                whoWeAre: {
                  ...whoWeAre,
                  title,
                  impactGoalDescription1,
                  impactGoalDescription2,
                  impactGoalTitle1,
                  impactGoalTitle2,
                  missionVisionAppImage: getImageUrlFromStrapi(
                    missionVisionAppImage
                  ),
                  missionVisionWebImage: getImageUrlFromStrapi(
                    missionVisionWebImage
                  ),
                },
              },
            })
          }
          return
        } else {
          const {
            home: { whoWeAre },
          }: ReducerModel = getState()
          dispatch({
            type: ActionTypes.WHO_WE_ARE_DETAILS,
            payload: {
              whoWeAre: { ...whoWeAre },
            },
          })
        }
      },
      (error: any) => {
        const {
          home: { whoWeAre },
        }: ReducerModel = getState()
        dispatch({
          type: ActionTypes.WHO_WE_ARE_DETAILS,
          payload: {
            whoWeAre: { ...whoWeAre },
          },
        })
      }
    )
  }
}

const getWhoWeAreLeaders = () => {
  return (dispatch: Function, getState: any) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.whoWeAreLeaders
      : endpoints.strapi.whoWeAreLeaders
    Api.getStrapiApiCall(
      apiEndpoint,
      '',
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data && apiRespone.data.length) {
          const { data } = apiRespone
          if (data && data.length) {
            let leadersTitle = ''
            let leadersDescription = ''
            const {
              home: { whoWeAre },
            }: ReducerModel = getState()
            const leadersList = data
              .map((item: any) => {
                const { id, attributes } = item
                if (attributes.publishedAt) {
                  const {
                    designation,
                    linkedInProfile,
                    name,
                    profileImage,
                    placementOrder,
                    profileImageMobile,
                    who_we_are_leader,
                  } = attributes
                  if (!leadersTitle && who_we_are_leader) {
                    leadersTitle =
                      who_we_are_leader?.data?.attributes?.title || ''
                    leadersDescription =
                      who_we_are_leader?.data?.attributes?.description || ''
                  }
                  return {
                    id,
                    designation,
                    linkedInProfile,
                    name,
                    placementOrder,
                    profileImage: getImageUrlFromStrapi(profileImage),
                    profileImageMobile:
                      getImageUrlFromStrapi(profileImageMobile),
                  }
                }
              })
              .filter((item: any) => item)
              .sort((a: any, b: any) => a.placementOrder - b.placementOrder)
            dispatch({
              type: ActionTypes.WHO_WE_ARE_LEADER_LIST,
              payload: {
                whoWeAre: {
                  ...whoWeAre,
                  leaders: {
                    title: leadersTitle,
                    description: leadersDescription,
                    leadersList,
                  },
                },
              },
            })
          }
          return
        } else {
          const {
            home: { whoWeAre },
          }: ReducerModel = getState()
          dispatch({
            type: ActionTypes.WHO_WE_ARE_LEADER_LIST,
            payload: {
              whoWeAre: { ...whoWeAre, leaders: new WhoWeAreLeaders() },
            },
          })
        }
      },
      (error: any) => {
        const {
          home: { whoWeAre },
        }: ReducerModel = getState()
        dispatch({
          type: ActionTypes.WHO_WE_ARE_LEADER_LIST,
          payload: {
            whoWeAre: { ...whoWeAre, leaders: new WhoWeAreLeaders() },
          },
        })
      }
    )
  }
}

//get all news
const getNews = () => {
  return (dispatch: Function, getState: any) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.getNews
      : endpoints.strapi.getNews
    Api.getStrapiApiCall(
      apiEndpoint,
      '',
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data) {
          let newsList: Array<News> = []
          const formatedNews = apiRespone.data.map((item: any) => {
            if (item.attributes.publishedAt) {
              const {
                id,
                attributes: { categoryName, news },
              } = item

              newsList = item.attributes.news.data
              let allnews: Array<News> = []
              news.data.map((newsItem: any) => {
                if (newsItem.attributes.publishedAt) {
                  const {
                    id,
                    attributes: {
                      newsDate,
                      newsPublisher,
                      newsTitle,
                      newsURL,
                      newsImg,
                      readTime
                    },
                  } = newsItem

                  const imgSrc = newsImg.data.attributes.url
                  const newsThumbNailImageWeb =
                    newsImg.data.attributes.formats.thumbnail.url

                  let news = new News()

                  news.id = id
                  news.newsTitle = newsTitle
                  news.newsDate = newsDate
                  news.newsPublisher = newsPublisher
                  news.newsURL = newsURL
                  news.newsImageWeb = imgSrc
                  news.newsThumbNailImageWeb = newsThumbNailImageWeb
                  news.readTime = readTime

                  allnews.push(news)
                }
              })
              return { id, categoryName, allnews }
            }
          })

          newsList = formatedNews

          dispatch({
            type: ActionTypes.NEWS,
            payload: {
              newsCategories: newsList,
            },
          })

          return
        } else {
          const {
            home: { newsCategories },
          }: ReducerModel = getState()
          dispatch({
            type: ActionTypes.NEWS,
            payload: {
              newsCategories: newsCategories,
            },
          })
        }
      },
      (error: any) => {
        const {
          home: { newsCategories },
        }: ReducerModel = getState()
        dispatch({
          type: ActionTypes.NEWS,
          payload: {
            newsCategories: newsCategories,
          },
        })
      }
    )
  }
}

//get all awards
const getAwards = () => {
  return (dispatch: Function, getState: any) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.getAwards
      : endpoints.strapi.getAwards
    Api.getStrapiApiCall(
      apiEndpoint,
      '',
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data) {
          let awardsList: Array<Awards> = []

          apiRespone.data.map((item: any) => {
            if (item.attributes.publishedAt) {

              const {
                id,
                attributes: {
                  awardYear,
                  awardPublisher,
                  awardTitle,
                  awardURL,
                  awardImg
                },
              } = item


              const imgSrc = awardImg.data.attributes.url
              const newsThumbNailImageWeb =
                awardImg.data.attributes.formats.thumbnail.url

              let awards = new Awards()

              awards.id = id
              awards.awardTitle = awardTitle
              awards.awardYear = awardYear
              awards.awardPublisher = awardPublisher
              awards.awardURL = awardURL
              awards.awardImageWeb = imgSrc
              awards.awardThumbNailImageWeb = newsThumbNailImageWeb

              awardsList.push(awards)

            }
          })

          dispatch({
            type: ActionTypes.AWARDS,
            payload: {
              awards: awardsList,
            },
          })

          return
        } else {
          const {
            home: { awards },
          }: ReducerModel = getState()
          dispatch({
            type: ActionTypes.AWARDS,
            payload: {
              awards: awards,
            },
          })
        }
      },
      (error: any) => {
        const {
          home: { awards },
        }: ReducerModel = getState()
        dispatch({
          type: ActionTypes.AWARDS,
          payload: {
            awards: awards,
          },
        })
      }
    )
  }
}

//get press releases
const getPressReleases = () => {
  return (dispatch: Function, getState: any) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.getPressRelease
      : endpoints.strapi.getPressRelease
    Api.getStrapiApiCall(
      apiEndpoint,
      '',
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data) {
          let pressList: Array<PressReleases> = []
          // console.log('response -----' + apiRespone.data.id)     
          apiRespone.data.map((item: any) => {
            if (item.attributes.publishedAt) {
              // console.log('item------' + item.attributes.publishedAt)
              //news.data.map((newsItem: any) => {
              //if (newsItem.attributes.publishedAt) {
              const {
                id,
                attributes: {
                  pressDate,
                  pressPublisher,
                  pressTitle,
                  pressURL,
                  pressImg,
                  pressFile
                },
              } = item

              // console.log(awardTitle)

              const imgSrc = pressImg.data.attributes.url
              const newsThumbNailImageWeb =
                pressImg.data.attributes.formats.thumbnail.url

              const file = pressFile.data.attributes.url

              let press = new PressReleases()

              press.id = id
              press.pressTitle = pressTitle
              press.pressDate = pressDate
              press.pressPublisher = pressPublisher
              press.pressURL = pressURL
              press.pressImageWeb = imgSrc
              press.pressThumbNailImageWeb = newsThumbNailImageWeb
              press.pressFile = file

              pressList.push(press)

            }
          })

          dispatch({
            type: ActionTypes.PRESS_RELEASES,
            payload: {
              pressRelease: pressList,
            },
          })

          return
        } else {
          const {
            home: { pressRelease },
          }: ReducerModel = getState()
          dispatch({
            type: ActionTypes.PRESS_RELEASES,
            payload: {
              pressRelease: pressRelease,
            },
          })
        }
      },
      (error: any) => {
        const {
          home: { pressRelease },
        }: ReducerModel = getState()
        dispatch({
          type: ActionTypes.PRESS_RELEASES,
          payload: {
            pressRelease: pressRelease,
          },
        })
      }
    )
  }
}

//get leadership 
const getLeadership = () => {
  return (dispatch: Function, getState: any) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.getLeadership
      : endpoints.strapi.getLeadership
    Api.getStrapiApiCall(
      apiEndpoint,
      '',
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data) {
          let leadership: Array<Leadership> = []
          // console.log('response -----' + apiRespone.data.id)     
          apiRespone.data.map((item: any) => {
            if (item.attributes.publishedAt) {
              // console.log('item------' + item.attributes.publishedAt)
              //news.data.map((newsItem: any) => {
              //if (newsItem.attributes.publishedAt) {
              const {
                id,
                attributes: {
                  founderName,
                  founderDesignation,
                  founderImg,
                  founderBio
                },
              } = item

              // console.log(awardTitle)

              const imgSrc = founderImg.data.attributes.url
              const newsThumbNailImageWeb =
                founderImg.data.attributes.formats.thumbnail.url

              const file = founderBio.data.attributes.url

              let leaders = new Leadership()

              leaders.id = id
              leaders.founderName = founderName
              leaders.founderDesignation = founderDesignation

              leaders.founderImageWeb = imgSrc
              leaders.founderThumbNailImageWeb = newsThumbNailImageWeb
              leaders.founderBio = file

              leadership.push(leaders)
              //}

            }
          })
          // console.log('formatted ------- ' + formatedAwards)

          dispatch({
            type: ActionTypes.LEADERSHIP,
            payload: {
              leadership: leadership,
            },
          })

          return
        } else {
          const {
            home: { leadership },
          }: ReducerModel = getState()
          dispatch({
            type: ActionTypes.LEADERSHIP,
            payload: {
              leadership: leadership,
            },
          })
        }
      },
      (error: any) => {
        const {
          home: { leadership },
        }: ReducerModel = getState()
        dispatch({
          type: ActionTypes.LEADERSHIP,
          payload: {
            leadership: leadership,
          },
        })
      }
    )
  }
}

//get logo and trademark 
const getLogoAndTrademark = () => {
  return (dispatch: Function, getState: any) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.getLogosAndTrademark
      : endpoints.strapi.getLogosAndTrademark
    Api.getStrapiApiCall(
      apiEndpoint,
      '',
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data) {
          let logoAndTrademarkAll: Array<LogosAndTrademark> = []
          // console.log('response -----' + apiRespone.data.id)     
          apiRespone.data.map((item: any) => {
            if (item.attributes.publishedAt) {
              // console.log('item------' + item.attributes.publishedAt)
              //news.data.map((newsItem: any) => {
              //if (newsItem.attributes.publishedAt) {
              const {
                id,
                attributes: {
                  logoAndTrademark
                },
              } = item

              const imgSrc = logoAndTrademark.data.attributes.url

              let logos = new LogosAndTrademark()

              logos.id = id

              logos.logoAndTrademark = imgSrc

              logoAndTrademarkAll.push(logos)
              //}

            }
          })

          dispatch({
            type: ActionTypes.LOGOSANDTRADEMARK,
            payload: {
              logosAndTrademark: logoAndTrademarkAll,
            },
          })

          return
        } else {
          const {
            home: { logosAndTrademark },
          }: ReducerModel = getState()
          dispatch({
            type: ActionTypes.LOGOSANDTRADEMARK,
            payload: {
              logosAndTrademark: logosAndTrademark,
            },
          })
        }
      },
      (error: any) => {
        const {
          home: { logosAndTrademark },
        }: ReducerModel = getState()
        dispatch({
          type: ActionTypes.LOGOSANDTRADEMARK,
          payload: {
            logosAndTrademark: logosAndTrademark,
          },
        })
      }
    )
  }
}


//get Aera 5000
const getAera5000 = () => {
  return (dispatch: Function, getState: any) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.getAera5000
      : endpoints.strapi.getAera5000
    Api.getStrapiApiCall(
      apiEndpoint,
      '',
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data) {
          let aera5000All: Array<Aera5000> = []
          // console.log('response -----' + apiRespone.data.id)     
          apiRespone.data.map((item: any) => {
            if (item.attributes.publishedAt) {
              // console.log('item------' + item.attributes.publishedAt)
              //news.data.map((newsItem: any) => {
              //if (newsItem.attributes.publishedAt) {
              const {
                id,
                attributes: {
                  aeraBikeImg
                },
              } = item

              const imgSrc = aeraBikeImg.data.attributes.url

              let aera5000 = new Aera5000()

              aera5000.id = id

              aera5000.aeraBikeImg = imgSrc

              aera5000All.push(aera5000)
              //}

            }
          })

          dispatch({
            type: ActionTypes.AERA5000,
            payload: {
              aera5000: aera5000All,
            },
          })

          return
        } else {
          const {
            home: { aera5000 },
          }: ReducerModel = getState()
          dispatch({
            type: ActionTypes.AERA5000,
            payload: {
              aera5000: aera5000,
            },
          })
        }
      },
      (error: any) => {
        const {
          home: { aera5000 },
        }: ReducerModel = getState()
        dispatch({
          type: ActionTypes.AERA5000,
          payload: {
            aera5000: aera5000,
          },
        })
      }
    )
  }
}

//get Aera 5000 Plus
const getAera5000Plus = () => {
  return (dispatch: Function, getState: any) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.getAera5000Plus
      : endpoints.strapi.getAera5000Plus
    Api.getStrapiApiCall(
      apiEndpoint,
      '',
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data) {
          let aera5000PlusAll: Array<Aera5000Plus> = []
          // console.log('response -----' + apiRespone.data.id)     
          apiRespone.data.map((item: any) => {
            if (item.attributes.publishedAt) {
              // console.log('item------' + item.attributes.publishedAt)
              //news.data.map((newsItem: any) => {
              //if (newsItem.attributes.publishedAt) {
              const {
                id,
                attributes: {
                  aeraBikeImg
                },
              } = item

              const imgSrc = aeraBikeImg.data.attributes.url

              let aera5000Plus = new Aera5000Plus()

              aera5000Plus.id = id

              aera5000Plus.aeraBikeImg = imgSrc

              aera5000PlusAll.push(aera5000Plus)
              //}

            }
          })

          dispatch({
            type: ActionTypes.AERA5000PLUS,
            payload: {
              aera5000Plus: aera5000PlusAll,
            },
          })

          return
        } else {
          const {
            home: { aera5000Plus },
          }: ReducerModel = getState()
          dispatch({
            type: ActionTypes.AERA5000PLUS,
            payload: {
              aera5000Plus: aera5000Plus,
            },
          })
        }
      },
      (error: any) => {
        const {
          home: { aera5000Plus },
        }: ReducerModel = getState()
        dispatch({
          type: ActionTypes.AERA5000PLUS,
          payload: {
            aera5000Plus: aera5000Plus,
          },
        })
      }
    )
  }
}

//get product portfolio
const getProductPortfolio = () => {
  return (dispatch: Function, getState: any) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.getProductPortfolio
      : endpoints.strapi.getProductPortfolio
    Api.getStrapiApiCall(
      apiEndpoint,
      '',
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data) {
          let productPortfolioAll: Array<ProductPortfolio> = []
          // console.log('response -----' + apiRespone.data.id)     
          apiRespone.data.map((item: any) => {
            if (item.attributes.publishedAt) {
              // console.log('item------' + item.attributes.publishedAt)
              //news.data.map((newsItem: any) => {
              //if (newsItem.attributes.publishedAt) {
              const {
                id,
                attributes: {
                  productName,
                  downloadAll,
                  specsheet
                },
              } = item

              const download = downloadAll.data.attributes.url
              const specsheetFile = specsheet.data.attributes.url

              let productPortfolio = new ProductPortfolio()

              productPortfolio.id = id

              productPortfolio.productName = productName
              productPortfolio.downloadAll = download
              productPortfolio.specsheet = specsheetFile

              productPortfolioAll.push(productPortfolio)
              //}

            }
          })

          dispatch({
            type: ActionTypes.PRODUCTPORTFOLIO,
            payload: {
              productPortfolio: productPortfolioAll,
            },
          })

          return
        } else {
          const {
            home: { productPortfolio },
          }: ReducerModel = getState()
          dispatch({
            type: ActionTypes.PRODUCTPORTFOLIO,
            payload: {
              productPortfolio: productPortfolio,
            },
          })
        }
      },
      (error: any) => {
        const {
          home: { productPortfolio },
        }: ReducerModel = getState()
        dispatch({
          type: ActionTypes.PRODUCTPORTFOLIO,
          payload: {
            productPortfolio: productPortfolio,
          },
        })
      }
    )
  }
}

//get all categories and blogs
const getBlogs = () => {
  return (dispatch: Function, getState: any) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.getCategoryBlogs
      : endpoints.strapi.getCategoryBlogs
    Api.getStrapiApiCall(
      apiEndpoint,
      '',
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data) {
          let blogs: Array<BlogsCategory> = []
          const formatedBlogs = apiRespone.data.map((item: any) => {
            if (item.attributes.publishedAt) {
              const {
                id,
                attributes: { categoryName, blogs },
              } = item

              let allblogs: Array<Blogs> = []
              blogs.data.map((blogItem: any) => {
                if (blogItem.attributes.publishedAt) {
                  const {
                    id,
                    attributes: {
                      title,
                      publishDate,
                      blogIntroduction,
                      readTime,
                      matterBlogImageWeb,
                      matterBlogImageApp,
                    },
                  } = blogItem

                  const { url } = matterBlogImageWeb.data.attributes
                  const matterBlogThumbNailImageWeb =
                    matterBlogImageWeb.data.attributes.formats.thumbnail.url
                  const urlAppImg = matterBlogImageApp.data.attributes.url
                  const matterBlogThumbNailImageApp =
                    matterBlogImageApp.data.attributes.formats.thumbnail.url

                  let blog = new Blogs()

                  blog.id = id
                  blog.title = title
                  blog.publishDate = publishDate
                  blog.blogIntroduction = blogIntroduction
                  blog.readTime = readTime
                  blog.matterBlogImageWeb = url
                  blog.matterBlogImageApp = urlAppImg
                  blog.matterBlogThumbNailImageWeb = matterBlogThumbNailImageWeb
                  blog.matterBlogThumbNailImageApp = matterBlogThumbNailImageApp
                  allblogs.push(blog)
                }
              })
              return { id, categoryName, allblogs }
            }
          })

          blogs = formatedBlogs

          dispatch({
            type: ActionTypes.BLOGS,
            payload: {
              blogsCategories: blogs,
            },
          })

          return
        } else {
          const {
            home: { blogsCategories },
          }: ReducerModel = getState()
          dispatch({
            type: ActionTypes.BLOGS,
            payload: {
              blogsCategories: blogsCategories,
            },
          })
        }
      },
      (error: any) => {
        const {
          home: { blogsCategories },
        }: ReducerModel = getState()
        dispatch({
          type: ActionTypes.BLOGS,
          payload: {
            blogsCategories: blogsCategories,
          },
        })
      }
    )
  }
}

//Get hyper local FAQ categories
const getHyperLocalFaqCategoriesStrapiApi = (
  successCallback: Function,
  failureCallback: Function
) => {
  return (dispatch: Function) => {
    Api.getStrapiApiCall(
      endpoints.strapi.masterFAQCategory,
      '',
      (apiRespone: any) => {
        if (apiRespone) {
          const formatedMasterCategory: Array<any> =
            apiRespone?.data.map((item: any) => {
              const formatedResponse: any = {
                id: item.id,
                ...item.attributes,
              }
              formatedResponse.child_category_faqs =
                formatedResponse.child_category_faqs?.data?.map(
                  (childCategory: any) => {
                    const childFormatedResponse: any = {
                      id: childCategory.id,
                      ...childCategory.attributes,
                    }
                    return childFormatedResponse
                  }
                ) || []
              return formatedResponse
            }) || []
          dispatch({
            type: ActionTypes.FAQ_CATEGORIES,
            payload: {
              faqCategories: formatedMasterCategory,
            },
          })
          if (
            formatedMasterCategory &&
            formatedMasterCategory.length &&
            formatedMasterCategory[1].child_category_faqs.length
          ) {
            dispatch(
              getCategoryFAQs(
                formatedMasterCategory[1].child_category_faqs[2].id,
                successCallback,
                failureCallback
              )
            )
          }
        } else {
          dispatch({
            type: ActionTypes.FAQ_CATEGORIES,
            payload: {
              faqCategories: [],
            },
          })
          failureCallback()
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.FAQ_CATEGORIES,
          payload: {
            faqCategories: [],
          },
        })
        failureCallback(error)
      }
    )
  }
}

//get full detailed blog
const getFullBlogs = (id: number) => {
  return (dispatch: Function, getState: any) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.getFullBlogs(id)
      : endpoints.strapi.getFullBlogs(id)
    Api.getStrapiApiCall(
      apiEndpoint,
      '',
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data) {
          const blogDetails = new Blogs()
          if (apiRespone.data.attributes) {
            const {
              title,
              publishDate,
              readTime,
              blogIntroduction,
              matterBlogImageWeb,
              blogImageDescription,
              blogStream1,
              blogStream2,
              blogExtraPictures,
              extraPicturesDescription,
              metaTitle,
              metaDescription
            } = apiRespone.data.attributes

            blogDetails.title = title
            blogDetails.publishDate = publishDate
            blogDetails.readTime = readTime
            blogDetails.blogIntroduction = blogIntroduction
            const { url } = matterBlogImageWeb.data.attributes
            blogDetails.matterBlogImageWeb = url
            const matterBlogThumbNailImageWeb =
              matterBlogImageWeb.data.attributes.formats.thumbnail.url
            blogDetails.matterBlogThumbNailImageWeb =
              matterBlogThumbNailImageWeb
            blogDetails.blogImageDescription = blogImageDescription
            blogDetails.blogStream1 = blogStream1
            blogDetails.blogStream2 = blogStream2
            blogDetails.blogExtraPictures = blogExtraPictures.data.map(
              (item: any) => {
                const { url } = item.attributes
                return url
              }
            )
            blogDetails.extraPicturesDescription = extraPicturesDescription
            blogDetails.metaTitle = metaTitle
            blogDetails.metaDescription = metaDescription
          }

          dispatch({
            type: ActionTypes.BLOG_DETAILS,
            payload: {
              blogDetails: blogDetails,
            },
          })
          return
        } else {
          const {
            home: { blogDetails },
          }: ReducerModel = getState()
          dispatch({
            type: ActionTypes.BLOG_DETAILS,
            payload: {
              blogDetails: blogDetails,
            },
          })
        }
      },
      (error: any) => {
        const {
          home: { blogDetails },
        }: ReducerModel = getState()
        dispatch({
          type: ActionTypes.BLOG_DETAILS,
          payload: {
            blogDetails: blogDetails,
          },
        })
      }
    )
  }
}

const getJoinWaitlist = (success: Function, fail: Function) => {
  return (dispatch: Function, getState: any) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.joinWaitlist
      : endpoints.strapi.joinWaitlist

    Api.getStrapiApiCall(
      apiEndpoint,
      '',
      (apiRespone: any) => {
        if (apiRespone && apiRespone.data && apiRespone?.data?.attributes) {
          const data = apiRespone?.data?.attributes
          const formatedTC = {
            title: data.title,
            introduction: data.introduction,
            listContent: [
              { title: data.definationTitle, description: data.definitions },
              {
                title: data.registrationAndWaitlistProcedureTitle,
                description: data.registrationAndWaitlistProcedure,
              },
              {
                title: data.generalTermsAndConditionsTitle,
                description: data.generalTermsAndConditions,
              },
              { title: data.governingLawTitle, description: data.governingLaw },
              { title: data.arbitrationTitle, description: data.arbitration },
              { title: data.contactUsTitle, description: data.contactUs },
            ].filter((item) => item.title),
          }
          dispatch({
            type: ActionTypes.TERM_CONDITION_JOIN_WAITLIST,
            payload: { joinWaitlistTC: formatedTC },
          })
          return
        } else {
        }
      },
      (error: any) => {}
    )
    // var requestOptions = {
    //   method: 'GET',
    //   redirect: 'follow'
    // };
    // fetch("http://4.188.238.31:1337/api/t-and-c-join-waitlist?populate=*", requestOptions)
    //   .then(response => response.json())
    //   .then(result => {
    //     if (result?.data?.attributes) {
    //       const data = result?.data?.attributes
    //       const formatedTC = {
    //         title: data.title,
    //         introduction: data.introduction,
    //         listContent: [
    //           { title: data.definationTitle, description: data.definitions },
    //           {
    //             title: data.registrationAndWaitlistProcedureTitle,
    //             description: data.registrationAndWaitlistProcedure,
    //           },
    //           {
    //             title: data.generalTermsAndConditionsTitle,
    //             description: data.generalTermsAndConditions,
    //           },
    //           { title: data.governingLawTitle, description: data.governingLaw },
    //           { title: data.arbitrationTitle, description: data.arbitration },
    //           { title: data.contactUsTitle, description: data.contactUs },
    //         ].filter((item) => item.title),
    //       }
    //       dispatch({type : ActionTypes.TERM_CONDITION_JOIN_WAITLIST, payload : {joinWaitlistTC : formatedTC} })
    //     }
    //   })
    //   .catch(error => {

    //   } );
  }
}

const getLegalPolicy = (success: Function, fail: Function) => {
  return (dispatch: Function, getState: Function) => {
    const apiEndpoint = process.env.STRAPI_ENV ? endpoints.strapi_dev.legalpolicy : endpoints.strapi.legalpolicy;
    Api.getStrapiApiCall(apiEndpoint, '',
      (apiResponse: any) => {
        if (apiResponse) {
          const { data } = apiResponse;
          console.log("the response of data is ", data.attributes.title)

          dispatch({
            type: ActionTypes.TERMS_CONDITIONS_LEGAL_POLICY,
            payload: {
              LegalPolicy: {
                title: data?.attributes?.title || '',
                introduction: data?.attributes?.introduction || '',
                userAgreement_title: data?.attributes?.userAgreement_title || '',
                disclaimer_title: data?.attributes?.disclaimer_title || '',
                notices_title: data?.attributes?.notices_title || '',
                eligibility_title: data?.attributes?.eligibility_title || '',
                acc_reg_obligations_title: data?.attributes?.acc_reg_obligations_title || '',
                use_of_the_platforms_title: data?.attributes?.use_of_the_platforms_title || '',
                usage_restrictions: data?.attributes?.usage_restrictions || '',
                userAgreement: data?.attributes?.userAgreement || '',
                amendments_title: data?.attributes?.amendments_title || '',
                amendments: data?.attributes?.amendments || '',
                disclaimer: data?.attributes?.disclaimer || '',
                notices: data?.attributes?.notices || '',
                eligibility: data?.attributes?.eligibility || '',
                limitation_liability_title: data?.attributes?.limitation_liability_title || '',
                limitation_liability: data?.attributes?.limitation_liability || '',
                acc_reg_obligations: data?.attributes?.acc_reg_obligations || '',
                use_of_the_platforms: data?.attributes?.use_of_the_platforms || '',
                usage_restrictions_title: data?.attributes?.usage_restrictions_title || '',
                proprietary_rights_title: data?.attributes?.proprietary_rights_title || '',
                proprietary_rights: data?.attributes?.proprietary_rights || '',
                claimsOfInfringement_title: data?.attributes?.claimsOfInfringement_title || '',
                claimsOfInfringement: data?.attributes?.claimsOfInfringement || '',
                LinksToThirdPartyWebsites_title: data?.attributes?.LinksToThirdPartyWebsites_title || '',
                LinksToThirdPartyWebsites: data?.attributes?.LinksToThirdPartyWebsites || '',
                Privacy_title: data?.attributes?.Privacy_title || '',
                Privacy: data?.attributes?.Privacy || '',
                onlinePreBookingTNC_title: data?.attributes?.onlinePreBookingTNC_title || '',
                onlinePreBookingTNC: data?.attributes?.onlinePreBookingTNC || '',
                termination_title: data?.attributes?.termination_title || '',
                termination: data?.attributes?.termination || '',
                non_waiver_title: data?.attributes?.non_waiver_title || '',
                non_waiver: data?.attributes?.non_waiver || '',
                general_title: data?.attributes?.general_title || '',
                general: data?.attributes?.general || '',
                indemnity_title: data?.attributes?.indemnity_title || '',
                indemnity: data?.attributes?.indemnity || '',
                grievane_officer_title: data?.attributes?.grievane_officer_title || '',
                grievane_officer: data?.attributes?.grievane_officer || '',
                governingLawTitle: data?.attributes?.governingLawTitle || '',
                governingLaw: data?.attributes?.governingLaw || '',
                abitrations_title: data?.attributes?.abitrations_title || '',
                arbitrations: data?.attributes?.arbitrations || '',
              }
            }
          }
          )

        } else {
          dispatch({
            type: ActionTypes.TERMS_CONDITIONS_LEGAL_POLICY,
            payload: {
              LegalPolicy: ''
            }
          })

        }

      },
      (error: any) => {
        console.log(error);
      }
    )
  }
}

const getPrivacyPolicy = (success: Function, fail: Function) => {
  return (dispatch: Function, getState: Function) => {
    const apiEndpoint = process.env.STRAPI_ENV ? endpoints.strapi_dev.PrivacyPolicy : endpoints.strapi.PrivacyPolicy;
    Api.getStrapiApiCall(apiEndpoint, '',
      (apiResponse: any) => {
        if (apiResponse) {
          const { data } = apiResponse;
          dispatch({
            type: ActionTypes.PRIVACY_POLICY,
            payload: {
              PrivacyPolicy: {
                title: data?.attributes?.title,
                introduction: data?.attributes?.introduction || '',
                collectionOfUsersData: data?.attributes?.collectionOfUsersData || '',
                collectionOfUsersDataContent: data?.attributes?.collectionOfUsersDataContent || '',
                cookies: data?.attributes?.cookies || '',
                cookiesContent: data?.attributes?.cookiesContent || '',
                usageOfTheUsersData: data?.attributes?.usageOfTheUsersData || '',
                usageOfTheUsersDataContent: data?.attributes?.usageOfTheUsersDataContent || '',
                sharingOfTheUsersData: data?.attributes?.sharingOfTheUsersData || '',
                sharingOfTheUsersDataContent: data?.attributes?.sharingOfTheUsersDataContent || '',
                security: data?.attributes?.security || '',
                securityContent: data?.attributes?.securityContent || '',
                ThirdPartyApps: data?.attributes?.ThirdPartyApps || '',
                ThirdPartyAppsContent: data?.attributes?.ThirdPartyAppsContent || '',
                storageOfuserData: data?.attributes?.storageOfuserData || '',
                storageOfuserDataContent: data?.attributes?.storageOfuserDataContent || '',
                usersRight: data?.attributes?.usersRight || '',
                usersRightContent: data?.attributes?.usersRightContent || '',
                changesToThePrivacyPolicy: data?.attributes?.changesToThePrivacyPolicy || '',
                changesToThePrivacyPolicyContent: data?.attributes?.changesToThePrivacyPolicyContent || '',
                grievanceOfficer: data?.attributes?.grievanceOfficer || '',
                grievanceOfficerContent: data?.attributes?.grievanceOfficerContent || '',
              }
            }
          }
          )

        }
        else {
          dispatch({
            type: ActionTypes.PRIVACY_POLICY,
            payload: {
              PrivacyPolicy: ''
            }
          })
          console.log("something went wrong !")

        }

      },
      (error: any) => {
        console.log(error);
      }
    )
  }
}
const getTestridetncPolicy=(
   success: Function,fail: Function
)=>{
   return (dispatch: Function, getState: Function) => {
    const apiEndpoint = process.env.STRAPI_ENV ? endpoints.strapi_dev.testridetnc : endpoints.strapi.testridetnc;
    Api.getStrapiApiCall(apiEndpoint, '',
      (apiResponse: any) => {
        if (apiResponse) {
          const { data } = apiResponse;
          dispatch({
            type: ActionTypes.TEST_RIDE_TNC_POLICY,
            payload: {
              Testridetncpolicy: {
                title: data?.attributes?.title || '',
                Introduction: data?.attributes?.Introduction || '',
                usageterms_title: data?.attributes?.usageterms_title || '',
                usageterms: data?.attributes?.usageterms || '',
                RiderResponsibility: data?.attributes?.RiderResponsibility || '',
                RiderResponsibility_title: data?.attributes?.RiderResponsibility_title || '',
                damagesorloss_title: data?.attributes?.damagesorloss_title || '',
                damagesorloss: data?.attributes?.damagesorloss || '',
                dataProtection_title: data?.attributes?.dataProtection_title || '',
                dataProtection: data?.attributes?.dataProtection || '',
                disputeResolution_title: data?.attributes?.disputeResolution_title || '',
                disputeResolution: data?.attributes?.disputeResolution || '',
                htrfacility_title: data?.attributes?.htrfacility_title || '',
                htrfacility: data?.attributes?.htrfacility || '',
                indemnity_title: data?.attributes?.indemnity_title || '',
                indemnity: data?.attributes?.indemnity || '',
                riderQualification_title: data?.attributes?.riderQualification_title || '',
                riderQualification: data?.attributes?.riderQualification || '',
                
              }
            }
          }
          )

        } else {
          dispatch({
            type: ActionTypes.TEST_RIDE_TNC_POLICY,
            payload: {
              Testridetncpolicy: ''
            }
          })
          console.log("error here")

        }

      },
      (error: any) => {
        console.log(error);
      }
    )
  }
  
}


const getOnlinePreBooking = () => {
  return (dispatch: Function) => {
    const apiEndpoint = process.env.STRAPI_ENV
      ? endpoints.strapi_dev.onlinePreBook
      : endpoints.strapi.onlinePreBook

    //const apiEndpoint = endpoints.strapi.onlinePreBook; // Using the single endpoint for online prebooking

    Api.getStrapiApiCall(
      apiEndpoint,
      '',
      (apiResponse: any) => {
        if (apiResponse && apiResponse.data && apiResponse?.data?.attributes) {
          const data = apiResponse?.data?.attributes;
          const formattedTC = {
            title: data.title,
            introduction: data.introduction,
            listContent: [
              { title: data.definitionsTitle, description: data.definitions },
              { title: data.prebookingTitle, description: data.prebooking },
              { title: data.priceTitle, description: data.price },
              { title: data.modificationsTitle, description: data.modifications },
              { title: data.deliveryTitle, description: data.delivery },
              { title: data.cancellationTitle, description: data.cancellation },
              { title: data.insuranceTitle, description: data.insurance },
              { title: data.financeTitle, description: data.finance },
              { title: data.general_T_and_C_Title, description: data.general_T_and_C },
              { title: data.governingLawTitle, description: data.governingLaw },
              { title: data.arbitrationsTitle, description: data.arbitrations },
              { title: data.contactUsTitle, description: data.contactUs },
            ].filter((item) => item.title), // Filtering out any empty titles
          };

          dispatch({
            type: ActionTypes.TERM_CONDITION_ONLINE_PREBOOK,
            payload: { OnlinePreBookTC: formattedTC },
          });
        } else {
          dispatch({
            type: ActionTypes.TERM_CONDITION_ONLINE_PREBOOK,
            payload: { OnlinePreBookTC: { title: '', introduction: '', listContent: [] } },
          });
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.TERM_CONDITION_ONLINE_PREBOOK,
          payload: { OnlinePreBookTC: { title: '', introduction: '', listContent: [] } },
        });
      }
    );
  };
};

const getHeroSectionDetails = () => {

  return (dispatch: Function, getState: Function) => {
    Api.getStrapiApiCall(
      endpoints.strapi.herosection,
      '',
      (response: any) => {

        if (response) {
          console.log("response is" + response?.data?.attributes?.initialScroll_img1_22nd_centuryBike)


          const { data } = response
          dispatch({
            type: ActionTypes.PRODUCT_PAGE_DETAILS,
            payload: {
              HeroSection1: {
                // InitialScrollImg1txt1: data?.attributes?.initialScroll_img1_22nd_centuryBike,
                M1Black: data?.attributes?.Motion1_blacktext || '',
                M1Blue: data?.attributes?.Motion1_bluetext || '',
                M1Content: data?.attributes?.Motion2_content || '',
                M2Presenting: data?.attributes?.Motion2_Presenting || '',
                M2Matter: data?.attributes?.Motion2_Matter || '',
                M2AERA: data?.attributes?.Motion2_AERA || '',

                M3MatterAERA: data?.attributes?.Motion3_MatterAERA || '',
                M3Content: data?.attributes?.Motion3_content || '',
                M3CTA: data?.attributes?.Motion3_cta || '',

                M4Black: data?.attributes?.Motion4_black || '',
                M4Blue: data?.attributes?.Motion4_blue || '',
                M4Acceleration: data?.attributes?.Motion4_acceleration || '',
                M4AccelerateValue: data?.attributes?.acceleration_value || '',
                M4AccelarationSec: data?.attributes?.acceleration_sec || '',
                M4MotorPower: data?.attributes?.Motion4_MotorPower || '',
                M4MotorValue: data?.attributes?.MotorPower_value || '',
                M4MotorKW: data?.attributes?.MotorPower_KW || '',
                M4RideModes: data?.attributes?.Motion4_RideModes || '',
                RideModesValue: data?.attributes?.RideModes_value || '',
                RideModesText: data?.attributes?.RideModes_text || '',
                M4HyperShift: data?.attributes?.Motion4_HyperShift || '',
                HyperShiftText: data?.attributes?.HyperShift_text || '',
                HyperShiftValue: data?.attributes?.HyperShift_value || '',
                M4Range: data?.attributes?.Motion4_Range || '',
                RangeValue: data?.attributes?.Range_value || '',
                RangeKM: data?.attributes?.Range_KM || '',
                LaunchVideo: data?.attributes?.LaunchVideo_Link || '',



              },
            },
          })
        } else {
          dispatch({
            type: ActionTypes.PRODUCT_PAGE_DETAILS,
            payload: {
              HeroSection1: {
                InitialScrollImg1txt1: '',
                sec2: '',
                InitialScrollBoldSimple: '',

              },
            },
          })
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.PRODUCT_PAGE_DETAILS,
          payload: {
            HeroSection1: {
              InitialScrollImg1txt1: '',
              sec2: '',
              InitialScrollBoldSimple: '',
            },
          },
        })
      }
    )
  }
}

const getMotorSectionDetails = () => {

  return (dispatch: Function, getState: Function) => {
    Api.getStrapiApiCall(
      endpoints.strapi.motorsection,
      '',
      (response: any) => {

        if (response) {
          console.log("response is" + response?.data?.attributes?.initialScroll_img1_22nd_centuryBike)


          const { data } = response
          dispatch({
            type: ActionTypes.HOME_PAGE_DETAILS,
            payload: {
              MotorSection1: {
                M6Capability: data?.attributes?.Motion6_Capability || '',
                M6Powertrain: data?.attributes?.Motion6_Powertrain || '',
                M6Heading: data?.attributes?.Motion6_Heading || '',
                M6cta: data?.attributes?.Motion6_cta || '',
                M6GearBox: data?.attributes?.Motion6_Gearbox || '',
                GearboxContent: data?.attributes?.Gearbox_content || '',
                LearnMoreHeading1: data?.attributes?.LearnMore_heading1 || '',
                LearnMoreContent1: data?.attributes?.LearnMore_Content1 || '',
                LearnMoreHeading2: data?.attributes?.LearnMore_heading2 || '',
                LearnMoreContent2: data?.attributes?.LearnMore_Content2 || '',
                LearnMoreHeading3: data?.attributes?.LearnMore_heading3 || '',
                LearnMoreContent3: data?.attributes?.LearnMore_Content3 || '',
                Heading1Acceleration: data?.attributes?.Heading1_acceleration || '',
                Acceleration_value: data?.attributes?.Acceleration_value || '',
                Acceleration_sec: data?.attributes?.Acceleration_sec || '',
                RideModesText: data?.attributes?.RideModes_text || '',
                RideModesValue: data?.attributes?.RideModes_value || '',

              },
            },
          })
        } else {
          dispatch({
            type: ActionTypes.HOME_PAGE_DETAILS,
            payload: {
              MotorSection1: {
                M6Capability: '',
                M6Powertrain: '',
                M6Heading: '',
                M6cta: '',
                M6GearBox: '',
                GearboxContent: '',
              },
            },
          })
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.HOME_PAGE_DETAILS,
          payload: {
            MotorSection1: {
              M6Capability: '',
              M6Powertrain: '',
              M6Heading: '',
              M6cta: '',
              M6GearBox: '',
              GearboxContent: '',
            },
          },
        })
      }
    )
  }
}

const getPowerSectionDetails = () => {

  return (dispatch: Function, getState: Function) => {
    Api.getStrapiApiCall(
      endpoints.strapi.powersection,
      '',
      (response: any) => {

        if (response) {
          console.log("response is" + response?.data?.attributes?.initialScroll_img1_22nd_centuryBike)


          const { data } = response
          dispatch({
            type: ActionTypes.PRODUCT_PAGE_DETAILS,
            payload: {
              PowerSection1: {
                M7Capability: data?.attributes?.Motion7_Capability || '',
                M7Powerpack: data?.attributes?.Motion7_Powerpack || '',
                M7Heading: data?.attributes?.Motion7_Heading || '',
                M7cta: data?.attributes?.Motion7_cta || '',
                PowerpackTitle: data?.attributes?.Powerpack_title || '',
                PowerpackContent: data?.attributes?.Powerpack_content || '',
                LearnMoreHeading1: data?.attributes?.LearnMore_heading1 || '',
                LearnMoreContent1: data?.attributes?.LearnMore_Content1 || '',
                LearnMoreHeading2: data?.attributes?.LearnMore_heading2 || '',
                LearnMoreContent2: data?.attributes?.LearnMore_Content2 || '',
                LearnMoreHeading3: data?.attributes?.LearnMore_heading3 || '',
                LearnMoreContent3: data?.attributes?.LearnMore_Content3 || '',
                BatteryCapacity: data?.attributes?.BatteryCapacity || '',
                BatteryCapacityValue: data?.attributes?.BatteryCapacity_Value || '',
                BatteryCapacityKWH: data?.attributes?.BatteryCapacity_KWH || '',
                WaterDustProofing: data?.attributes?.WaterDustProofing || '',
                WaterDustValue: data?.attributes?.WaterDustProofing_value || '',
                WaterDustRating: data?.attributes?.WaterDustProofing_Rating || '',
                Range: data?.attributes?.Range || '',
                RangeValue: data?.attributes?.Range_value || '',
                RangeText: data?.attributes?.Range_text || '',



              },
            },
          })
        } else {
          dispatch({
            type: ActionTypes.PRODUCT_PAGE_DETAILS,
            payload: {
              PowerSection1: {


              },
            },
          })
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.PRODUCT_PAGE_DETAILS,
          payload: {
            PowerSection1: {

            },
          },
        })
      }
    )
  }
}

const getThermalSectionDetails = () => {

  return (dispatch: Function, getState: Function) => {
    Api.getStrapiApiCall(
      endpoints.strapi.thermalsection,
      '',
      (response: any) => {

        if (response) {
          console.log("response is" + response?.data?.attributes?.initialScroll_img1_22nd_centuryBike)


          const { data } = response
          dispatch({
            type: ActionTypes.PRODUCT_PAGE_DETAILS,
            payload: {
              ThermalSection1: {
                M8Capability: data?.attributes?.Motion8_Capability || '',
                M8THermal: data?.attributes?.Motion8_Thermal || '',
                M8Heading: data?.attributes?.Motion8_Heading || '',
                M8cta: data?.attributes?.Motion8_cta || '',
                LiquidCooling: data?.attributes?.Motion8_LiquidCooling || '',
                LiquidCooling_Content: data?.attributes?.LiquidCooling_content || '',
                LearnMoreHeading1: data?.attributes?.LearnMore_heading1 || '',
                LearnMoreContent1: data?.attributes?.LearnMore_Content1 || '',
                LearnMoreHeading2: data?.attributes?.LearnMore_heading2 || '',
                LearnMoreContent2: data?.attributes?.LearnMore_Content2 || '',


              },
            },
          })
        } else {
          dispatch({
            type: ActionTypes.PRODUCT_PAGE_DETAILS,
            payload: {
              ThermalSection1: {


              },
            },
          })
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.PRODUCT_PAGE_DETAILS,
          payload: {
            ThermalSection1: {

            },
          },
        })
      }
    )
  }
}

const getHotspotSectionDetails = () => {

  return (dispatch: Function, getState: Function) => {
    Api.getStrapiApiCall(
      endpoints.strapi.hotspotsection,
      '',
      (response: any) => {

        if (response) {
          console.log("response is" + response?.data?.attributes?.initialScroll_img1_22nd_centuryBike)


          const { data } = response
          dispatch({
            type: ActionTypes.PRODUCT_PAGE_DETAILS,
            payload: {
              HotspotSection1: {
                M9Capability: data?.attributes?.Motion9_Capability || '',
                M9Content: data?.attributes?.Motion9_Content || '',

              },
            },
          })
        } else {
          dispatch({
            type: ActionTypes.PRODUCT_PAGE_DETAILS,
            payload: {
              HotspotSection1: {


              },
            },
          })
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.PRODUCT_PAGE_DETAILS,
          payload: {
            HotspotSection1: {

            },
          },
        })
      }
    )
  }
}

const getColorSectionDetails = () => {

  return (dispatch: Function, getState: Function) => {
    Api.getStrapiApiCall(
      endpoints.strapi.colorsection,
      '',
      (response: any) => {

        if (response) {


          const { data } = response
          dispatch({
            type: ActionTypes.PRODUCT_PAGE_DETAILS,
            payload: {
              ColorSection1: {
                M10ColourPalette: data?.attributes?.Motion10_ColourPalette || '',
                ColourPaletteContent: data?.attributes?.ColourPalette_Content || '',
              },
            },
          })
        } else {
          dispatch({
            type: ActionTypes.PRODUCT_PAGE_DETAILS,
            payload: {
              ColorSection1: {


              },
            },
          })
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.PRODUCT_PAGE_DETAILS,
          payload: {
            ColorSection1: {

            },
          },
        })
      }
    )
  }
}

const getVuiSectionDetails = () => {

  return (dispatch: Function, getState: Function) => {
    Api.getStrapiApiCall(
      endpoints.strapi.vuisection,
      '',
      (response: any) => {

        if (response) {


          const { data } = response
          dispatch({
            type: ActionTypes.PRODUCT_PAGE_DETAILS,
            payload: {
              VuiSection1: {
                M11Connectivity: data?.attributes?.Motion11_Connectivity || '',
                M11Content: data?.attributes?.Motion11_Content || '',
                Corousel1Heading: data?.attributes?.Corousel1_Heading || '',
                Corousel1Content: data?.attributes?.Corousel1_content || '',
                Corousel2Heading: data?.attributes?.Corousel2_Heading || '',
                Corousel2Content: data?.attributes?.Corousel2_content || '',
                Corousel3Heading: data?.attributes?.Corousel3_Heading || '',
                Corousel3Content: data?.attributes?.Corousel3_content || '',
                Corousel4Heading: data?.attributes?.Corousel4_Heading || '',
                Corouse4Content: data?.attributes?.Corousel4_content || '',
              },
            },
          })
        } else {
          dispatch({
            type: ActionTypes.PRODUCT_PAGE_DETAILS,
            payload: {
              VuiSection1: {


              },
            },
          })
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.PRODUCT_PAGE_DETAILS,
          payload: {
            VuiSection1: {

            },
          },
        })
      }
    )
  }
}
const getAppSectionDetails = () => {

  return (dispatch: Function, getState: Function) => {
    Api.getStrapiApiCall(
      endpoints.strapi.appsection,
      '',
      (response: any) => {

        if (response) {


          const { data } = response
          dispatch({
            type: ActionTypes.PRODUCT_PAGE_DETAILS,
            payload: {
              AppSection1: {
                M12Connectivity: data?.attributes?.Motion12_Connectivity || '',
                M12Content: data?.attributes?.Motion12_Content || '',
                Corousel1Heading: data?.attributes?.Corousel1_Heading || '',
                Corousel1Content: data?.attributes?.Corousel1_content || '',
                Corousel2Heading: data?.attributes?.Corousel2_Heading || '',
                Corousel2Content: data?.attributes?.Corousel2_content || '',
                Corousel3Heading: data?.attributes?.Corousel3_Heading || '',
                Corousel3Content: data?.attributes?.Corousel3_content || '',
                Corousel4Heading: data?.attributes?.Corousel4_Heading || '',
                Corousel4Content: data?.attributes?.Corousel4_content || '',


              },
            },
          })
        } else {
          dispatch({
            type: ActionTypes.PRODUCT_PAGE_DETAILS,
            payload: {
              AppSection1: {


              },
            },
          })
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.PRODUCT_PAGE_DETAILS,
          payload: {
            AppSection1: {

            },
          },
        })
      }
    )
  }
}

const getFeatureSectionDetails = () => {

  return (dispatch: Function, getState: Function) => {
    Api.getStrapiApiCall(
      endpoints.strapi.featuresection,
      '',
      (response: any) => {

        if (response) {


          const { data } = response
          dispatch({
            type: ActionTypes.PRODUCT_PAGE_DETAILS,
            payload: {
              FeatureSection1: {
                Features: data?.attributes?.Features || '',
                FeaturesHeading: data?.attributes?.Features_Heading || '',
                Alloys: data.attributes.Alloys.data.attributes.url || '',
                Charger: data.attributes.Charger.data.attributes.url || '',
                Dpad: data.attributes.Dpad.data.attributes.url || '',
                Graphics: data.attributes.Graphics.data.attributes.url || '',
                Passive: data.attributes.passive.data.attributes.url || '',
                KeyImg: data.attributes.KeyImg.data.attributes.url || '',
                Triangle: data.attributes.triangle.data.attributes.url || '',
                Storage: data.attributes.storage.data.attributes.url || '',
                SmartParking: data.attributes.SmartParking.data.attributes.url || '',
                grrbrail: data.attributes.grrbrail.data.attributes.url || '',
                star: data.attributes.star.data.attributes.url || '',
                ChargerBlue: data?.attributes?.Charger_BlueText || '',
                ChargerWhite: data?.attributes?.Charger_WhiteText || '',
                DpadBlue: data?.attributes?.Dpad_BlueText || '',
                DpadWhite: data?.attributes?.Dpad_WhiteText || '',
                StarBlue: data?.attributes?.Star_BlueText || '',
                StarWhite: data?.attributes?.Star_WhiteText || '',
                SmartParkingBlue: data?.attributes?.SmartParking_BlueText || '',
                SmartPrakingWhite: data?.attributes?.SmartParking_WhiteText || '',
                KeyImgBlue: data?.attributes?.KeyImg_BlueText || '',
                KeyImgWhite: data?.attributes?.KeyImg_WhiteText || '',
                AlloysBlue: data?.attributes?.Alloys_BlueText || '',
                AlloysWhite: data?.attributes?.Alloys_WhiteText || '',
                PassiveBlue: data?.attributes?.Passive_BlueText || '',
                PassiveWhite: data?.attributes?.Passive_WhiteText || '',
                TriangleBlue: data?.attributes?.Triangle_BlueText || '',
                TriangleWhite: data?.attributes?.Triangle_WhiteText || '',
                GrrbrailBlue: data?.attributes?.grrbrail_BlueText || '',
                GrrbrailWhite: data?.attributes?.grrbrail_WhiteText || '',
                StorageBlue: data?.attributes?.storage_BlueText || '',
                StorageWhite: data?.attributes?.Storage_WhiteText || '',
                GraphicsBlue: data?.attributes?.Graphics_BlueText || '',
                GraphicsWhite: data?.attributes?.Graphics_WhiteText || '',
                ChargerContent: data?.attributes?.Charger_content || '',
                GraphicsContent: data?.attributes?.Graphics_content || '',
                GrrbrailContent: data?.attributes?.grrbrail_content || '',
                KeyImgContent: data?.attributes?.KeyImg_content || '',
                PassiveContent: data?.attributes?.passive_content || '',
                SmartParkingContent: data?.attributes?.SmartParking_content || '',
                DPadContent: data?.attributes?.dpad_content || '',
                StorageContent: data?.attributes?.storage_content || '',
                TriangleContent: data?.attributes?.triangle_content || '',

                ChargerMobile: data.attributes.Charger_Mobile.data.attributes.url || '',
                DpadMobile: data.attributes.Dpad_Mobile.data.attributes.url || '',
                AlloysMobile: data.attributes.Alloys_Mobile.data.attributes.url || '',
                GrabrailMobile: data.attributes.Grabrail_Mobile.data.attributes.url || '',
                GraphicsMobile: data.attributes.Graphics_Mobile.data.attributes.url || '',
                KeyImgMobile: data.attributes.KeyImg_Mobile.data.attributes.url || '',
                PassiveMobile: data.attributes.Passive_Mobile.data.attributes.url || '',
                SmartParkingMobile: data.attributes.SmartParking_Mobile.data.attributes.url || '',
                StarMobile: data.attributes.Star_Mobile.data.attributes.url || '',
                StorageMobile: data.attributes.Storage_Mobile.data.attributes.url || '',
                TriangleMobile: data.attributes.Traingle_Mobile.data.attributes.url || '',

              },
            },
          })
        } else {
          dispatch({
            type: ActionTypes.PRODUCT_PAGE_DETAILS,
            payload: {
              FeatureSection1: {


              },
            },
          })
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.PRODUCT_PAGE_DETAILS,
          payload: {
            FeatureSection1: {

            },
          },
        })
      }
    )
  }
}
const getMatterCareSectionDetails = () => {

  return (dispatch: Function, getState: Function) => {
    Api.getStrapiApiCall(
      endpoints.strapi.mattercaresection,
      '',
      (response: any) => {

        if (response) {


          const { data } = response
          dispatch({
            type: ActionTypes.PRODUCT_PAGE_DETAILS,
            payload: {
              MatterCareSection1: {
                MatterCare: data?.attributes?.MatterCare || '',
                MatterCareHeadingBlack: data?.attributes?.MatterCare_HeadingBlack || '',
                MatterCareHeadingBlue: data?.attributes?.MatterCare_HeadingBlue || '',
                Security: data?.attributes?.Security || '',
                Safety: data?.attributes?.Safety || '',
                Reliability: data?.attributes?.Reliability || '',
                Performance: data?.attributes?.Performance || '',
                FourPillarsContent: data?.attributes?.FourPillars_Content || '',
                RaceHeading: data?.attributes?.RaceInto22ndCentury_Heading || '',
                RaceContent: data?.attributes?.RaceInto22ndCentury_Content || '',
              },
            },
          })
        } else {
          dispatch({
            type: ActionTypes.PRODUCT_PAGE_DETAILS,
            payload: {
              MatterCareSection1: {


              },
            },
          })
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.PRODUCT_PAGE_DETAILS,
          payload: {
            MatterCareSection1: {

            },
          },
        })
      }
    )
  }
}
const getPriceSectionDetails = () => {

  return (dispatch: Function, getState: Function) => {
    Api.getStrapiApiCall(
      endpoints.strapi.pricesection,
      '',
      (response: any) => {

        if (response) {


          const { data } = response
          dispatch({
            type: ActionTypes.PRODUCT_PAGE_DETAILS,
            payload: {
              PriceSection1: {
                Heading: data?.attributes?.Heading || '',
                AERA500Price: data?.attributes?.AERA5000_Price || '',
                AERA5000PlusPrice: data?.attributes?.AERA5000Plus_Price || '',
                AERA5000EMI: data?.attributes?.AERA5000_EMI || '',
                AERA5000PlusEMI: data?.attributes?.AERA5000Plus_EMI || '',
              },
            },
          })
        } else {
          dispatch({
            type: ActionTypes.PRODUCT_PAGE_DETAILS,
            payload: {
              PriceSection1: {


              },
            },
          })
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.PRODUCT_PAGE_DETAILS,
          payload: {
            PriceSection1: {

            },
          },
        })
      }
    )
  }
}


export default {
  getTestridetncPolicy,
  getPrivacyPolicy,
  getLegalPolicy,
  resetFAQ,
  getAppConfig,
  getCategoryFAQs,
  updateFAQDetails,
  getLocationList,
  getCancelReasons,
  getMasterChildFAQ,
  getBannerStrapiApi,
  getFAQAppliedSearch,
  getContactUsStrapiApi,
  getproductSpecStrapiApi,
  getModifyLocationList,
  getFaqCategoriesStrapiApi,
  getInnovationHubDetails,
  getWhoWeAreAchivements,
  getWhoWeAreEvents,
  getWhoWeAreDetails,
  getWhoWeAreLeaders,
  getBlogs,
  getFullBlogs,
  getJoinWaitlist,
  getHomePageDetails,
  getOnlinePreBooking,
  getHyperLocalFaqCategoriesStrapiApi,
  getNews,
  getAwards,
  getPressReleases,
  getLeadership,
  getLogoAndTrademark,
  
  getAera5000,
  getAera5000Plus,
  getProductPortfolio,
  getHeroSectionDetails,
  getMotorSectionDetails,
  getPowerSectionDetails,
  getThermalSectionDetails,
  getHotspotSectionDetails,
  getColorSectionDetails,
  getVuiSectionDetails,
  getAppSectionDetails,
  getFeatureSectionDetails,
  getMatterCareSectionDetails,
  getPriceSectionDetails

}

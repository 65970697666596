import { combineReducers } from 'redux'
import onboarding from './onboarding'
import createPreBooking from './preBookingReducer'
import userDetails from './userDetails'
import home from './homeReducer'
import registerYourInterest from './registerYourInterest'
import partnerAndChannel from "./PartnerAndChannel"
import locatorAndScheduling from "./locatorAndScheduling"
import ecBooking from './ecBooking'


const rootReducer = combineReducers({
  onboarding,
  userDetails,
  createPreBooking,
  home,
  registerYourInterest,
  partnerAndChannel ,
  locatorAndScheduling,
  ecBooking
  
})

const appReducer = (state: any, action: any) => rootReducer(state, action)

export type RootState = ReturnType<typeof rootReducer>

export default appReducer

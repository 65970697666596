export default {
  PARTNER_TYPE: "PARTNER_TYPE",
  CHANNEL_PARTNER_STEPPER:"CHANNEL_PARTNER_STEPPER",
  Channel_PARTNER_VERRIED: "Channel_PARTNER_VERRIED",
  PARTNER_WITH_US: 'PARTNER_WITH_US',
  CHANNEL_PARTNER_OTP: 'CHANNEL_PARTNER_OTP',
  CHANNEL_PARTNER_FORMDATA: "CHANNEL_PARTNER_FORMDATA",
  CHANNEL_PARTNER_QUESTIONS: 'CHANNEL_PARTNER_QUESTIONS',
  UPDATE_QUESTIONS: "UPDATE_QUESTIONS",
  CHANNEL_PARTNER_LOCATION_LIST: "CHANNEL_PARTNER_LOCATION_LIST",
  RESET_CHANNEL_PARTNER: "RESET_CHANNEL_PARTNER"
}

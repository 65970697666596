export default {
  POST_OTP: 'POST_OTP',
  TAB_BACK: 'TAB_BACK',
  TAB_CHANGE: 'TAB_CHANGE',
  LOADER_STATUS: 'LOADER_STATUS',
  PROMO_CODE: 'PROMO_CODE',
  SIGNUP_DETAILS: 'SIGNUP_DETAILS',
  OTP_VERIFICATION: 'OTP_VERIFICATION',
  OTP_REQUEST_DETAILS: 'OTP_REQUEST_DETAILS',
  NUMBER_OTP_VERIFIED: "NUMBER_OTP_VERIFIED",
  OTP_NUMBER_ATTEMPS: "NUMBER_OTP_NUMBER_ATTEMPS",
  CHECK_FIVE_MINUTES:"CHECK_FIVE_MINUTES",
  RESET_ONBOARDING: "RESET_ONBOARDING"
}

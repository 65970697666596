import { AnyAction } from 'redux'
import { ActionTypes } from '../constants/index'
import { ECBooking } from '../constants/model'

let initialState: ECBooking = new ECBooking()

export default function ecBooking(
  state: any = initialState,
  action: AnyAction
) {
  const { type, payload } = action

  switch (type) {
    case ActionTypes.EC_BOOKING:
      return { ...state, ...payload }   
    case ActionTypes.EC_BOOKINGS_LIST:
      return { ...state, ...payload }    
    case ActionTypes.EC_BOOKING_DETAILS:
      return { ...state, ...payload }    
    case ActionTypes.EC_BOOKING_STRAPI:
      return { ...state, ...payload }    
    default:
      return state
  }
}

import ReactDOM from 'react-dom'
import './style.scss'
import './../public/assets/fonts/fonts.scss'
import {App} from './App'
import { store } from './store'

ReactDOM.render(<App appStore={store} />, document.getElementById('root'));
const rootElement : any = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<App appStore={store} />, rootElement);
} else {
  ReactDOM.render(<App appStore={store} />, rootElement);
}


// ReactDOM.render(<App appStore={store} />, document.getElementById('root'))

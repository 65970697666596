import { AnyAction } from 'redux'
import { ActionTypes } from '../constants/index'
import { CurrentUserDetails } from '../constants/model'
import { decryptData, encryptData } from '../utils/common'

let initialState: CurrentUserDetails = new CurrentUserDetails()
export default function userDetails(
  state: any = initialState,
  action: AnyAction
) {
  const { type, payload } = action


  
  switch (type) {
    case ActionTypes.UPDATE_USER_DETAILS:
      return { ...state, ...{currentUserDetails : encryptData(payload)}}
    case ActionTypes.GET_PREBOOKING_LIST:
       return { ...state, ...{orderList : encryptData(payload)} }
    case ActionTypes.MODIFY_PREBOOKING:
      return { ...state, ...payload }
    case ActionTypes.CANCEL_PREBOOKING:
      return { ...state, ...payload }
    case ActionTypes.LOADER_STATUS:
      return { ...state, ...payload }
    case ActionTypes.RESET_USER_DETAILS:
      return new CurrentUserDetails()
    default:
      return state
  }
}

import Header from '../../../components/Header'
import "../legal.scss";
import Footer from "../../../components/Footer";
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react';
import HeadlessComponent from '../../../components/HeadlessComponent';

const CompanionMobilePrivacyPolicy = () => {

  useEffect(() => {
    document.title = 'Privacy Policy | Matter'
  }, [])

  return (
    <div className='legal-cover'>
      {/* <Header /> */}
      <div className="legal-content">
        <h1 className="visuallyHidden">PRIVACY POLICY</h1>
        <HeadlessComponent
        title="Privacy Policy | Matter"
         conicalLink="https://matter.in/privacy-policy" 
        description="The Matter.in privacy policy has been created to serve you better by explaining what information we collect and how it is used online. Read More about our privacy policy"
        />

        <h2 className="heading monument mb-40 mb-xs-30 mt-20">PRIVACY POLICY</h2>
        <p>
          The policy below is our “Privacy Policy” which describes how the Company (defined hereinafter) deals
          with the user’s information when a user visits our website
          {' '} <a className='primary white' style={{ pointerEvents: 'none', fontStyle: 'italic', textDecorationLine: 'none' }} href='https://matter.in'> {` `} https://matter.in</a> {' '} 
          (“the Website”, which
          expression shall mean and include any successor or replacement website), or its mobile application or
          similar applications provided on other electronic devices (“App”). By accessing, using, visiting and/or
          registering with the Website and/or App (“Platform”), the services provided on, from, or through the
          Platform (“Services”), the users (hereinafter referred to as “User/s” are accepting and consenting to the
          practices described in this Privacy Policy. This Privacy Policy forms a part of the Terms and Conditions of
          Use given at
           {' '} <a className='primary white' style={{ pointerEvents: 'none', fontStyle: 'italic', textDecorationLine: 'none' }}   href="/terms-and-conditions">https://matter.in/terms-and-conditions</a> {' '} 
          under which the User has contracted with
          ‘Matter Motor Works Private Limited’ being a company incorporated under the Companies Act, 2013 and
          having its registered office at 301, Parishram Building, 5BRashmi Society, Nr. Mithakhali Six Roads,
          Navrangpura, Ahmedabad –380009, India (hereinafter “the Company”/ “we”/”us”, which expression,
          unless the context otherwise requires, shall mean and include their respective successors and assigns).
        </p>
        <p>
          We understand that the information the User provides us is important for him/her and hence we shall use
          them only in accordance with this Privacy Policy. By using the Platform, the User consents to the
          collection, storage, processing, transfer and sharing of the Personal Information (defined hereinafter) of
          the User by the Company for the purpose of rendering the Services to the User or for the purposes set
          out in this Privacy Policy in the manner described below in this Privacy Policy. We will take such steps as
          we consider reasonably necessary to ensure that the User’s information is treated securely and in
          accordance with the Privacy Policy. If any User objects to any content of this Privacy Policy including the
          nature of information collected/used/disclosed by us as per the terms of this Privacy Policy, then we
          request the User to not click on the “Register”/”Login” button on the Website, to not register their Account
          (defined hereinafter in Section A below) on the Platform, to not provide their personal information on the
          Platform and to not use the Services. Further, by providing their phone number and email address to us,
          the User agrees to receive communications, updates, surveys, questionnaire, feedback forms and the like
          regarding the Company on the provided phone number and email account. The User shall be entitled to
          request us to stop sending any promotional emails by clicking on the ‘unsubscribe’ tab/button provided in
          the email.
        </p>
        <p>
          The User is requested not to share their Personal Information or register on our Platform if the User is a
          minor i.e. below 18 years of age (or such other age as applicable to User’s jurisdiction). If the Users
          wishes to avail any of the Services provided by us, the Users may request their parent or guardian to do
          so on their behalf.
        </p>
        <p>
          We regularly keep monitoring our Website to ensure that the Website is kept safe and secure.
        </p>
        <br />
        <div className="sec-wrapper">
          <h5 className="monument mb-20">A. COLLECTION OF THE USER’S DATA:</h5>
          <p>
            We collect, process and store the data provided by the Users on our Platform, including when the
            Users register their interest at “I’m interested!” tab or when the Users choose to use our Services and
            features by registering their account on the Platform (“Account”). The information collected on our
            Platform includes User’s name, phone number, email address, and any other personally identifiable
            information as supplied by the User on our Platform (“Personal Information”). We also collect
            information provided by the User voluntarily, while completing a customer survey or from the
            feedback provided by the User on any of our message boards or via email. The Company, situated in
            Ahmedabad, India, is the controller of the Users’ Personal Information.
          </p>
          <p>
            In the event the User does not provide their Personal Information where required, we may not be able
            to provide all of the Services available on the Platform. For the purpose of creation of User Account
            on the Platform, the User will be required to provide their phone number, without the provision of
            which, User’s Account cannot be created on the Platform. In the event the User makes payment on
            the Platform via the payment gateway to complete any financial transaction on the Platform, we may
            collect and store his/her credit card/debit card, bank account number and other financial information
            in accordance with applicable law. The User acknowledge that the payment gateway may be
            provided by a third-party service provider in which case, applicable terms of use and privacy policy of
            such third-party service provider shall apply.
          </p>
          <p>
            In order for us to be able to provide the Services to the Users, we may seek permission of the Users
            whether we may obtain access to contact list/phone book on their device, device location, camera,
            microphone and other sensitive device data. When this permission is sought, the Users shall have the
            control wherein they can select the ‘allow’ us to access such data or ‘deny’ us the permission to
            access the said data.
          </p>
          <br />
          <h6 className="monument mb-20">Cookies</h6>
          <p>
            Like many other websites, we use cookies, which are small files that are placed on User’s device by
            our Platform, through which we automatically collect and keep the information that the User’s browser
            sends whenever the User visits our Platform or when the User accesses our Platform by or through
            any device. This includes information such as User’s computer Internet Protocol address (IP
            address), browser type, browser version, the pages of our Platform that the User visits, the time spent
            on those pages, unique device identifiers and other diagnostic data. When the Users accesses the
            Platform with a device, we may collect and keep information such as the type of device Users use,
            their device unique ID, the IP address of their device, the operating system, the type of internet
            browser Users use, unique device identifiers and other diagnostic data. This is done mainly to
            customize Users current and future visits to and use of our Platform, and to deliver content relevant to
            their interests.
          </p>
          <p>
            This Platform uses different analytics and tracking technologies on the Platform including Google
            Analytics, a web analytics service provided by Google Inc. (“Google”). Google uses cookies, text files
            that are stored on Users’ computers and enables analysis of their use of the Platform. The types of
            information a cookie used by Google collects include unique device identifiers, unique User IDs
            (consisting of a string of numbers unique to each User on the website), the Internet Protocol Address
            (IP Address) of the Users’ device, the number of times Users have visited or accessed the Platform
            previously and the date and time of their visit to the Platform, information about how the Users found
            the Platform, their search and browsing history.
          </p>
          <p>
            Cookies can be Persistent or Session cookies. Persistent cookies remain on Users’ device when they
            go offline, while session cookies are deleted as soon as the Users close their web browser.
          </p>
          <p>
            We use the following categories of cookies for different purposes:
          </p>
          <ul className='lower-alpha'>
            <li>
              Necessary/Essential Cookies: These cookies are essential to provide the User with Services
              available through the Platform and to enable the User to use some of its features. They help to
              authenticate the Users and prevent fraudulent use of Users’ Accounts.
            </li>
            <li>
              Functionality Cookies: These cookies allow the Platform to remember any choices the User
              makes about the Platform.
            </li>
            <li>
              Performance/ Statistical cookies: These cookies collect information about how the User uses our
              Platform. This data may be used to optimize our Platform and make it easier for the User to use
              it.
            </li>
            <li>
              Targeted Cookies: These cookies may be placed through our advertising partners and are used
              to track User’s online activity to help advertisers deliver more relevant advertising or to limit how
              many times the User sees an ad.
            </li>
          </ul>
          <br />
          <p>
            By clicking on “Accept”, the Users consent to the use of cookies by us to collect their data. By clicking
            on “Reject”, Users can reject the use of all non-essential cookies and no data shall be collected
            through non-essential cookies. The Users may also restrict the use of cookies by changing their
            browser settings. However, restricting the use of cookies may impact their experience of the Platform
            and the Services we offer.
            <br /><br />
            In some instances, our third-party service providers may use cookies on the Platform. We cannot
            control or access cookies used by third-party service providers. This Privacy Policy covers only
            cookies used by us, and not any cookies used by third parties.
            <br /><br />
            The Platform is an online interface conceptualized with the aim to communicate to our users, the
            Company’s endeavors to develop smart transformative and integrated technologies in order to
            empower our users with the option to choose a sustainable and a cleaner alternative and to provide

            Services to the users. From this perspective and in order to provide Services and to continually keep
            our Users updated about our growth, we collect, store and process the information provided by the
            Users or authorize third party service providers to store or process the same provided such third
            parties are also bound by obligations similar to those contained in this Privacy Policy and their
            storage/processing of the information of the User is limited to the purposes mentioned in this Privacy
            Policy and any such information shared is only on a need-to-know basis.
            <br /><br />
            We have assumed that the Users have allowed us to access their internet network. If this permission
            is not given, then the Users will not be able to visit our Platform.
            <br /><br />
            The Users can access our Website anonymously (as a guest user), but in that case, they shall not be
            qualified to receive any communications from us or they may not be able to access the privileges, if
            any, that we may provide to our members.
          </p>
        </div>

        <div className="sec-wrapper">
          <h5 className="monument mb-20 ">2. USAGE OF THE USER’S DATA</h5>
          <p>
            The purposes for which we collect, process and store User’s Personal Information depends upon the
            reasons for which we collect the said Personal Information. The Personal Information provided by the
            User and collected by us is kept strictly confidential and is used by us for automated profiling and for
            the below mentioned purposes:
          </p>
          <ul>
            <li>
              To create User Account on the basis of the information provided by the User, to manage their
              Account, to customize their experience;
            </li>
            <li>
              To notify the User regarding User Account, to confirm their identity in order to ensure that
              they are eligible to access the Platform;
            </li>
            <li>
              To share reports, news articles and other articles in relation to our R&amp;D for development of
              platforms for electric vehicles and storage technology solutions and other related activities;
            </li>
            <li>
              To share promotional information regarding our products;
            </li>
            <li>
              To enable the User to avail the Services offered by us on the Platform;
            </li>
            <li>
              To share with the User offers initiated by the Company and to invite them, to events hosted
              by the Company;
            </li>
            <li>
              If the User is a user who has availed our booking services, to share with the User the
              details of the authorized entities with whom the booking has been made and other
              relevant details with respect to the booking;
            </li>
            <li>
              To send reminders with respect to the vehicle services the Company offers;
            </li>
            <li>
              To comply with our legal obligations such as to conduct a product safety recall whenever
              necessary;
            </li>
            <li>
              To troubleshoot and provide the User an efficient service like providing the User customer
              service, resolving their technical issues, managing traffic on the Platform and the keyword
              searches;
            </li>
            <li>
              To display interest-based ads for products, services and features that might be of interest to
              the User. We do not use information that identifies the User personally in order to display
              such interest-based advertisements;
            </li>
            <li>
              To evaluate information on User’s recent visits to our Platform and how the User moves
              around different sections of our Platform for analytic purposes to understand how people
              generally use our Platform so that we can make it more relevant to the users.
            </li>
            <li>
              To send the User e-mails or other communications seeking for feedback or surveys; and
            </li>
            <li>
              If required to comply with legal requests, because the Company believes in good faith, that it
              is necessary to:
              <ul className='disc mt-20'>
                <li>
                  Fulfill legal obligations or to enforce these terms and conditions;
                </li>
                <li>
                  Defend accusations relating to the infringement of copyright/trademark laws;
                </li>
                <li>
                  Protect the Company’s systems and its user’s rights, property and security.
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div className="sec-wrapper">
          <h5 className="monument mb-20">C. SHARING OF THE USER’S DATA</h5>
          <p>
            The Users’ Personal Information is kept confidential and secured with us and not disclosed to any
            third party. However, we may:
          </p>

          <ul>
            <li>
              share the Users’ Personal Information with the entities authorized by the Company who shall
              complete the intended sale of the vehicle (for eg the Company’s authorized dealers) and
              payment gateways if the User is someone who has availed our pre-booking service on the
              Platform;
            </li>
            <li>
              share the Users’ Personal Information with the related companies and affiliates of the
              Company;
            </li>
            <li>
              share the Users’ Personal Information with third party service providers on a need-to-know
              basis to process the Users’ Personal Information in order to fulfill the purposes set out in the
              Privacy Policy. The Company shall execute agreements with such third party service
              providers to cast an obligation on them to maintain the confidentiality of the Users’ Personal
              Information on terms that are at least at par with those set out in this Privacy Policy and to
              use the information only for the specific purpose for which the agreements have been
              executed. The third party service providers are contractually bound to carry out tasks based
              upon our instructions.
            </li>
            <li>
              share the Users’ Personal Information when required in order to comply with any legal
              requests.
            </li>
            <li>
              store information in locations outside the direct control of the Company (for instance, on
              servers or databases co-located with hosting providers or other service providers) and their
              use of the Platform shall constitute consent for the same.
            </li>
            <li>
              share the Users’ mobile number with other service providers who provide communication
              services on behalf of the Company like SMS, e-mails etc.
            </li>
            <li>
              transfer the Users’ Personal Information if we are acquired by another entity, or if we merge
              with another company or transfer a part of our business, including the Platform, to a third
              party. In the event of such sale or transfer, we may notify the User.
            </li>
            <li>
              transfer the Personal Information collected from the User to a country outside the European
              Economic Area, if the User is an user residing in the European Economic Area. The
              Company shall take appropriate safeguards to protect their Personal Information.
            </li>

          </ul>
        </div>

        <div className="sec-wrapper">
          <h5 className="monument mb-20">D. SECURITY</h5>
          <p>
            The Company takes reasonable commercial efforts to ensure the security of the systems with regard
            to the transmission of data during the usage of the Platform. We regularly carry out assessments to
            ensure the security of the data collected by us. However, please note that there is no guarantee that
            such information may not be accessed, disclosed, altered, or destroyed by breach of any physical,
            technical, or managerial safeguards. It must be fully understood that neither the Company, nor any
            other website can completely eliminate such risks. If the Company becomes aware of a security
            system breach, then we shall immediately notify the User within the time-lines required by law by
            email or as a notification on the Platform so that the User can take appropriate protective steps.
          </p>
          <p>
            The User will be responsible for maintaining the confidentiality of the username, password and other
            details related to his/her Account on the Platform and for all the activities that occur on the Account.
          </p>
        </div>

        <div className="sec-wrapper">
          <h5 className="monument mb-20">E. THIRD PARTY APPLICATIONS AND WEBSITES</h5>
          <p>
            In addition, the Platform may occasionally contain links to third-party websites. If the User clicks on
            the links to third-party websites, the User leaves our Platform. The Company is not responsible for the
            content of these third-party websites or for the security of User’s personal information when the User
            uses the third-party websites. These third-party websites may have their own privacy policies
            governing the storage and retention of their personal information that the User may be subject to.
            They may collect information such as their IP address, browser specification, or operating system.
            This Privacy Policy does not govern personal information provided to, stored on, or used by these

            third-party providers and third-party websites. We recommend that when the User enters a third-party
            website, they review the third-party website’s privacy policy as it relates to safeguarding their
            personal information.
          </p>

        </div>

        <div className="sec-wrapper">
          <h5 className="monument mb-20">F. STORAGE OF THE USER’S DATA</h5>
          <p>
            We will store the Users’ Personal Information as long as it is required to be stored for the purposes
            set out in this Privacy Policy and to comply with our legal obligations. When we no longer need to
            use the User’s Personal Information and there is no need for us to keep it to comply with our legal or
            regulatory or contractual obligations under the agreements executed with third party service
            providers like payment gateways, we will either delete it from our systems or depersonalize it so that
            we cannot identify the User.
          </p>
        </div>

        <div className="sec-wrapper">
          <h5 className="monument mb-20">G. USER RIGHTS</h5>
          <ul>
            <li>
              Right to access: the User has the right: (a) to request copies of information we hold
              about the User at any time or; (b) request us to modify or update such information and (c)
              to request any other information with respect to his/her Personal Information collected
              and stored by us. We may require the User to provide suitable identification for security
              purposes before providing or allowing the User to access his/her Personal Information.
            </li>
            <li>
              Right to rectify/update the information provided by User: the User has the right to
              request to have his/her Personal Information rectified if it is inaccurate or incomplete. We
              rely on the Personal Information provided by the User in order to provide the User with
              the Services available on the Platform, and therefore it is important that the Personal
              Information provided by the User is accurate, complete and up to date. If the Personal
              Information provided by the User has undergone any changes, or if the User becomes
              aware that any information which we hold about the User is incorrect, incomplete or not
              up to date, kindly rectify or update the information by exercising this right.
            </li>
            <li>
              Right to erasure: the User has the right to request that we delete his/her data from our
              storage systems. However, please note that there might be latency in deleting the User’s
              Personal Information. Additionally, if the User has undertaken any financial transaction
              through our Platform, such details will be stored with us till such time as required under
              law or as per our contractual obligation under the agreements executed with third party

              service providers like payment gateways, although all other information may be deleted
              us.
            </li>
            <li>
              Right to restrict processing: If the User is a user from the EU Region, then the User
              has the right to restrict us from processing his/her Personal Information.
            </li>
            <li>
              Right to data portability: If the User is a user from the EU Region, then the User has
              the right to request us to move, copy or transfer his/her Personal Information.khskje
            </li>
            <li>
              Right to object: If the User is a user from the EU Region, then the User has the right to
              object to our use of his/her Personal Information for all or certain specific purposes.
            </li>
            <li>
              Right to opt out: We would be sending the User information about our products,
              newsletters, reports and other promotional communications. User has the right to opt out
              of receiving communications from us. The User can also unsubscribe to our promotional
              emails by following the unsubscribe instructions provided in those emails.
            </li>

          </ul>

          <p>
            If the User wishes to exercise any of the abovementioned rights, the User shall submit his/her
            request by writing to us at 
            {' '} <a href='mailto:customer.care@matter.in'>customer.care@matter.in</a> {' '} mentioning the nature of the right the User
            wishes to exercise in the subject line of the e-mail.
          </p>

        </div>

        <div className="sec-wrapper">
          <h5 className="monument mb-20">H. CHANGES TO THIS PRIVACY POLICY</h5>
          <p>
            The Company reserves the right to change this Privacy Policy as it may deem necessary from time to
            time or as may be required by law. Any changes will be immediately posted on the Platform alongwith
            the date of the modification and if the Users continue to use this Platform, they are deemed to have
            accepted the terms of the altered privacy policy.
          </p>
        </div>

        <div className="sec-wrapper">
          <h5 className="monument mb-20">I. GRIEVANCE OFFICER</h5>
          <p>
            For any complaints/grievances/discrepancies in respect of this Privacy Policy, please contact Mr.
            Rajeshwar Prasad at
            {' '} <a href='mailto:customer.grievance@matter.in'>customer.grievance@matter.in</a>, the designated ‘Grievance Officer’ under
            Information Technology Act, 2000 and any other applicable law for this purpose. The subject of
            User’s e-mail should state “Complaint arising from use of the Platform”. The Company shall
            immediately look into the same and undertake the requisite actions.
          </p>
        </div>

      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default CompanionMobilePrivacyPolicy

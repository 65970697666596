import { AnyAction } from 'redux'
import { ActionTypes } from '../constants/index'
import { PartnerAndChannel } from '../constants/model'

let initialState: PartnerAndChannel = new PartnerAndChannel()
export default function partnerAndChannel(
  state: any = initialState,
  action: AnyAction
) {
  const { type, payload } = action


  switch (type) {
    case ActionTypes.PARTNER_TYPE:
      return { ...state, ...payload }
    case ActionTypes.PARTNER_WITH_US:
      return { ...state, ...payload }
    case ActionTypes.CHANNEL_PARTNER_STEPPER:
      return { ...state, ...payload }
    case ActionTypes.CHANNEL_PARTNER_OTP:
      return { ...state, ...payload }
    case ActionTypes.CHANNEL_PARTNER_FORMDATA:
      return { ...state, ...payload }
    case ActionTypes.CHANNEL_PARTNER_QUESTIONS:
      return { ...state, ...payload }
    case ActionTypes.UPDATE_QUESTIONS:
      return { ...state, ...payload }
    case ActionTypes.CHANNEL_PARTNER_LOCATION_LIST:
      return { ...state, ...payload }
    case ActionTypes.RESET_CHANNEL_PARTNER:
      return { ...state, ...payload }
    default:
      return state
  }
}

import React from "react";
import Header from "../../../components/Header";
import "../legal.scss";
import Footer from "../../../components/Footer";
import { useEffect } from "react";
import HeadlessComponent from "../../../components/HeadlessComponent";


const CompanionMobileTermsAndConditions = () => {

  useEffect(() => {
    document.title = 'Terms and Conditions | Matter'
    
  },[])
  return (
    <div className="legal-cover">
      {/* <Header /> */}
      <div className="legal-content">
        <h1 className="visuallyHidden">TERMS AND CONDITIONS OF USE</h1>
        <HeadlessComponent
        title="Terms and Conditions | Matter"
        conicalLink="https://matter.in/terms-and-conditions" 
        description="Checkout the terms and conditions of Matter.in. Understand website's terms, Governing Law related to Matter.in website, etc."
        />

        <h2 className="heading monument mb-40 mb-xs-30">TERMS AND CONDITIONS OF USE</h2>
        <p>
          Welcome to “MATTER”. The User (defined hereinafter) is requested to read these terms and
          conditions carefully before (a) using the Platform (defined hereinafter) that we, ‘Matter Motor Works
          Private Limited’ being a company incorporated under the Companies Act, 2013, with registered office
          at 301, Parishram Building, 5B Rashmi Society, Nr. Mithakhali Six Roads, Navrangpura, Ahmedabad –
          380009, India (hereinafter, “the Company”, which expression, unless the context otherwise requires,
          shall mean and include its respective successors and assigns) offer; or (b) visiting/using our Platform in
          general. If the User does not agree to any of these Terms and Conditions and the Privacy Policy
          provided at {' '} <a style={{pointerEvents:  'none', fontStyle: 'italic', textDecorationLine: 'none'  }}  className='primary white' href="/privacy-policy">https://matter.in/privacy-policy</a>,
          we request the User to not use the Platform.
        </p>
        <p>
          This document is an electronic record under Information Technology Act, 2000 and rules thereunder as
          applicable and the amended provisions pertaining to electronic records in various statutes under
          applicable Indian laws. This electronic record is generated by a computer system and does not require
          any physical or digital signatures.
        </p>
        <p>
          This document is published and shall be construed in accordance with the provisions of Rule 3(1) of
          the Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing the rules
          and regulations, privacy policy and user agreement for access or usage of the Platform.
        </p>
        <br />


        <ol>
          <li className="monu-count">
            <h5 className="monument mb-20">USER AGREEMENT</h5>
            <ol>
              <li>
                User’s use of the website https://matter.in (“the Website”, which expression shall include any
                successor or replacement website) or of the mobile application or similar applications provided
                on other electronic devices by the Company under the brand name “Matter” (“App”) is
                governed by the following terms and conditions (“User Agreement”). By using the Website
                and/or the App (hereinafter the Website and the App shall be collectively referred to as
                “Platform”), the User signifies his/her agreement to be bound by this User Agreement. This
                User Agreement shall come into effect upon each visit of the Platform or upon the User’s
                registration or upon the User providing any information on the Platform. In this User
                Agreement, the User is contracting with the Company.
              </li>
              <li>
                This User Agreement applies to any natural or legal person who has agreed to become a
                member of the Platform by providing the information required while registering on the Platform
                as a registered user or by creating an account on the Platform (“Registered User”) or who
                uses the Platform without becoming a Registered User (“Visitor”). A Registered User has
                allocated himself/ herself a unique identification user name to become a Registered User. For
                the purpose of this User Agreement, ‘“User” shall mean a Registered User or a Visitor, as the
                context may require.
              </li>
              <li>
                The Website can be viewed anywhere in the world but the Platform is intended for use only in
                India. The products and services provided on the Platform are not available in all geographic
                areas. The Company’s vehicles described in the Platform relate only to Indian specifications.
              </li>
              <li>
                Some services offered through the Platform may be subject to additional terms and conditions
                adopted by the Company (for instance if the User intends to pre-book/ book any of our
                vehicles, our policy at {' '} <a style={{pointerEvents:  'none', fontStyle: 'italic', textDecorationLine: 'none'  }}  className='primary white'  href="/online-prebooking-terms">https://matter.in/online-prebooking-terms</a> {' '} shall also be applicable to the User).
                User’s use of those services is subject to those additional terms and conditions.
              </li>

            </ol>
          </li>
          <li className="monu-count">
            <h5 className="monument mb-20">AMENDMENTS TO THE USER AGREEMENT</h5>
            <p>
              The Company may amend this User Agreement and the Privacy Policy at any time by posting
              a revised version on the Platform. The revised version will be effective at the time the
              Company posts it on the Platform. User should make note of the &quot;last updated date&quot; at the
              beginning of this User Agreement or the Privacy Policy. With this information, the User should
              be able to identify if this User Agreement or the Privacy Policy have been updated recently.
              The User is advised to regularly check for any amendments or updates to the terms and
              conditions contained in this User Agreement. If the changes to this User Agreement and/or the
              Privacy Policy are material in the opinion of the Company, we may specify the nature of the
              changes along with the date from which such changes are effective on the Platform. If the User
              does not agree to the new terms, the User should not use the Platform and if the User is a
              member/ Registered User, then the User may also delete their account information by writing
              to us at 
              {' '} <a className="primary" href="mailto:customer.care@matter.in">customer.care@matter.in</a>.
            </p>
          </li>
          <li className="monu-count">
            <h5 className="monument mb-20">DISCLAIMERS AND NO WARRANTIES</h5>
            <p>The following disclaimers by the Company are the key to determine any relationship between
              the Users and the Company for their use of the Platform and the Company, its affiliates, its
              directors, employees, agents, consultants, business partners, representatives make no
              representations or warranties with respect to the following:</p>
            <ol>
              <li>
                The Company provides the Platform on “as is” basis and without any warranty or conditions,
                express, implied or statutory and specifically disclaim any implied warranties of title, suitability
                for a particular purpose and non-infringement to the fullest extent permitted by law. Users
                expressly agree that their use of the Platform is at their risk and that they are using prudent
                judgement before availing any services through the Platform.
              </li>
              <li>
                The Company may suspend, withdraw, discontinue or change all or any part of the Platform
                without notice. The Company shall not be liable if for any reason the Platform or its content is
                unavailable at any time and/or for any period.
              </li>
              <li>
                The information contained on the Platform is for general informational purposes only. While we
                endeavour to keep the information on the Platform up-to-date and correct, the Company, its
                affiliates, its directors, employees, agents, consultants, business partners and/or
                representatives make no representations or warranties about the accuracy, reliability,
                completeness, suitability, availability and/or timeliness with respect to any content, information,
                visual representations, product, software, text, related graphics, links or communications
                provided on or through the use of the Platform or that the operation of the Platform will be error
                free and/or uninterrupted. Any reliance placed on such information provided on the Platform is
                therefore strictly at the User’s own risk.
              </li>
              <li>
                The Company assumes no liability or responsibility for any (i) errors, mistakes, or inaccuracies
                of content, (ii) personal injury or property damage, of any nature whatsoever, resulting from
                User’s access to and use of the Platform, (iii) any unauthorized access to or use of its servers
                and/or any and all personal information and/or financial information stored therein, (iv) any
                interruption or cessation of transmission to or from the Platform, and/or (v) any bugs, viruses,
                trojan horses, or the like which may be transmitted to or through the Platform by any third
                party.
              </li>
              <li>
                The Company does not warrant, endorse, guarantee, or assume responsibility for any product
                or service advertised or offered by a third party through the Platform or any hyperlinked
                website or featured in any banner or other advertising, and the Company will not be a party to
                or in any way be responsible for monitoring any transaction between the User and third-party
                providers of products or services.
              </li>
              <li>
                The User agrees that the aforesaid disclaimers shall be in addition to any other disclaimers
                given in this User Agreement or in the Privacy Policy given at
                {' '} <a style={{pointerEvents:  'none', fontStyle: 'italic', textDecorationLine: 'none'  }}  className='primary white' href="/privacy-policy">https://matter.in/privacy-policy</a> {' '} 
                or any other policies on the
                Platform including the Online Pre-Booking T&C given at 
                {' '} <a style={{pointerEvents:  'none', fontStyle: 'italic', textDecorationLine: 'none'  }}  className='primary white' href="/online-prebooking-terms">https://matter.in/online-prebooking-terms</a>.
              </li>
            </ol>
          </li>
          <li className="monu-count">
            <h5 className="monument mb-20">ELECTRONIC COMMUNICATIONS AND SERVICE OF NOTICE</h5>
            <ol>
              <li>
                When the User uses the Platform or provide information or communication to the Company,
                the Users agree and understand that they are communicating with the Platform and the
                Company through electronic record which are legally identifiable and enforceable, and they
                consent to receive communications via electronic record from the Company, communicated or
                required.
              </li>
              <li>
                The mobile number/registration data/ email ID/User’s account details of the third party website
                through which the User registers with the Platform (if permitted by the Platform) will be
                construed as their ‘designated electronic address’ and the Company, the third parties and law
                enforcement agencies will communicate with the User on their designated electronic address
                which will be deemed adequate service of notice/ electronic record.
              </li>
              <li>
                By impliedly or expressly accepting this User Agreement, the User as a user, also accept and
                agree to be bound by various policies of the Company as provided from time to time in various
                hyperlinks on the Platform.
              </li>

            </ol>
          </li>
          <li className="monu-count">
            <h5 className="monument mb-20">ELIGIBILITY</h5>
            <p>
              Use of the Platform is available only to persons who can form legally binding contracts under
              the Indian Contract Act, 1872 and/or any other applicable law for this purpose. Persons who
              are “incompetent to contract” within the meaning of the Indian Contract Act, 1872 and any
              other law applicable to them for this purpose including minors, un-discharged insolvents etc.
              are not eligible to use the Platform. If a User is a minor i.e., under the age of 18 years (or such
              other eligible age as applicable to User’s jurisdiction), he/she shall not register as a
              member/user of the Platform and may ask his/her parent or guardian to do so on his/her
              behalf. The Company reserves the right to terminate the User’s membership and refuse to
              provide the User with access to the Platform if it is brought to the Company’s notice or if it is
              discovered that the User is under the age of 18 years (or such other eligible age as applicable
              to User’s jurisdiction) or is otherwise “incompetent to contract”. If a User is registering as or on
              behalf of a business entity, the User represents that he/she is duly authorized by the business
              entity to accept this User Agreement and the User has the authority to bind that business entity
              to this User Agreement.
            </p>

          </li>
          <li className="monu-count">
            <h5 className="monument mb-20">LIMITATION OF LIABILITY</h5>
            <ol>
              <li>
                Any service provided through the Platform are provided without any implicit or explicit warranty.
                The Company cannot be held responsible for any harm coming from the inability to access or
                use the Platform, or the loss of any content uploaded by the User. It is the User’s responsibility
                to keep a copy of the content uploaded on the Platform if the same is needed by the User.
              </li>
              <li>
                The Company, its affiliates, its directors, employees, agents, consultants, business partners,
                representatives disclaim all warranties, express or implied, in connection with the Platform and
                User’s use thereof, to the fullest extent permitted by law.
              </li>
              <li>
                In no event shall the Company, its affiliates, its business partners, officers, directors,
                employees, representatives or agents, be liable for any direct, indirect, incidental, special,
                consequential or exemplary damages, resulting from any aspect of User’s use of the Platform,
                including without limitation whether the damages arise from use or misuse of the Platform, or
                the interruption, suspension, modification, alteration, or termination of the Platform. Such
                limitation of liability shall also apply with respect to damages incurred by reason of other
                services or products received through or advertised in connection with the Platform or any links
                on the Platform, as well as by reason of any information, opinions or advice received through
                or advertised in connection with the Platform or any links on the Website. These limitations
                shall apply to the fullest extent permitted by law. The User specifically acknowledges and
                agrees that the Company shall not be liable for User submissions or the defamatory, offensive,
                or illegal conduct of any user or third party and that the risk of harm or damage from the
                foregoing rests entirely with the User.
              </li>
            </ol>
          </li>
          <li className="monu-count">
            <h5 className="monument mb-20">ACCOUNT AND REGISTRATION OBLIGATIONS</h5>
            <p>
              If the User uses the Platform as a Registered User, the User is responsible for maintaining the
              confidentiality of his/her phone number and username and the Company will not be liable for
              any loss that the User may incur as a result of someone using his/her account, either with or
              without his/her knowledge. The User is responsible for all activities that occur under User’s
              account. The User agrees to provide true, accurate, current and complete information about
              himself/herself as prompted by the Platform at the time of registration or provided by the User
              as a Visitor or user of a third-party site through which the User accesses the Platform. If the
              User provides any information that is untrue, inaccurate, not correct or incomplete, or the
              Company has reasonable grounds to suspect that such information is untrue, inaccurate, not
              correct or incomplete or not in accordance with the User Agreement, the Company has the
              right to indefinitely suspend or terminate or block access of User’s membership with the
              Platform and refuse to provide the User with access to the Platform. By using the login service,
              the User authorizes the Company to access and retrieve information requested by the User.
              The User confirms and consents that the documents provided herein may be used by the
              Company to ensure compliance with all relevant and applicable regulatory and KYC
              requirements as need be. The information provided to us during the registration process shall
              be protected in accordance with the Privacy Policy.
            </p>
            <p>
              If the User wishes to update any of the information provided by the User, the User may do so
              by writing to us at 
              {' '} <a className="primary" href="mailto:customer.care@matter.in">customer.care@matter.in</a>.
            </p>
            <p>
              If the User wishes to delete their account on our Platform, the User can do so by writing to us
              at 
              {' '} <a className="primary" href="mailto:customer.care@matter.in">customer.care@matter.in</a>.
            </p>
          </li>
          <li className="monu-count">
            <h5 className="monument mb-20">USE OF THE PLATFORM</h5>
            <ol>
              <li>
                The User understands and agrees that the Company and the Platform merely provides
                information and may also provide certain services to its Registered Users and persons
                browsing / visiting the Platform. The Platform also contains various third-party generated
                content and material. The Company neither originates nor initiates the transmission nor selects
                the sender and receiver of the transmission nor selects nor modifies the information contained
                in the transmission.
              </li>
              <li>
                The User understands, agrees and acknowledges that the Company may use third party
                service providers to store and process User’s personal information and other information which
                the User provides to the Company (as more specifically identified in the Privacy Policy) and
                such third parties may be a store or brand or retailer or supplier. The Company shall execute
                agreements with such third party service providers to cast an obligation on them to maintain
                the confidentiality of User’s Personal Information on terms that are at least at par with those set
                out in our Privacy Policy and to use the information only for the specific purpose for which the
                agreements have been executed.
              </li>
              <li>
                The User shall at all times ensure full compliance with the applicable provisions of the
                Information Technology Act, 2000 and rules thereunder as applicable and as amended from
                time to time and also all applicable laws, rules and regulations and International laws (including
                without limitation all direct and indirect tax laws and all local, entry or usage related tax laws)
                regarding User’s use of our Platform that is applicable to the User.
              </li>
            </ol>
          </li>
          <li className="monu-count">
            <h5 className="monument mb-20">USAGE RESTRICTIONS</h5>
            <ol>
              <li>
                The User shall not use the Platform for any of the following purposes:
                <ul className="mt-20">
                  <li>Gaining unauthorised access to other computer systems;</li>
                  <li>Breaching any applicable laws;</li>
                  <li>interfering or disrupting networks or website;</li>
                  <li>reproducing, republishing, adapting, creating a derivative work, copying, duplicating,
                    selling, reselling or in any way using for commercial purposes, any part of the Platform;</li>
                  <li>commercial purposes;</li>
                  <li>making, transmitting or storing electronic copies of materials protected by copyright without
                    the permission of the Company; and/or</li>
                  <li>impersonating another person and / or employing misleading email addresses, or forged
                    headers or otherwise manipulating identifiers in order to disguise the origin of the content
                    transmitted through the Platform.</li>
                </ul>
              </li>
              <li>
                The User shall not use the Platform to disseminate, upload, post, message, display, publish,
                transmit or share any information or material that:
                <ul className="mt-20">
                  <li>is harmful, abusive, unlawful, threatening, harassing, blasphemous, defamatory, obscene,
                    pornographic, paedophilic, libellous, invasive of another’s privacy or other rights, hateful,
                    or racially/ ethnically objectionable, disparaging, relating or encouraging money laundering
                    or gambling or harms or could harm minors in any way or otherwise unlawful in any
                    manner whatsoever;</li>
                  <li>encourages conduct that constitutes a criminal offence, results in civil liability or otherwise
                    breaches any relevant laws, regulations or code of practice;</li>
                  <li>belongs to another person and to which the User does not have any right;</li>
                  <li>infringes any patents, trade secrets, copyright or other proprietary rights;</li>
                  <li>disrupts the normal flow of dialogue with an excessive number of messages (flooding
                    attack) to the Platform, or that otherwise negatively affects other users’ ability to use the
                    Platform; and/or</li>
                  <li>threatens the unity, integrity, defence, security or sovereignty of India, friendly relations
                    with foreign states, or public order or causes incitement of the commission of any
                    cognizable offence or prevents investigation of any offence or is insulting any other nation.</li>
                </ul>
                <p>
                  The Company upon receiving the information either on its own knowledge or on the information
                  provided by the affected person about the breach/contravention of the abovementioned
                  information, shall undertake the necessary actions and where applicable disable such
                  information.
                </p>
                <p>
                  The comments submitted on the public sections/forum may be recorded and stored in multiple
                  places, both on the Platform, and elsewhere on the internet.
                </p>
              </li>
            </ol>
          </li>
          <li className="monu-count">
            <h5 className="monument mb-20">PROPRIETARY RIGHTS AND PLATFORM ACCESS LICENSE</h5>
            <ol>
              <li>
                The User acknowledges and agrees that all software utilized in connection with the Platform
                are protected by intellectual property laws. The Company grants the User a very limited, non-
                exclusive, non-transferable and personal, royalty-free license to access the Platform. The User
                shall not copy, download, modify, create derivative works and shall not reverse engineer, de-
                compile or disassemble the software in any shape or form, or any content/commercials of the
                Platform, and in any way try to discover the source codes of the software, sell, sub-license,
                distribute, publish, or transfer to third parties any right on the software or any part thereof.
              </li>
              <li>
                Unless otherwise indicated, the Platform and all materials, including images, designs, video
                clips, contents, articles, blogs, etc. that are part of the Platform are intellectual properties
                owned, controlled or licensed by/to the Company and are protected by intellectual property
                laws. The User is not permitted to use the same. Unless otherwise indicated or anything
                contained to the contrary or any proprietary material owned by a third party and so expressly
                mentioned, the Company owns or has been licensed as the case may be, all intellectual
                property rights to and in the trademark “Matter” and the Platform including without limitation,
                any and all rights, title and interest in and to trade names, service marks, logos, domain name,
                copyright, related rights, patents, utility models, designs, know-how, designs, graphics and
                inventions, goodwill, source code, meta tags, databases, text, content, graphics, icons, and
                hyperlinks. All the intellectual property rights arising as a result of domain names, internet or
                any other right available under applicable law shall vest solely and exclusively in the Company
                and/or its affiliate, as the case may be, as the owner of such domain name. The User hereby
                agrees and confirms that nothing contained herein shall be construed as conferring or
                transferring to the User any license or right, by implications, estoppels or otherwise, to any
                intellectual property rights mentioned hereinabove. The User shall not use, reproduce or
                distribute any content from the Platform belonging to the Company and/or its affiliates, as the
                case may be.
              </li>
              <li>
                The Company makes the best possible efforts to ensure that Users can get uninterrupted and
                error-free access to the Platform. However, the access of Users may be temporarily
                suspended or restricted owing to the repairs, maintenance, modification, addition, deletion or
                such other necessary changes in the Platform without prior notice.
              </li>
              <li>
                The User may post reviews, comments, feedback, submit suggestions, ideas, or such other
                information, as long as the content of such information is not fraudulent, illegal, abusive,
                obscene, defamatory, objectionable to the Company, any third party, or public in general and
                does not contain any information that acts as an infringement to any laws or rules for the time
                being in force. Non-compliance with this clause may result in termination of the Services
                provided to the User.
              </li>
            </ol>
          </li>
          <li className="monu-count">
            <h5 className="monument mb-20">CLAIMS OF INFRINGEMENT/ CLAIMS AGAINST OBJECTIONABLE CONTENT</h5>
            <p>
              If the User believes that any content on the Platform infringes upon User’s intellectual property
              rights or is harmful, abusive, unlawful, threatening, harassing, blasphemous, defamatory,
              obscene, pornographic, paedophilic, libellous, invasive of another’s privacy or other rights,
              hateful, or racially/ ethnically objectionable, disparaging, relating or encouraging money
              laundering or gambling or harms or could harm minors in any way or otherwise unlawful in any
              manner whatsoever or which consists of or contains software viruses (“Objectionable
              Content”), please notify us immediately by sending an email at
              {' '} <a className="primary" href="mailto:customer.grievance@matter.in">customer.grievance@matter.in</a>.
              The subject line of the User’s email should state “Claim
              against Objectionable Content” or “Claim of Infringement”. Alternatively, the User may write to
              us on the following postal address:
            </p>
            <p>
              Attn: Mr. Rajeshwar Prasad <br />
              301, Parishram Building,<br />
              5B Rashmi Society, Nr. Mithakhali Six Roads,<br />
              Navrangpura, Ahmedabad – 380009
            </p>
            <p>
              Once the complaint has been received from the User, the Company will make all reasonable
              endeavours to undertake the necessary actions including removal of such Objectionable
              Content complained about within a reasonable time. Any action taken by us is without any
              admission as to liability and without prejudice to any rights, remedies or defenses, all of which
              are expressly reserved. By submitting a complaint as mentioned above, the User grants to the
              Company the right to use, reproduce, modify, publish, translate, adapt, create derivative works
              from the same, throughout the world in any media. This includes forwarding the complaint to
              the parties involved in the provision of the Objectionable Content. The User agrees to
              indemnify the Company for all claims brought by a third party against the Company arising out
              of or in connection with the submission of a complaint. The User may note that giving false,
              inaccurate or misleading information under this Section may result in civil and/or criminal
              liability. Users should seek legal advice at their end in case they have any doubts or if they
              have any questions.
            </p>
          </li>
          <li className="monu-count">
            <h5 className="monument mb-20">LINKS TO THIRD PARTY WEBSITES</h5>
            <p>
              In addition, the Platform may occasionally contain links to third-party websites. If the User
              clicks on the link to third-party websites, the User leaves our Platform. The User’s use of such
              other third-party websites is at his/her own risk. These third party websites may have their own
              terms and conditions of use (including without limitation to such website’s privacy policy and
              disclaimers) that the User may be subject to. Such third party websites are not under our
              control and the User acknowledges that we are not liable for the content, functions, accuracy,
              legality, appropriateness, or any other aspect of those other third party websites or resources.
              This User Agreement does not govern the terms of use of such third party websites. The User
              further acknowledges and agrees that we shall not be liable for any damage related to the use
              of any content, goods, or services available through any third-party website or resource. We
              recommend that when the User enters a third-party website, they review the third party
              website’s terms of use and their privacy policy.
            </p>

          </li>
          <li className="monu-count">
            <h5 className="monument mb-20">PRIVACY</h5>
            <p>
              The Company collects, stores, processes and uses User’s information in accordance with the
              Company's Privacy Policy. By using the Platform and/ or by providing their information, the
              User consents to the collection and use of the information the User discloses on the Platform
              by the Company in accordance with the Company's Privacy Policy. The Privacy Policy shall be
              deemed to form part of this User Agreement by way of this reference. The User may view the
              Privacy Policy at 
               {' '} <a style={{pointerEvents:  'none', fontStyle: 'italic', textDecorationLine: 'none'  }}  className='primary white'  href="/privacy-policy">https://matter.in/privacy-policy</a>.
            </p>
          </li>
          <li className="monu-count">
            <h5 className="monument mb-20">ONLINE PRE-BOOKING T&C</h5>
            <p>
              If the User is a Registered User who has availed the service of pre-booking a vehicle on the
              Platform, such pre-booking shall be governed by the Online Pre-Booking T&C
              {' '} <a style={{pointerEvents:  'none', fontStyle: 'italic', textDecorationLine: 'none'  }}  className='primary white'  href="/online-prebooking-terms">https://matter.in/online-prebooking-terms</a>, which are in addition to this User Agreement. In the event of a conflict, the terms and
              conditions provided in the Online Pre-booking T&C shall prevail over this User Agreement.
            </p>
          </li>
          <li className="monu-count">
            <h5 className="monument mb-20">TERMINATION</h5>
            <p>
              The Company reserves the right, in its sole discretion, to terminate or limit User’s access to
              any or all parts of the Platform or any content thereof, and the related services or any portion
              thereof at any time, without notice, and the Company shall not be liable to the User for the
              same in any manner whatsoever.
            </p>
          </li>
          <li className="monu-count">
            <h5 className="monument mb-20">NON-WAIVER AND FORCE MAJEURE</h5>
            <p>
              The Company reserves all rights permissible under this User Agreement as well as under the
              provisions of any applicable law. The non-enforcement of any particular provision or provisions
              of this User Agreement or any applicable law should not be construed as the Company’s
              waiver of its right to enforce that same provision under the same or different circumstances at
              any time in the future. Further, the Company is not responsible for any disruption in service or
              any circumstances arising as a result of events beyond our control including, but not limited to,
              acts of God, war, insurrection, riots, terrorism, crime, labour shortages (including lawful and
              unlawful strikes), embargoes, postal disruption, communication disruption, failure or shortage
              of infrastructure, viruses/bugs, or any other similar events.
            </p>
          </li>
          <li className="monu-count">
            <h5 className="monument mb-20">GENERAL</h5>
            <ol>
              <li>
                None of the provisions of the User Agreements shall be deemed to constitute a partnership or
                agency between the User and the Company and the User shall have no authority to bind the
                Company in any manner whatsoever nor shall the Company have any authority to bind the
                User in any manner whatsoever. All the services provided by the Company shall be as
                independent contractor and on principal to principal basis.
              </li>
              <li>
                If any clause of the User Agreement shall be deemed invalid, void or for any reason
                unenforceable, such clause shall be deemed severable and shall not affect the validity and
                enforceability of the remaining clauses of this User Agreement.
              </li>
              <li>
                This User Agreement sets forth the entire understanding and agreement between the User and
                the Company with respect to the subject matter hereof.
              </li>
              <li>
                In the Company’s sole discretion, the Company may transfer its rights and obligations under
                this Agreement, assign this Agreement or novate this Agreement without User’s prior express
                consent. However, the Company on reasonable efforts basis and if practicable may provide
                the User with an advance intimation of any such acts.
              </li>
            </ol>
          </li>
          <li className="monu-count">
            <h5 className="monument mb-20">INDEMNITY</h5>
            <p>The User agrees to indemnify and hold harmless the Company, its subsidiaries, affiliates,
              representatives, officers, directors, agents, employees, channel partners and suppliers, from
              any direct or indirect losses, claim, demand, action, or damage, including reasonable
              attorney's fees, arising out of or related to User’s use of or in connection to the Platform
              including: (i) his/her breach of the terms of this Agreement; (ii) any claims made by any third
              party due to, or arising out of, or in connection with, his/her use or misuse of the Platform; (iii)
              anything the Users may post such as any information, content, user information, personal or
              sensitive data or any sort of query, response, information, etc. on the Platform; (iv) his/her
              violation of any rights of another, including any intellectual property rights; (v) any fraud,
              negligence or misconduct by the User; (vi) violation of any applicable laws, regulations
              including but not limited to that of intellectual property rights, payment of statutory dues and
              taxes etc.</p>
          </li>
          <li className="monu-count">
            <h5 className="monument mb-20">GRIEVANCE OFFICER</h5>
            <p>For any complaints/grievances/discrepancies against usage of the Services, please contact
              Mr. Rajeshwar Prasad at
              {' '} <a className="primary" href="mailto:customer.grievance@matter.in">customer.grievance@matter.in</a> {' '} 
              the designated Grievance Officer
              under Information Technology Act, 2000 and any other applicable law for this purpose. The
              subject of User’s e-mail should state “Complaint arising from use of the Platform”. The
              Company shall immediately look into the same and undertake the necessary action required to
              be taken by us in our capacity as an intermediary.</p>

          </li>
          <li className="monu-count">
            <h5 className="monument mb-20">GOVERNING LAW</h5>
            <p>This User Agreement and all the rules and policies contained herein and any of User’s usage
              of Platform of the Company shall be governed and constructed in accordance with the laws of
              India.</p>

          </li>
          <li className="monu-count">
            <h5 className="monument mb-20">ARBITRATIONS</h5>
            <p>If any dispute arises between the User and the Company during his/her use of the Platform of
              the Company or in relation to any activity on the Platform, in connection with the validity,
              interpretation, implementation or alleged breach of any provision of the User Agreement and
              all the rules and policies contained herein, the dispute shall be referred to a sole arbitrator who
              shall be an independent and neutral third party identified by the Company. The Arbitration and
              Conciliation Act, 1996, shall govern the arbitration proceeding. The arbitration proceedings
              shall be in the English language. The seat and venue of arbitration shall be Ahmedabad.</p>
          </li>
        </ol>

      </div>
      {/* <Footer /> */}
    </div >
  );
};

export default CompanionMobileTermsAndConditions;

import { AnyAction } from 'redux'
import { ActionTypes } from '../constants/index'
import { LocatorAndScheduling } from '../constants/model'

let initialState: LocatorAndScheduling = new LocatorAndScheduling()

export default function locatorAndScheduling(
  state: any = initialState,
  action: AnyAction
) {
  const { type, payload } = action
  switch (type) {
    case ActionTypes.SEARCH_RESULTS:
      return { ...state, ...payload }
    case ActionTypes.DEALER_LIST:
      return { ...state, ...payload }
    case ActionTypes.DEALERS_LIST:
      return {...state,...payload}
    default:
      return state
  }
}

import ReCAPTCHA from 'react-google-recaptcha'
import { setXResponse } from '../../utils/common'

const ReCaptchaComponent = () => {

  const handleRecaptcha = (value: any) => {    
    setXResponse(value)        
  }

  const handleRecaptchaExpire = () => {    
    setXResponse('')    
  }
  
  return (
    <ReCAPTCHA
        className='mt-24'
        sitekey={process.env.RECAPTCHA_KEY || ''}
        onChange={handleRecaptcha}
        onExpired={handleRecaptchaExpire}
    />
  )
}

export default ReCaptchaComponent

import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InputAdornment from '@mui/material/InputAdornment'
import Link from '@mui/material/Link'
import Icons from '../../../../utils/icons'
import { ReducerModel } from '../../../../reducers/reducerModel'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import { phonePreventText, registerYourInterest } from '../../../../schema'
import {
  postGeoLocaiton,
  resetRYI,
  registerInterestUser,
} from '../../../../actions/registerInterest'
import { ActionTypes } from '../../../../constants'
import { useHistory } from 'react-router-dom'

import { handlePasteMethod, tagMethodGTM } from '../../../../utils/common'

interface IntialValues {
  firstName: string
  lastName: string
  phone: string
  email: string
  receiveNotifications?: boolean
  whatsAppNo?: string
  city: string
  state: string
  teams?: boolean
  interestedIn: any
}

const FullDetail = ({validateUserToken, setShowHeaderText, setShowFullDetail }: any) => {
  const history = useHistory()
  const { phone, otpVerifed } = useSelector(
    (store: ReducerModel) => store.registerYourInterest
  )

  const [userCity, setUserCity] = useState('')
  const [userState, setUserState] = useState('')
  const [confirmationScreen, setConfirmationScreen] = useState(false)

  const { appConfig } = useSelector((store: ReducerModel) => store.home)

  const ryiUserData = useSelector(
    (store: ReducerModel) => store.registerYourInterest
  )
  const { joinWaitListkey } = useSelector(
    (store: ReducerModel) => store.home.appConfig
  )
  const [confirmationMessage, setConfirmationMessage] = useState(false)

  const dispatch: Function = useDispatch()

  const intialVal: IntialValues = {
    city: '',
    state: '',
    teams: false,
    interestedIn: [],
    receiveNotifications: true,
    email: ryiUserData?.email || '',
    phone: ryiUserData?.phone || phone,
    lastName: ryiUserData?.lastName || '',
    firstName: ryiUserData?.firstName || '',
    whatsAppNo: ryiUserData?.whatsAppNo || phone,
  }
  useEffect(() => {
    window.scrollTo(0, 0)



    const inputType: any = document.querySelector("#whatsAppNo")
    console.log("inputType",inputType)

    inputType?.addEventListener('keydown', function(e:any) {
      if (e.which === 38 || e.which === 40) {
          e.preventDefault();
      }
  });

  if (inputType) {
    inputType?.addEventListener('keypress', (evt: any) => {
      if (evt.which === 8) {
        return
      }
      if (evt.which < 48 || evt.which > 57) {
        evt.preventDefault()
      }
    })
  }



    navigator.geolocation.getCurrentPosition((position) => {
      let lat = position.coords.latitude
      let long = position.coords.longitude
      dispatch(
        postGeoLocaiton(
          {
            longitude: long.toString(),
            latitude: lat.toString(),
          },

          (response: any) => {
            const { city, state } = response
            joinWaitListkey
              ? tagMethodGTM(
                  `Join Waitlist`,
                  `Matter - Join Waitlist - Help us know you better`,
                  'Click',
                  { city }
                )
              : tagMethodGTM(
                  `Register Your Interest`,
                  `Matter - Register Your Interest - Help us know you better`,
                  'Click',
                  { city }
                )
            
            joinWaitListkey
              ? tagMethodGTM(
                  `Join Waitlist`,
                  `Matter - Join Waitlist - Help us know you better`,
                  'Click',
                  { state }
                )
              : tagMethodGTM(
                  `Register Your Interest`,
                  `Matter - Register Your Interest - Help us know you better`,
                  'Click',
                  { state }
                )
            // console.log(city ,state)
         
            setUserCity(city)
            setUserState(state)
          },

          () => {}
        )
      )
    })

    //  RESET RYI REDUCER ON UNMOUNT COMPONENT
    return () => {      
      dispatch({
        type: ActionTypes.RESET_ONBOARDING
      })
      dispatch(resetRYI())
    }
  }, [])

  const handleSubmit = (values: IntialValues) => {
    delete values.teams
    !values.receiveNotifications && delete values.whatsAppNo
    delete values.receiveNotifications
    dispatch(
      registerInterestUser(
        { ...values, interestedIn: 'Buying the AERA' },
        (data: any) => {
          window.scrollTo(0, 0)
          setShowHeaderText(true)
          setConfirmationScreen(true)
          dispatch({
            type: ActionTypes.RESET_ONBOARDING,
          })
          dispatch(resetRYI())
        },

        (error: any) => {
          if (error.message === `Given phone ${values.phone} already exists` || 
          error.message === `You are already in our waitlist. We will keep you posted about latest update`
          ) {
            window.scrollTo(0, 0)
            setConfirmationScreen(true)
            setConfirmationMessage(true)
            setShowHeaderText(true)
            dispatch({
              type: ActionTypes.RESET_ONBOARDING,
            })
            dispatch(resetRYI())
          }
        }
      )
    )
  }

  const showMessage = () => {
    let message: any = ''
    if (joinWaitListkey) {
      if (confirmationMessage) {
        message = 'You have already joined the waitlist.'
      } else {
        message = `You've successfully joined the waitlist.`
      }
      message = (
        <span>
          {message} <br /> Hang in there, we'll get back to you soon!
        </span>
      )
    } else {
      if (confirmationMessage) {
        message = 'You have already joined the waitlist.'
      }else {
        message = `You've successfully joined the waitlist.`
      }
    }
    return message
  }
  return (
    <>
      {!confirmationScreen ? (
        <>
          <p className="small subText mt-20 mb-24">Help us know you better</p>
          <Formik
            validationSchema={registerYourInterest}
            initialValues={{
              ...intialVal,
              city: userCity,
              state: userState,
              interestedIn: 'Buying the AERA',
            }}
            enableReinitialize
            onSubmit={(values) => {
              joinWaitListkey ? tagMethodGTM(`Join Waitlist Form`, `Matter - Join Waitlist - Help us know you better - Submit`, "Click", "Submit", {
                mobile_number: values.phone,
                first_name : values.firstName,
                last_name: values.lastName,
                state: values.state,
                city: values.city,
                email :values.email,
                // interested_in: values.interestedIn.join(','),
                important_updates_on_whatsapp : values.whatsAppNo,
              }) : tagMethodGTM(`Register Your Interest Form`, `Matter - Register Your Interest - Help us know you better - Submit`, "Click", "Submit", {
                mobile_number: values.phone,
                first_name : values.firstName,
                last_name: values.lastName,
                state: values.state,
                city: values.city,
                email :values.email,
                // interested_in: values.interestedIn.join(','),
                important_updates_on_whatsapp : values.whatsAppNo,
              });
              handleSubmit(values)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              touched,
              values,
              isValid,
              dirty,
              setFieldValue,
              handleSubmit,
            }) => {
              const {
                firstName,
                lastName,
                phone,
                email,
                receiveNotifications,
                whatsAppNo,
                city,
                state,
                teams,
                // interestedIn,
              } = values

              console.log('Before', values)

              console.log('Errors', errors)

              return (
                <Form>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="mobileNumber"
                            placeholder="Phone Number"
                            name="phone"
                            type="number"
                            className="w-100"
                            onWheel={(e) => document?.activeElement?.blur()}

                            disabled
                            value={phone}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className="cc-prop"
                                >
                                  +91
                                </InputAdornment>
                              ),
                              endAdornment: otpVerifed ? (
                                <CheckCircleIcon className="success ml-12" />
                              ) : (
                                ''
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            placeholder="First Name"
                            name="firstName"
                            value={firstName}
                            autoComplete="off"
                            className="w-100"
                            onBlurCapture={() => {
                              joinWaitListkey
                                ? tagMethodGTM(
                                    `Join Waitlist`,
                                    `Matter - Join Waitlist - Help us know you better`,
                                    'Click',
                                    'Enter First Name'
                                  )
                                : tagMethodGTM(
                                    `Join Waitlist`,
                                    `Matter - Register Your Interest - Help us know you better`,
                                    'Click',
                                    'Enter First Name'
                                  )
                            }}
                            inputProps={{ maxLength: 25 }}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                            onBlur={handleBlur}
                            error={
                              errors.firstName === ' '
                                ? false
                                : Boolean(touched.firstName && errors.firstName)
                            }
                            helperText={
                              errors.firstName === ' '
                                ? false
                                : touched.firstName && errors.firstName
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            placeholder="Last Name"
                            name="lastName"
                            value={lastName}
                            autoComplete="off"
                            className="w-100"
                            onBlurCapture={() => {
                              joinWaitListkey
                                ? tagMethodGTM(
                                    `Join Waitlist`,
                                    `Matter - Join Waitlist - Help us know you better`,
                                    'Click',
                                    'Enter Last Name'
                                  )
                                : tagMethodGTM(
                                    `Register Your Interest`,
                                    `Matter - Register Your Interest - Help us know you better`,
                                    'Click',
                                    'Enter Last Name'
                                  )
                            }}
                            onChange={(e) => {
                              handleChange(e)
                            }}
                            inputProps={{ maxLength: 25 }}
                            onBlur={handleBlur}
                            error={
                              errors.lastName === ' '
                                ? false
                                : Boolean(touched.lastName && errors.lastName)
                            }
                            helperText={
                              errors.lastName === ' '
                                ? false
                                : touched.lastName && errors.lastName
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            placeholder="City"
                            name="city"
                            value={city}
                            className="w-100"
                            autoComplete="off"
                            onBlurCapture={() => {
                              joinWaitListkey
                                ? tagMethodGTM(
                                    `Join Waitlist`,
                                    `Matter - Join Waitlist - Help us know you better`,
                                    'Click',
                                    values.city
                                  )
                                : tagMethodGTM(
                                    `Register Your Interest`,
                                    `Matter - Register Your Interest - Help us know you better`,
                                    'Click',
                                    'City'
                                  )
                            }}
                            onChange={(e) => {
                              handleChange(e)
                              // setCity(e.target.value)
                            }}
                            inputProps={{ maxLength: 40 }}
                            onBlur={handleBlur}
                            error={
                              errors.city === ' '
                                ? false
                                : Boolean(touched.city && errors.city)
                            }
                            helperText={
                              errors.city === ' '
                                ? false
                                : touched.city && errors.city
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            placeholder="State"
                            name="state"
                            value={state}
                            className="w-100"
                            onBlurCapture={() => {
                              joinWaitListkey
                                ? tagMethodGTM(
                                    `Join Waitlist`,
                                    `Matter - Join Waitlist - Help us know you better`,
                                    'Click',
                                    values.state
                                  )
                                : tagMethodGTM(
                                    `Register Your Interest`,
                                    `Matter - Register Your Interest - Help us know you better`,
                                    'Click',
                                    'State'
                                  )
                            }}
                            onChange={(e) => {
                              // setState(e.target.value)
                              handleChange(e)
                            }}
                            autoComplete="off"
                            inputProps={{ maxLength: 40 }}
                            onBlur={handleBlur}
                            error={
                              errors.state === ' '
                                ? false
                                : Boolean(touched.state && errors.state)
                            }
                            helperText={
                              errors.state === ' '
                                ? false
                                : touched.state && errors.state
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>

                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          {/* <Grid item xs={12}> */}
                            <TextField
                              className="w-100"
                              placeholder="Email ID"
                              name="email"
                              // disabled
                              value={email}
                              onBlurCapture={() => {
                                joinWaitListkey ? tagMethodGTM(`Join Waitlist` , `Matter - Join Waitlist - Help us know you better` , "Click" , "Email") 
                                : tagMethodGTM(`Register Your Interest` , `Matter - Register Your Interest - Help us know you better` , "Click" , "Email");
                              }}
                              onChange={(e) => {
                              
                                handleChange(e)}}
                              autoComplete="off"
                              onKeyDown={(e: any) =>
                                [' '].includes(e.key) && e.preventDefault()
                              }
                              onBlur={handleBlur}
                              error={
                                errors.email === ' '
                                  ? false
                                  : Boolean(touched.email && errors.email)
                              }
                              helperText={
                                errors.email === ' '
                                  ? false
                                  : touched.email && errors.email
                              }
                            />
                          {/* </Grid> */}
                        </Grid>
                      </Grid>

                    </Grid>
{/* 
                    <Grid item xs={12}>
                      <div role="group" aria-labelledby="checkbox-group">
                        <p className="mb-16">I’m interested in</p>
                        <div className="check-row wrap">
                          <div className="flex al-start">
                            <Checkbox
                              name="interestedIn"
                              value="Customer"
                              
                              onChange={(e) => {
                                tagMethodGTM(`Register Your Interest` , `Matter - Register Your Interest - Help us know you better - I'm interested in ` , "Click" , `${"Buying the AERA"}`);
                                handleChange(e)}}
                              checkedIcon={<img src={Icons.CheckboxActiveIcon} />}
                              icon={<img src={Icons.CheckboxIcon} />}
                            />

                            <p className="small subText">Buying the AERA</p>
                          </div>

                
                          <div className="flex al-start">
                            <Checkbox
                              name="interestedIn"
                              value="Supplier"
                              // onChange={handleChange}\
                              onChange={(e) => {
                                tagMethodGTM(`Register Your Interest` , `Matter - Register Your Interest - Help us know you better - I'm interested in ` , "Click" , `${"Becoming a Supplier"}`);
                                handleChange(e)}}
                              checkedIcon={<img src={Icons.CheckboxActiveIcon} />}
                              icon={<img src={Icons.CheckboxIcon} />}
                            />
                            <p className="small subText nowrap">Becoming a Supplier</p>
                          </div>
                        </div>
                      </div>
                    </Grid> */}

                    <Grid item xs={12} sm={6}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <div className="flex al-start mt-20">
                            <Checkbox
                              name="receiveNotifications"
                              checked={receiveNotifications}
                              onChange={(e) => {
                                joinWaitListkey
                                  ? !receiveNotifications
                                    ? tagMethodGTM(
                                        `Join Waitlist`,
                                        `Matter - Join Waitlist - Help us know you better - Updates on WhatsApp - Checkbox`,
                                        'Click',
                                        `Accepted`
                                      )
                                    : ''
                                  : tagMethodGTM(
                                      `Register Your Interest`,
                                      `Matter - Register Your Interest - Help us know you better - Updates on WhatsApp - Checkbox`,
                                      'Click',
                                      `Accepted`
                                    )
                                handleChange(e)
                              }}
                              checkedIcon={
                                <img src={Icons.CheckboxActiveIcon} />
                              }
                              icon={<img src={Icons.CheckboxIcon} />}
                            />
                            <p className="small subText">
                              Get important updates on WhatsApp
                            </p>
                          </div>
                          {receiveNotifications && (
                            <TextField
                              placeholder="WhatsApp Number"
                              type="number"
                              className="w-100 mb-10 mt-8"
                              onWheel={(e) => document?.activeElement?.blur()}
                              id="whatsAppNo"
                              name="whatsAppNo"
                              value={whatsAppNo}
                              onPaste={(e) =>
                                handlePasteMethod(
                                  e,
                                  setFieldValue,
                                  'whatsAppNo'
                                )
                              }
                              onBlurCapture={() => {
                                joinWaitListkey
                                  ? tagMethodGTM(
                                      `Join Waitlist`,
                                      `Matter - Join Waitlist - Help us know you better - Updates on WhatsApp`,
                                      'Click',
                                      'Checkbox'
                                    )
                                  : tagMethodGTM(
                                      `Register Your Interest`,
                                      `Matter - Register Your Interest - Help us know you better - Updates on WhatsApp`,
                                      'Click',
                                      'Checkbox'
                                    )
                              }}
                              // inputProps={{ maxLength: 10 }}
                              onChange={(e) => {
                                if (e.target.value.length === 11) return
                                setFieldValue('whatsAppNo', e.target.value)
                              }}
                              onKeyDown={(e: any) =>
                                phonePreventText.includes(e.key) &&
                                e.preventDefault()
                              }
                              onBlur={handleBlur}
                              error={
                                errors.whatsAppNo === ' '
                                  ? false
                                  : Boolean(
                                    touched.whatsAppNo && errors.whatsAppNo
                                  )
                              }
                              helperText={
                                errors.whatsAppNo === ' '
                                  ? false
                                  : touched.whatsAppNo && errors.whatsAppNo
                              }
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className="cc-prop"
                                  >
                                    +91
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <div className="flex al-start js-start subText">
                            <Field
                              as={Checkbox}
                              type="checkbox"
                              name="teams"
                              onChange={(e) => {
                                joinWaitListkey
                                  ? tagMethodGTM(
                                      `Join Waitlist`,
                                      `Matter - Join Waitlist - Help us know you better - Disclaimer`,
                                      'Click',
                                      'Disclaimer-Checked'
                                    )
                                  : tagMethodGTM(
                                      `Register Your Interest`,
                                      `Matter - Register Your Interest - Help us know you better - Disclaimer`,
                                      'Click',
                                      'Disclaimer-Checked'
                                    )
                                 
                                 handleChange(e)
                               }}
                              color="primary"
                              checkedIcon={
                                <img src={Icons.CheckboxActiveIcon} />
                              }
                              icon={<img src={Icons.CheckboxIcon} />}
                            />

                            <p className="small">
                              By clicking Submit, you agree to our{' '}
                              <Link
                                href="/terms-and-conditions"
                                className="underline primary bold"
                                target="_blank"
                              >
                                T&C of Use
                              </Link>{' '}
                              &{' '}
                              <Link
                                href="/privacy-policy"
                                className="underline primary bold"
                                target='_blank'
                              >
                                Privacy Policy
                              </Link>
                            </p>
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <button
                            className="btn btn-gray w-100"
                            disabled={validateUserToken}
                            type="button"
                            onClick={(e) => {
                              joinWaitListkey ? tagMethodGTM(`Join Waitlist` , `Matter - Join Waitlist - Help us know you better - Back` , "Click" , "Help us know you better - Back") :
                              tagMethodGTM(`Register Your Interest` , `Matter - Register Your Interest - Help us know you better - Back` , "Click" , "Help us know you better - Back");                              
                              dispatch(resetRYI())
                              setShowFullDetail(false)}}
                          >
                            Back
                          </button>
                        </Grid>
                        <Grid item xs={6}>
                          <button
                            className="btn btn-primary w-100"
                            type="submit"
                            disabled={!(isValid && dirty)}
                            onClick={() => {
                              handleSubmit()
                              joinWaitListkey
                                ? tagMethodGTM(
                                    `Join Waitlist`,
                                    `Matter - Join Waitlist - Help us know you better - Submit`,
                                    'Click',
                                    'Submit'
                                  )
                                : tagMethodGTM(
                                    `Register Your Interest`,
                                    `Matter - Register Your Interest - Help us know you better - Submit`,
                                    'Click',
                                    'Submit'
                                  )
                            }}
                          >
                            Submit
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </>
      ) : (
        <div>
          <Grid container spacing={3}>
            <Grid item md={12} lg={6}>
              <img src={Icons.CheckGreen} className="info-icon" alt="" />
              <h2 className="monument mb-28">
                <span className="primary">Thank you</span> for <br />
                reaching out
              </h2>
              <p className="large subText mb-24">{showMessage()}</p>
            </Grid>
            <Grid item xs={12} lg={9}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <button
                    className="btn btn-primary w-100 mb-40"
                    onClick={() => history.push('/motorbike')}
                  >
                    Continue Exploring
                  </button>
                </Grid>
                {/* <Grid item xs={12}>
                  <h5 className="expect-text monument">What can you expect next</h5>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <div className="expect-next w-100">
                    <img src={Icons.Mini_Logo_Black} />
                    <div>
                      <h6 className="mb-5 semi-bold">
                        Pre-booking information
                      </h6>
                      <p className="subText">
                        Early bird perks like special price preference in
                        delivery etc.
                      </p>
                    </div>
                  </div>
                </Grid> */}
                {/* <Grid item xs={12} lg={6}>
                  <div className="expect-next w-100 h-100">
                    <img src={Icons.Mini_Logo_Black} />
                    <div>
                      <h6 className="semi-bold">
                        Reward Points and Redeem Plan
                      </h6>
                    </div>
                  </div>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

export default FullDetail

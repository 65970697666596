import React from 'react'

const AppLoader = React.memo(() => {
  return (
    <div className='loader-cover'>
      <lottie-player src="/public/assets/loader.json" speed="1" loop autoplay />
    </div>

  )
})

export default AppLoader

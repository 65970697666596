import { AnyAction } from 'redux'
import { ActionTypes } from '../constants'
import { RegisterYourInterest } from '../constants/model'

let initialState: RegisterYourInterest = new RegisterYourInterest()

export default function reducer(state: any = initialState, action: AnyAction) {
  const { type, payload } = action
  switch (type) {
    case ActionTypes.PHONE_NUMBER_RYI:
      return {
        ...state,
        ...payload,
      }
      case ActionTypes.UPDATE_RYI_USER_DETAILS:
        return {
          ...state,
          ...payload,
        }
        case ActionTypes.RESET_RYI_USER_DETAILS:
          return {
            ...state,
            ...payload,
          }
  
    default:
      return state
  }
}

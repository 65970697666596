import { AnyAction } from 'redux'
import { ActionTypes } from '../constants/index'
import { AeraDetails } from '../constants/model'

let initialState: AeraDetails = new AeraDetails()

export default function createPreBooking(
  state: any = initialState,
  action: AnyAction
) {
  const { type, payload } = action
  switch (type) {
    case ActionTypes.UPDATE_PREBOOKING_CONFIGURE:
      return { ...state, ...payload }
    case ActionTypes.UPDATE_PREBOOKING_PAYMENT:
      return { ...state, ...payload }
    case ActionTypes.UPDATE_PREBOOKING_SUMMARY:
      return { ...state, ...payload }
    case ActionTypes.CREATE_PREBOOKING_ORDER:
      return { ...state, ...payload }
    case ActionTypes.RESET_CREATE_PREBOOKING_ORDER:
      return { ...state, ...payload }
    case ActionTypes.PROMO_CODE:
      return { ...state, ...payload }
    case ActionTypes.LOADING_PREBOOKING:
      return { ...state, ...payload }
    case ActionTypes.UPDATE_TRANSACTION_RESPONSE:
      return { ...state, ...payload }
    case ActionTypes.VALIDATEPREBOOKING:
      return { ...state, ...payload }
      case ActionTypes.UPDATE_EXECUTIVE_AGENT_LIST:
        return { ...state, ...payload }
    default:
      return state
  }
}

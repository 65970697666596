import * as Yup from 'yup'

export const phonePreventText = ['e', 'E', '+', '-', '.']

export const MobileNumnerSchema = () => {
  return Yup.object().shape({
    phone: Yup.string().trim(),
    // .required('Please provide a phone number!')
    // .min(10, 'Phone number must contain at least 10 character')
    // .max(10, 'Phone number must not contain more than 10 characters'),
  })
}

export const userSignupSchema = () => {
  return Yup.object().shape({
    firstName: Yup.string()
      .required(' ')
      .matches(/^[a-zA-Z]*$/, 'Sorry, no special characters are allowed')
      .min(2, 'Please enter atleast 2 characters')
      .max(25, 'first name not contain more then 25 characters'),
    lastName: Yup.string()
      .required(' ')
      .matches(/^[a-zA-Z]*$/, 'Sorry, no special characters are allowed')
      .max(25, 'Last name not contain more then 25 characters')
      .min(2, 'Please enter atleast 2 characters'),

    // location: Yup.string().required('Location is required'),
    userName: Yup.string()
      .required(' ')
      .matches(/^[a-zA-Z0-9._]*$/, 'Sorry, only ‘.’ and ‘_’ are allowed')
      .max(10, 'User name not contain more then 10 characters'),
  })
}

export const activeAccountSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .trim()
      .email('Sorry, please enter a valid email id')
      .required(' ')
      .min(2, 'email must contain at least 2 character')
      .matches(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        'Sorry, please enter a valid email id'
      ),
    whatsAppNo: Yup.string().trim().required(' ').min(10, ' '),
    teams: Yup.boolean()
      .oneOf([true], 'You must agree to the terms and conditions')
      .required('You must agree to the terms and conditions'),
  })
}

// firstName: string
// lastName: string
// userName: string
// phone: string
// email: string
// notify: boolean

export const updateUserProfile = () => {
  return Yup.object().shape({
    firstName: Yup.string()
      .required(' ')
      .matches(/^[a-zA-Z]*$/, 'Sorry, no special characters are allowed')
      .min(2, 'Please enter atleast 2 characters')
      .max(25, 'first name not contain more then 25 characters'),
    lastName: Yup.string()
      .required(' ')
      .matches(/^[a-zA-Z]*$/, 'Sorry, no special characters are allowed')
      .min(2, 'Please enter atleast 2 characters')
      .max(25, 'Last name not contain more then 25 characters'),
    // location: Yup.string().required('Location is required'),
    userName: Yup.string()
      .required(' ')
      .matches(/^[a-zA-Z0-9._]*$/, 'Sorry, only ‘.’ and ‘_’ are allowed')
      .max(10, 'User name not contain more then 10 characters'),
    phone: Yup.string()
      .trim()
      .required('Please provide a phone number!')
      .min(10, 'Phone number must contain at least 10 character')
      .max(10, 'Phone number must not contain more than 10 characters'),
    email: Yup.string()
      .trim()
      .email('Sorry, please enter a valid email id')
      .required(' ')
      .min(2, 'email must contain at least 2 character')
      .matches(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        'Sorry, please enter a valid email id'
      ),
    whatsAppNo: Yup.string().trim().required(' '),
  })
}

export const registerYourInterest = () => {
  return Yup.object().shape({
    firstName: Yup.string()
      .required(' ')
      .matches(/^[a-zA-Z]*$/, 'Sorry, no special characters are allowed')
      .min(2, 'Please enter atleast 2 characters')
      .max(25, 'first name not contain more then 25 characters'),
    lastName: Yup.string()
      .required(' ')
      .matches(/^[a-zA-Z]*$/, 'Sorry, no special characters are allowed')
      .min(2, 'Please enter atleast 2 characters')
      .max(25, 'Last name not contain more then 25 characters'),
    // location: Yup.string().required('Location is required'),
    city: Yup.string()
      // .required(' ')
      .matches(
        /^[a-zA-Z !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/,
        'Sorry, no special characters are allowed'
      )
      .min(2, 'Please enter atleast 2 characters'),
    // .max(25, 'first name not contain more then 25 characters'),

    state: Yup.string()
      // .required(' ')
      .matches(
        /^[a-zA-Z !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/,
        'Sorry, no special characters are allowed'
      )
      .min(2, 'Please enter atleast 2 characters'),
    // .max(25, 'first name not contain more then 25 characters'),

    phone: Yup.string()
      .trim()
      .required('Please provide a phone number!')
      .min(10, 'Phone number must contain at least 10 character')
      .max(10, 'Phone number must not contain more than 10 characters'),
    email: Yup.string()
      .trim()
      .email('Sorry, please enter a valid email id')
      // .required(' ')
      .min(2, 'email must contain at least 2 character')
      .matches(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        'Sorry, please enter a valid email id'
      ),
    whatsAppNo: Yup.string().trim().min(10, ' ').max(10, ' '),
    teams: Yup.boolean()
      .oneOf([true], 'You must agree to the terms and conditions')
      .required('You must agree to the terms and conditions'),

    // interestedIn: Yup.array().min(1).of(Yup.string().required()).required(),
  })
}

export const reserveYourAera = () => {
  return Yup.object().shape({
    firstName: Yup.string()
      .required(' ')
      .matches(/^[a-zA-Z]*$/, 'Sorry, no special characters are allowed')
      .min(2, 'Please enter atleast 2 characters')
      .max(25, 'first name not contain more then 25 characters'),
    lastName: Yup.string()
      .required(' ')
      .matches(/^[a-zA-Z]*$/, 'Sorry, no special characters are allowed')
      .min(2, 'Please enter atleast 2 characters')
      .max(25, 'Last name not contain more then 25 characters'),
    // location: Yup.string().required('Location is required'),
    city: Yup.string()
      // .required(' ')
      .matches(
        /^[a-zA-Z !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/,
        'Sorry, no special characters are allowed'
      )
      .min(2, 'Please enter atleast 2 characters'),
    // .max(25, 'first name not contain more then 25 characters'),

    state: Yup.string()
      // .required(' ')
      .matches(
        /^[a-zA-Z !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/,
        'Sorry, no special characters are allowed'
      )
      .min(2, 'Please enter atleast 2 characters'),
    // .max(25, 'first name not contain more then 25 characters'),

    phone: Yup.string()
      .trim()
      .required('Please provide a phone number!')
      .min(10, 'Phone number must contain at least 10 character')
      .max(10, 'Phone number must not contain more than 10 characters'),
    email: Yup.string()
      .trim()
      .email('Sorry, please enter a valid email id')
      // .required(' ')
      .min(2, 'email must contain at least 2 character')
      .matches(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        'Sorry, please enter a valid email id'
      ),
    whatsAppNo: Yup.string().trim().min(10, ' ').max(10, ' '),
    teams: Yup.boolean()
      .oneOf([true], 'You must agree to the terms and conditions')
      .required('You must agree to the terms and conditions'),

    bikeModel: Yup.string().required(' '),
    bikeColor: Yup.string().required(' '),
  })
}

export const chanelPartnerValidation = () => {
  return Yup.object().shape({
    firstName: Yup.string()
      .required(' ')
      .matches(/^[a-z A-Z]*$/, 'Sorry, no special characters are allowed')
      .min(2, 'Please enter atleast 2 characters')
      .max(25, 'first name not contain more then 25 characters'),
    lastName: Yup.string()
      // .required(' ')
      .matches(/^[a-z A-Z]*$/, 'Sorry, no special characters are allowed')
      .min(2, 'Please enter atleast 2 characters')
      .max(25, 'Last name not contain more then 25 characters'),
    // location: Yup.string().required('Location is required'),
    city: Yup.string()
      .required('')
      // .matches(
      //   /^[a-zA-Z !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/,
      //   'Sorry, no special characters are allowed'
      // )
      .min(2, 'Please enter atleast 2 characters'),
    // .max(25, 'first name not contain more then 25 characters'),

    state: Yup.string()
      .required('')
      // .matches(
      //   /^[a-zA-Z !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/,
      //   'Sorry, no special characters are allowed'
      // )
      .min(2, 'Please enter atleast 2 characters'),
    // .max(25, 'first name not contain more then 25 characters'),

    phone: Yup.string()
      .trim()
      .required('Please provide a phone number!')
      .min(10, 'Phone number must contain at least 10 character')
      .max(10, 'Phone number must not contain more than 10 characters'),
    email: Yup.string()
      .required("")
      .trim()
      .email('Sorry, please enter a valid email id')
      // .required(' ')
      .min(2, 'email must contain at least 2 character')
      .matches(
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        'Sorry, please enter a valid email id'
      ),
    firmName: Yup.string()
      // .required(' ')
      .matches(/^[a-z A-Z]*$/, 'Sorry, no special characters are allowed')
      .min(2, 'Please enter atleast 2 characters')
      .max(25, 'first name not contain more then 25 characters'),
  })
}

// firstName: '',
//     lastNmae: '',
//     city: '',
//     state: '',
//     phone: '',
//     email: '',
//     firmName: ''

import React from 'react'
import Snackbar from '@mui/material/Snackbar'

interface Props {
  successMessage: string
  failMessage?: string
}
const Toaster = React.memo(({ successMessage, failMessage }: Props) => {
  const [showToaster, setShowToaster] = React.useState(true)

  return (
    <Snackbar
      open={showToaster}
      onClose={() => setShowToaster(false)}
      autoHideDuration={3000}
      message={successMessage || failMessage || ''}
      className={failMessage ? 'danger' : 'success'}
    />
  )
})

export default Toaster

import Api from '../../api/index'
import { ActionTypes } from '../../constants'
import { endpoints } from '../../api/endPoints'
import {
  BIKE_MODEL_NAME,
  CurrentUserDetails,
  ORDER_STATUS,
  UserDetails,
} from '../../constants/model'
import { decryptData, encryptData, setUserToken } from '../../utils/common'
import { API_SUCCESS_CODE } from '../../api/constant'
import { ReducerModel } from '../../reducers/reducerModel'
import axios from 'axios'

const updateUserDetails = (userData: UserDetails) => {
  return (dispatch: Function, getState: Function) => {
    dispatch({
      type: ActionTypes.UPDATE_USER_DETAILS,
      payload: userData,
    })
  }
}

const getOrderSubcription = (success: Function, fail: Function) => {
  return (dispatch: Function, getState: Function) => {
    dispatch(handleUserLoader(true))
    Api.getApiCall(
      endpoints.product.list,
      '',
      (response: any) => {
        const { responseCode, data, error, message } = response
        if (data && !error && responseCode == API_SUCCESS_CODE.success) {
          dispatch({
            type: ActionTypes.GET_PREBOOKING_LIST,
            payload: {
              orderList: data.filter(
                (item: any) =>
                  (item.bikeModelName == BIKE_MODEL_NAME.AERA_5000 ||
                    item.bikeModelName == BIKE_MODEL_NAME.AERA_5000_PLUE) &&
                  (item.orderStatus == ORDER_STATUS.CANCELED ||
                    item.orderStatus == ORDER_STATUS.SUCCESS ||
                    item.orderStatus == ORDER_STATUS.MODIFIED)
              ),
            },
          })
          success(data)
          dispatch(handleUserLoader(false))
          return
        }
        if (error && !data) {
          dispatch({
            type: ActionTypes.GET_PREBOOKING_LIST,
            payload: [],
          })
          success([])
          dispatch(handleUserLoader(false))

          return
        }
        dispatch(handleUserLoader(false))

        success()
      },
      (error: any) => {
        dispatch(handleUserLoader(false))

        fail(error)
      }
    )
  }
}

const modifyPrebooking = (payload: any, success: Function, fail: Function) => {
  return (dispatch: Function, getState: Function) => {
    const sendingPayload = JSON.parse(JSON.stringify(payload))
    delete payload.orderId
    dispatch(handleUserLoader(true))

    Api.postApiCall(
      endpoints.product.modify + `?orderId=${sendingPayload.orderId}`,
      payload,
      (response: any) => {
        const { responseCode, data, error, message } = response
        if (!error && responseCode == API_SUCCESS_CODE.success) {
          success({ message })
          dispatch(handleUserLoader(false))
          return
        }
        if (error && !data) {
          fail({ message })
          dispatch(handleUserLoader(false))
          return
        }
        dispatch(handleUserLoader(false))
        success({ message })
      },
      (error: any) => {
        dispatch(handleUserLoader(false))
        fail(error)
      }
    )
  }
}

const cancelPrebooking = (payload: any, success: Function, fail: Function) => {
  return (dispatch: Function, getState: Function) => {
    dispatch(handleUserLoader(true))

    Api.putApiCall(
      endpoints.product.cancel,
      payload,
      (response: any) => {
        const { responseCode, error, message } = response
        if (!error && responseCode == API_SUCCESS_CODE.success) {
          success({ message })
          dispatch(handleUserLoader(false))
          return
        }
        if (error) {
          fail({ message })
          dispatch(handleUserLoader(false))
          return
        }
        dispatch(handleUserLoader(false))
        fail({ message })
      },
      (error: any) => {
        dispatch(handleUserLoader(false))
        fail(error)
      }
    )
  }
}

const updateUserAPI = (payload: any, success: Function, fail: Function) => {
  return (dispatch: Function, getState: Function) => {
    dispatch(handleUserLoader(true))
    Api.putApiCall(
      endpoints.auth.updateUserData,
      payload,
      (response: any) => {
        const { responseCode, data, error, message } = response
        if (data && !error && responseCode == API_SUCCESS_CODE.success) {
          const {currentUserDetails} = getState().userDetails
          dispatch({
            type: ActionTypes.UPDATE_USER_DETAILS,
            payload: { ...decryptData(currentUserDetails), ...data },
          })
          success({
            ...data,
            message,
          })
          dispatch(handleUserLoader(false))
          return
        }
        if (error && !data) {
          fail({ message })
          dispatch(handleUserLoader(false))
          return
        }
        dispatch(handleUserLoader(false))
        success()
      },
      (error: any) => {
        dispatch(handleUserLoader(false))

        fail(error)
      }
    )
  }
}

const getWhatsApp_OTP = (payload: any, success: Function, fail: Function) => {
  return (dispatch: Function, getState: Function) => {
    dispatch(handleUserLoader(true))

    Api.postApiCall(
      endpoints.auth.verify,
      payload,
      (response: any) => {
        const { responseCode, data, error, message } = response
        if (data && !error && responseCode == API_SUCCESS_CODE.success) {
          success({
            ...data,
            message,
          })
          dispatch(handleUserLoader(false))

          return
        }
        if (error && !data) {
          fail({ message })
          dispatch(handleUserLoader(false))

          return
        }
        dispatch(handleUserLoader(false))

        success()
      },
      (error: any) => {
        dispatch(handleUserLoader(false))

        fail(error)
      }
    )
  }
}

const verifyWhatsappOTP = (payload: any, success: Function, fail: Function) => {
  return (dispatch: Function, getState: Function) => {
    dispatch(handleUserLoader(true))
    Api.postApiCall(
      endpoints.auth.otpValidate,
      payload,
      (response: any) => {
        const { responseCode, data, error, message } = response
        if (data && !error && responseCode == API_SUCCESS_CODE.success) {
          const {currentUserDetails}: CurrentUserDetails = getState().userDetails
          dispatch({
            type: ActionTypes.UPDATE_USER_DETAILS,
            payload: { ...decryptData(currentUserDetails), ...data },
          })
          success({
            ...data,
            message,
          })
          dispatch(handleUserLoader(false))
          return
        }
        if (error && !data) {
          fail({ message })
          dispatch(handleUserLoader(false))
          return
        }
        dispatch(handleUserLoader(false))
        success()
      },
      (error: any) => {
        dispatch(handleUserLoader(false))

        fail(error)
      }
    )
  }
}

export const isVerifyEmailAndWhatsapp = (
  payload: any,
  success: Function,
  fail: Function
) => {
  return (dispatch: Function, getState: Function) => {
    Api.postApiCall(
      endpoints.auth.verify,
      payload,
      (response: any) => {
        const { responseCode, data, error, message } = response
        if (data && !error && responseCode == API_SUCCESS_CODE.success) {
          const {currentUserDetails}: UserDetails = getState().userDetails
          const {
            whatsAppVerified,
            emailVerified,
          }: { whatsAppVerified: boolean; emailVerified: boolean } = data
          dispatch({
            type: ActionTypes.UPDATE_USER_DETAILS,
            payload: { ...decryptData(currentUserDetails), whatsAppVerified, emailVerified },
          })
          success({
            ...data,
            message,
          })
          return
        }
        if (error && !data) {
          fail({ message })
          return
        }
        success()
      },
      (error: any) => {
        fail(error)
      }
    )
  }
}

const getUsersDetails = () => {
  return (dispatch: Function, getState: Function) => {
    // const { userDetails }: ReducerModel = getState()
    // dispatch(getUserIp())
    const { userDetails }: ReducerModel = getState()
    const currentUserDetails = decryptData(userDetails.currentUserDetails)
    if (currentUserDetails.userToken) {
      Api.getApiCall(
        endpoints.auth.userDetails,
        '',
        (response: any) => {
          if (response) {
            const store: ReducerModel = getState()
            const { userDetails : {currentUserDetails} } = store
            dispatch({
              type: ActionTypes.UPDATE_USER_DETAILS,
              payload: { ...decryptData(currentUserDetails), ...response?.data },
            })
          }
        },
        (error: any) => {}
      )
    }
  }
}

const getUserIp = () => {
  return async (dispatch: Function, getState: Function) => {
    const response = await fetch('https://geolocation-db.com/json/')
    const data = await response.json()
    console.log('getUserIp', data)
  }
}

const checkProfilePercentge = () => {
  return (dispatch: Function, getState: Function) => {
    const userDetails: UserDetails = getState().userDetails
    const {      imageBase64,
      whatsAppVerified,
      emailVerified,
      receiveNotifications,} = decryptData(userDetails.currentUserDetails)
    // const {
    //   imageBase64,
    //   whatsAppVerified,
    //   emailVerified,
    //   receiveNotifications,
    // }: any = userDetails

    let score: number

    if (imageBase64 && whatsAppVerified && emailVerified) {
      score = 100
    } else if (
      (imageBase64 && whatsAppVerified) ||
      (whatsAppVerified && emailVerified) ||
      (emailVerified && imageBase64)
    ) {
      score = 90
    } else if (
      imageBase64 ||
      whatsAppVerified ||
      whatsAppVerified ||
      emailVerified ||
      emailVerified ||
      imageBase64
    ) {
      score = 80
    } else {
      score = 70
    }

    if (receiveNotifications && !whatsAppVerified) {
      score = score + 5
    }

    if (!receiveNotifications && whatsAppVerified) {
      score = score - 5
    }

    dispatch(
      dispatch({
        type: ActionTypes.UPDATE_USER_DETAILS,
        payload: {
          ...decryptData(userDetails.currentUserDetails),
          profilePercentage: score,
        },
      })
    )
  }
}

const handleUserLoader = (status: boolean) => {
  return (dispatch: Function, getState: Function) => {
    const { loaderStatus } = getState().onboarding

    dispatch({
      type: ActionTypes.LOADER_USER_STATUS,
      payload: { loaderStatus: status },
    })
  }
}

const userLogout = (success: Function, fail: Function) => {
  return (dispatch: Function, getState: Function) => {
    Api.deleteApiCall(
      endpoints.auth.logout,
      '',
      (response: any) => {
        success(response)
        //@ts-ignore
        Moengage.destroy_session();
        console.log({ response })

        if (response) {
          // const store: ReducerModel = getState()
          // const { userDetails } = store
          // dispatch({
          //   type: ActionTypes.UPDATE_USER_DETAILS,
          //   payload: { ...userDetails, ...response },
          // })
        }
      },
      (error: any) => {
        console.log({ error })
      }
    )
  }
}

const requestWriteToUs = (payload: any, success: Function, fail: Function) => {
  return (dispatch: Function, getState: Function) => {
    dispatch(handleUserLoader(true))

    Api.postApiCall(
      endpoints.product.requestAssistance,
      payload,
      (response: any) => {
        dispatch(handleUserLoader(false))
        const { responseCode, data, error, message } = response
        console.log(responseCode, data, error, message)
        if (data && !error && responseCode == API_SUCCESS_CODE.success) {
          success()
        }
      },
      (error: any) => {
        dispatch(handleUserLoader(false))
        fail(error)
      }
    )
  }
}

export default {
  updateUserAPI,
  getUsersDetails,
  getWhatsApp_OTP,
  verifyWhatsappOTP,
  isVerifyEmailAndWhatsapp,
  cancelPrebooking,
  modifyPrebooking,
  updateUserDetails,
  getOrderSubcription,
  checkProfilePercentge,
  handleUserLoader,
  userLogout,
  requestWriteToUs,
}

import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import rehype from 'rehype-raw'
import './markdown.scss'

interface Props {
  string: string
  disableLinkClick?:boolean
}

const index = ({ string, disableLinkClick }: Props) => {

  function LinkRenderer(props: any) {
    return (<a href={props.href} style={{pointerEvents: disableLinkClick ?  'none' : undefined}} target="_blank">
    {props.children}
  </a>)
  }

  return (
    <ReactMarkdown
      transformImageUri={(src) => {
        return process.env.STRAPI_URL + src
      }}
      components={{ a: LinkRenderer }}
      linkTarget="_blank"
      remarkPlugins={[gfm]}
      rehypePlugins={[rehype]}
    >
      {string}
    </ReactMarkdown>
  )
}

export default index
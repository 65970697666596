import Header from '../../../components/Header'
import Footer from '../../../components/Footer'
import Grid from '@mui/material/Grid'
import Icons from '../../../utils/icons'
import '../blogs.scss'
import { useDispatch, useSelector } from 'react-redux'
import { ReducerModel } from '../../../reducers/reducerModel'
import { useState } from 'react'
import { BlogsCategory } from '../../../constants/model'
import homeActions from '../../../actions/homeActions'
import { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment'
import ProgressiveImage from '../../../components/ProgressiveImage'
import React from 'react'
import { tagMethodGTM } from '../../../utils/common'

const HomeBlogs = () => {
  const dispatch: Function = useDispatch()
  const { blogsCategories } = useSelector(
      (store: ReducerModel) => store.home
    )
  
  const history = useHistory()  
  const [currentIndex, setCurrentIndex] = useState(0)    
  const [blogsLength, setCarouselBlogslength] = useState(0)

  //filter category which contains blogs
  const blogsAllCategories = React.useMemo(() => {
    return blogsCategories.filter((blog:any) => blog.allblogs.length > 0)
  }, [blogsCategories])

  const carouselInfiniteScroll = () => {        
    if (currentIndex === blogsLength - 1) {            
      return setCurrentIndex(0)
    }
    return setCurrentIndex(currentIndex + 1)
  }

  useEffect(()=> {
    const interval = setInterval(()=> {carouselInfiniteScroll()}, 10000)
    return () => clearInterval(interval)
  })
  
  useEffect(()=> {
    window.scrollTo(0, 0)
    //get all categories and blogs
    dispatch(homeActions.getBlogs())        
  },[])          

  const openBlog = (title: string) => {
    let blogTitle = title.toLowerCase().replace(/ /g,"_").replace(/\?/g, '').replace(/\-/g, "_")
    window.open('/blogs/' + blogTitle, '_blank')    
  }
      
  return (
    <div className="blogs-page-wrapper">
      <Header />
      <h1 className="visuallyHidden">Blogs</h1>              

      {/* category and blogs */}
      <div className="blog-data-content content-container">
        {blogsAllCategories.map((blogCategory: BlogsCategory, index) => 
          <div key={index} className="blog-section">
            <div className="category-title">
              <h5 className="category-sub-title monument large black mb-16 mb-sm-20 mb-xs-8">
                {blogCategory.categoryName}
              </h5>
              {blogCategory.allblogs.length > 3 && 
                <p className="more-posts mb-16 mb-sm-20 mb-xs-8" onClick={() => {
                    history.push('/blogs/category-blogs/' + blogCategory.categoryName.toLowerCase().replace(/ /g,"_").replace(/\?/g, '').replace(/\-/g, "_"))
                    tagMethodGTM(blogCategory.categoryName, "Matter Blog " + blogCategory.categoryName, "Click", "More Posts")
                }}>
                  More Posts             
                  <img
                    className="ml-5"
                    src={Icons.RightChevronIconBlue}
                    alt="Right Chevron Icon Blue"
                  />
                </p>
              }
            </div>            
            
            <Grid container spacing={{xs: 1.5, sm: 3}}>
              {blogCategory.allblogs.slice(0,3).map((item, index)=>                 
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={() => {
                    
                    openBlog(item.title)              
                    tagMethodGTM(blogCategory.categoryName, "Matter Blog " + blogCategory.categoryName, "Click", item.title)
                  }}>
                  <div className="blog-posts monument pointer" onClick={() => {
                    
                  }
                    }>
                    <ProgressiveImage alt="Matter Blogs"                    
                      className="more-posts-blog-img" 
                      imgSrc={process.env.STRAPI_URL + item.matterBlogImageWeb} 
                      previewSrc={process.env.STRAPI_URL + item.matterBlogThumbNailImageWeb} />                    
                    <p className="date mr-8">{moment(item.publishDate).format('MMM DD, YYYY')}</p>
                    <span className="dot"></span>                    
                    <p className="date ml-8">{item.readTime} min read</p>
                    <p className="blog-data-title mt-8">
                      {item.title}
                    </p>                
                    <p className="blog-description">
                      {item.blogIntroduction} 
                    </p>
                  </div>
                  </Link>
                </Grid>
              )}               
            </Grid>
          </div>
        )}        
      </div>        
      <Footer />
    </div>    
  )
}

export default HomeBlogs
